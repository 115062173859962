import { Expose, Transform } from "class-transformer";
import { IsUUID, IsString, IsNumber, IsEnum, IsDate } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";
import { dateTransformer } from "./dateTransformer";
import { numberTransformer } from "./numberTransformer";
import { InterviewStageType, JobQuestion } from "./InterviewPayload";
import { FrontlineStageType } from "./FrontlinePayload";

export enum ActivityType {
	FIRST = "first",
	MIDDLE = "middle",
	LAST = "last"
}
export class ScheduleView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	scheduleId!: string;

	@Expose()
	@ViewColumn()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	scheduleDate!: Date;

	@Expose()
	@ViewColumn()
	@IsDate()
	@Transform(({ value }) => dateTransformer(value))
	autoCompleteDate!: Date;

	@Expose()
	@ViewColumn()
	@IsString()
	scheduleDescription!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	assignmentId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentTitle!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentDescription!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	courseId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	courseName!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	aggregateDataId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	aggregateType!: "firstSessionAggregate" | "middleSessionAggregate" | "lastSessionAggregate";

	@Expose()
	@ViewColumn()
	projectGoals!: string;

	@Expose()
	@ViewColumn()
	expectations!: {
		content: string;
		outlook: {
			total_count: number;
			positive_count: number;
			negative_count: number;
			mixed_count: number;
		};
	};

	@Expose()
	@ViewColumn()
	flaggedComments!: {
		negative?: Array<{
			comment: string;
			priority: "medium" | "high";
			userId: string;
			firstName: string;
			lastName: string;
			email: string;
		}>;
	};

	@Expose()
	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	assignedSessionsCount!: number;

	@Expose()
	@ViewColumn()
	@IsEnum(["completed", "pending", "available"])
	status!: "completed" | "pending" | "available";

	@Expose()
	@ViewColumn()
	@IsEnum(ActivityType)
	activityType!: ActivityType;

	@ViewColumn()
	overview!: string;

	@ViewColumn()
	studentSatisfaction!: {
		average_score: number;
		aggregated_summary: string;
	};

	@ViewColumn()
	goalProgress!: {
		goals: {
			total_count: number;
			yes_count: number;
			partial_count: number;
			no_count: number;
		};
		summary: string;
	};

	@ViewColumn()
	competencyProgress!: { name: string; average_assessment: number; aggregated_summary: string }[];

	@ViewColumn()
	finalReview!: {
		takeaways: string;
		growth: string;
		feedback: {
			valuableAspects: string;
			contribution: string;
			suggestions: string;
			preferredMethod: {
				guided: number;
				traditional: number;
				unknown: number;
				total: number;
			};
			rating: number;
		};
	};

	@ViewColumn()
	purpose!: {
		progress: {
			total_count: number;
			yes_count: number;
			partial_count: number;
			no_count: number;
			new_count: number;
		};
		summary: string;
	};

	@Expose()
	@ViewColumn()
	interviewAssessment!: {
		strengths: string;
		improvements: string;
		questionListSummary: Array<{
			id: string;
			type: string;
			feedback: string;
			averageScore?: number;
		}>;
	};

	@Expose()
	@ViewColumn()
	@IsString()
	interviewStageType!: InterviewStageType | null;

	@Expose()
	@ViewColumn()
	@IsNumber()
	interviewStageOrderNumber!: number | null;

	@Expose()
	@ViewColumn()
	@IsString()
	interviewerAttitude!: string | null;

	@Expose()
	@ViewColumn()
	interviewQuestionList!: JobQuestion[] | null;

	@Expose()
	@ViewColumn()
	@IsString()
	jobTitle!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	jobDescription!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	jobSummary!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	jobFunction!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	jobExperienceLevel!: string | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	jobResponsibilities!: string[] | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	jobSkillsRequired!: string[] | null;

	@Expose()
	@ViewColumn()
	frontlineAssessment!: {
		strengths: string;
		improvements: string;
		keyPointsEvaluation?: Array<{
			point: string;
			aggregatedSummary: string;
			successPercentage?: number;
		}>;
		infoCollectionEvaluation?: Array<{
			point: string;
			aggregatedSummary: string;
			successPercentage?: number;
		}>;
	};

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineStageType!: FrontlineStageType | null;

	@Expose()
	@ViewColumn()
	@IsNumber()
	frontlineStageOrderNumber!: number | null;

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineStudentRole!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineAssistantRole!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineAssistantProblem!: string | null;

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineAssistantRequest!: string | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	frontlineKeyPoints!: string[] | null;

	@Expose()
	@ViewColumn()
	@IsString()
	frontlineIssueWithRequest!: string | null;

	@Expose()
	@ViewColumn()
	@IsString({ each: true })
	frontlineInfoToCollect!: string[] | null;
}
