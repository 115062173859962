import React, { useRef, useEffect, useState } from "react";
import ChatBubble from "./ChatBubble";
import LoadingBubble from "./LoadingBubble";
import { PaperAirplaneIcon, PencilIcon } from "@heroicons/react/24/solid";
import { ChatMessage } from "../../services/ResumeService";

interface ChatSectionProps {
	chatHistory: ChatMessage[];
	message: string;
	setMessage: (message: string) => void;
	handleSubmit: (e: React.FormEvent) => void;
	isLoading: boolean;
	onFileSelect?: (file: File) => void;
	profileId: string;
	setProfile: (profile: any) => void;
	profile: any;
}

const ChatSection: React.FC<ChatSectionProps> = ({
	chatHistory,
	message,
	setMessage,
	handleSubmit,
	isLoading,
	onFileSelect,
	profileId,
	setProfile,
	profile
}) => {
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const [isEditing, setIsEditing] = useState(false);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [chatHistory, isLoading]);

	const handleFormSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		handleSubmit(e);
		// Reset textarea height after submission
		if (textareaRef.current) {
			textareaRef.current.style.height = "36px";
		}
	};

	const adjustTextareaHeight = (e: React.FormEvent<HTMLTextAreaElement>) => {
		const target = e.target as HTMLTextAreaElement;
		target.style.height = "36px"; // Reset height first
		target.style.height = `${target.scrollHeight}px`;
	};

	return (
		<div className="w-[35%] min-w-[250px] flex flex-col h-full bg-white rounded-lg border shadow-sm pt-1">
			<h2 className="text-2xl text-gray-800 border-b-2 border-[#00a9af] p-2 m-2 mb-4 mx-4">AI Assistant</h2>
			<div className="flex-1 overflow-y-auto overflow-x-hidden custom-scrollbar">
				<div className="px-4 pb-4">
					{chatHistory.map((msg, index) => {
						const isUser = msg.role === "User";
						const messageContent = msg.content;
						return (
							<ChatBubble
								key={index}
								message={messageContent}
								isUser={isUser}
								isFirstMessage={index === 0}
								onFileSelect={onFileSelect}
								profileId={profileId}
								setProfile={setProfile}
								profile={profile}
							/>
						);
					})}
					{isLoading && <LoadingBubble />}
					<div ref={messagesEndRef} />
				</div>
			</div>
			<form onSubmit={handleFormSubmit} className="border-t bg-white rounded-b-lg">
				<div className="flex gap-2 p-4">
					<textarea
						ref={textareaRef}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						onInput={adjustTextareaHeight}
						className="flex-1 border rounded-lg px-2 py-2 leading-normal text-sm resize-none overflow-y-hidden min-h-[36px]"
						placeholder="Type..."
						rows={1}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleFormSubmit(e);
							}
						}}
					/>
					<button
						type="submit"
						className="bg-[#00a9af] text-white p-2 rounded-lg hover:bg-[#008f94] text-sm w-9 h-9 flex items-center justify-center"
					>
						<PaperAirplaneIcon className="w-4 h-4" />
					</button>
				</div>
			</form>
		</div>
	);
};

export default ChatSection;
