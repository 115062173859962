import { z } from "zod";
import { AssignedSessionView } from "./AssignedSessionView";

export type CoreQuestionType = "Technical" | "Behavioral" | "Situational" | "Cultural Fit" | "Background" | "Motivation" | "General" | "Recall";
export type QuestionType = "Opening" | "Closing" | CoreQuestionType;

export const JobQuestionSchema = z.object({
	id: z.string(),
	order: z.number(),
	type: z.enum(["Opening", "Closing", "Technical", "Behavioral", "Situational", "Cultural Fit", "Background", "Motivation", "General", "Recall"]),
	text: z.string(),
	context: z.string().optional()
});

export type JobQuestion = z.infer<typeof JobQuestionSchema>;

export type InterviewStageType = "resume" | "screening" | "middle" | "final";

export interface InterviewStagePlan {
	technicalQuestions: number;
	behavioralQuestions: number;
	situationalQuestions: number;
	culturalFitQuestions: number;
	generalQuestions: number;
	recallQuestions: number;
	backgroundQuestions: number;
	motivationQuestions: number;
	interviewerAttitude?: "direct" | "friendly" | "neutral";
	interviewStageOrderNumber: number;
	interviewQuestionList: JobQuestion[];
	questionDetails?: {
		[questionId: string]: {
			text: string;
			context: string;
		};
	};
}

export interface JobDetails {
	jobSummary: string;
	jobFunction: string;
	jobExperienceLevel: string;
	jobSkillsRequired: string[];
	jobResponsibilities: string[];
}

export type InterviewStage = {
	interviewStageOrderNumber: number;
	interviewStageType: InterviewStageType;
	enabled: boolean;
	title: string;
	description: string;
	date: Date | null;
	startDate: Date | null;
};

export class InterviewPayload {
	interviewStageOrderNumber: number;

	interviewStageType: InterviewStageType;

	interviewerAttitude: string;

	interviewQuestionList: JobQuestion[];

	jobTitle: string;

	jobDescription: string;

	jobSummary: string;

	jobFunction: string;

	jobExperienceLevel: string;

	jobResponsibilities: string[];

	jobSkillsRequired: string[];

	constructor(data: Partial<InterviewPayload>) {
		this.interviewStageOrderNumber = data.interviewStageOrderNumber ?? 0;
		this.interviewStageType = data.interviewStageType ?? "resume";
		this.interviewerAttitude = data.interviewerAttitude ?? "";
		this.interviewQuestionList = data.interviewQuestionList ?? [];
		this.jobTitle = data.jobTitle ?? "";
		this.jobDescription = data.jobDescription ?? "";
		this.jobSummary = data.jobSummary ?? "";
		this.jobFunction = data.jobFunction ?? "";
		this.jobExperienceLevel = data.jobExperienceLevel ?? "";
		this.jobResponsibilities = data.jobResponsibilities ?? [];
		this.jobSkillsRequired = data.jobSkillsRequired ?? [];
	}
}

export const isValidJobDetails = (session: AssignedSessionView): boolean => {
	if (!session) return false;

	// Check all required job fields
	const requiredFields: (keyof JobDetails)[] = ["jobSummary", "jobFunction", "jobExperienceLevel", "jobSkillsRequired", "jobResponsibilities"];

	return requiredFields.every((field) => {
		const value = session[field];
		return value !== null && value !== undefined && (Array.isArray(value) ? value.length > 0 : value !== "");
	});
};
