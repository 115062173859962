export function getQuestionScoreColor(score: number): { background: string; text: string } {
	if (score === null || score === undefined) {
		return { background: "#F6F3EC", text: "#414042" }; // Brand background and text off-black
	}
	if (score < 50) {
		return { background: "#fce8f1", text: "#F0699e" }; // Light Overlap Fuchsia
	}
	if (score >= 50 && score < 80) {
		return { background: "#e8f7f8", text: "#41c1ca" }; // Light Marker Teal
	}
	return { background: "#e6f7f7", text: "#00a9af" }; // Light Overlap Teal
}
