import React, { createContext, useState, useContext, ReactNode, useEffect, useRef, useCallback } from "react";
import useSocket, { SocketStatus } from "../hooks/useSocket";
import useVapiSetup, { VapiCall, VapiFirstMessageMode } from "../hooks/useVapiSetup";
import { Call } from "../schemas/CallSchema";
import { AssistantConfig } from "../schemas/AssistantSchema";
import { assistants } from "../constants/Assistants";
import { SessionService } from "../services/SessionService";
import { UserService } from "../services/UserService";
import { VapiInterface } from "../hooks/useVapiSetup";
import { UserView } from "../types/UserView";
import { useAuth } from "@frontegg/react";
import TokenManager from "../services/TokenManager";
import { NavigateService } from "../services/NavigateService";
import { useNavigate } from "react-router-dom";
import { SessionView } from "../types/SessionView";
import { usePostHog } from "posthog-js/react";
// Update the QueueItem type
export type QueueItem = {
	id: string;
	position: number;
	userId: string;
	joinedAt: Date;
	canMoveToActive: boolean;
	socketIds: string[];
	clearanceLevel: number; // Add this line
	vapiCallId: string;
};

// Update the ConnectedUser interface
interface ConnectedUser {
	userId: string;
	socketIds: string[];
	isInCall: boolean;
	vapiCallId: string;
	lastUpdatedTimeStamp: number;
	canMoveToActive: boolean;
	clearanceLevel: number;
}

export interface VapiCallList {
	id: string;
	name: string;
	createdAt: Date;
}

interface Assistant {
	id: string;
	name: string;
	audio: any;
	description: string;
	voice_provider: string;
	voice_id: string;
}

export enum MessageRole {
	System = "system",
	Assistant = "assistant",
	User = "user"
}

interface StartCallProps {
	sessionId: string;
	session: SessionView;
}

interface EmitFunction {
	<T = any>(eventName: string, data?: object): Promise<T>;
}

interface emitType {
	(eventName: string, data?: object): Promise<any>;
}

interface AppContextType {
	status: SocketStatus;
	emit: EmitFunction;
	on: (event: string, callback: (...args: any[]) => void) => void;
	off: (event: string, callback: (...args: any[]) => void) => void;
	userId: string | null;
	setUserId: (id: string | null) => void;
	needsOnboarding: boolean;
	setNeedsOnboarding: (needsOnboarding: boolean) => void;
	fetchUserAndCheckTutorial: () => Promise<UserView | null>;
	vapi: VapiInterface | null;
	startCall: (props: StartCallProps) => Promise<VapiCall | null>;
	stopCall: () => Promise<void>;
	sayMessage: (message: string) => Promise<void>;
	sendMessage: (message: string) => Promise<void>;
	isCallActive: boolean;
	setIsCallActive: React.Dispatch<React.SetStateAction<boolean>>;
	toggleMute: () => void;
	isMuted: boolean;
	setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
	isInitialSpeech: boolean;
	currentSpeaker: "assistant" | "user" | null;
	currentMessage: string | null;
	activityMessages: any[];
	debugMode: boolean;
	setDebugMode: React.Dispatch<React.SetStateAction<boolean>>;
	callStatus: "not_started" | "in_progress" | "ended";
	queueData: QueueItem[];
	activeUsers: QueueItem[];
	vapiCalls: VapiCallList[];
	canJoin: boolean;
	setCanJoin: React.Dispatch<React.SetStateAction<boolean>>;
	checkIfAvailable: (userId: string) => Promise<boolean>;
	joinQueue: (userId: string) => Promise<void>;
	moveToActive: (userId: string) => Promise<void>;
	moveDownQueue: (userId: string) => Promise<void>;
	removeFromQueue: (userId: string) => Promise<void>;
	removeFromActiveUsers: (userId: string) => Promise<void>;
	instageUser: UserView | null;
	activeAssistant: Assistant;
	setActiveAssistant: React.Dispatch<React.SetStateAction<Assistant>>;
	isServiceUp: boolean;
	setIsServiceUp: React.Dispatch<React.SetStateAction<boolean>>;
	pagesNavigated: string[];
	setPagesNavigated: React.Dispatch<React.SetStateAction<string[]>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	// const socket = useSocket({ url: process.env.REACT_APP_SOCKET_URL || "http://localhost:3001" });
	const navigate = useNavigate();

	const posthog = usePostHog();

	const { vapi, isInitialSpeech, currentSpeaker, callStatus, currentMessage, activityMessages } = useVapiSetup();
	const { status, emit, on, off } = useSocket({ url: process.env.REACT_APP_BACKEND_URL || "http://localhost:5002" });
	const { user } = useAuth();
	const [userId, setUserId] = useState<string | null>(null);
	const [needsOnboarding, setNeedsOnboarding] = useState(false);
	const sessionIdRef = useRef<string | null>(null);
	const [isCallActive, setIsCallActive] = useState<boolean>(false);
	const [activeAssistant, setActiveAssistant] = useState<Assistant>(() => {
		const storedAssistantId = localStorage.getItem("selectedAssistantId");
		if (storedAssistantId) {
			const assistant = assistants.find((a) => a.id === storedAssistantId);
			return assistant || assistants[0];
		}
		return assistants[0];
	});
	const [isMuted, setIsMuted] = useState<boolean>(false);
	const [debugMode, setDebugMode] = useState(() => {
		const stored = sessionStorage.getItem("isAdminAccess");
		return stored === "true";
	});
	const [queueData, setQueueData] = useState<QueueItem[]>([]);
	const [vapiCalls, setVapiCalls] = useState<VapiCallList[]>([]);
	const [activeUsers, setActiveUsers] = useState<QueueItem[]>([]);
	const [canJoin, setCanJoin] = useState<boolean>(false);
	const [isServiceUp, setIsServiceUp] = useState<boolean>(true);
	const [instageUser, setInstageUser] = useState<UserView | null>(null);
	const [pagesNavigated, setPagesNavigated] = useState<string[]>(() => {
		const stored = sessionStorage.getItem("pagesNavigated");
		return stored ? JSON.parse(stored) : [];
	});



	NavigateService.setNavigate(navigate);

	useEffect(() => {
		if (status === "connected" && userId) {
			console.log("status", status);


			emit("setUserId", { userId, userToken: TokenManager.getToken() }).catch((error) => {
				console.error("Error setting user ID:", error);
			});
		}
		// if (status === "disconnected") {
		// 	console.log("status", status);
		// 	emit("update/user", { userId });
		// }
	}, [status, userId, emit]);

	// Refresh the page when a session is submitted
	useEffect(() => {
		const handleSessionSubmitted = () => {
			window.location.reload();
		};

		on("sessionSubmitted", handleSessionSubmitted);

		return () => {
			off("sessionSubmitted", handleSessionSubmitted);
		};
	}, [on, off]);

	useEffect(() => {
		// const queueUpdateHandler = (data: ConnectedUser[]) => {
		// 	// console.log("queueUpdateHandler", data);
		// 	if (!data) return;
		// 	const formattedData: QueueItem[] = data.map((user, index) => ({
		// 		id: `queue-${index}`,
		// 		position: index + 1,
		// 		userId: user.userId,
		// 		socketIds: user.socketIds,
		// 		joinedAt: new Date(),
		// 		vapiCallId: user.vapiCallId,
		// 		canMoveToActive: user.canMoveToActive,
		// 		clearanceLevel: user.clearanceLevel
		// 	}));
		// 	setQueueData(formattedData);
		// };

		// const activeUsersUpdateHandler = (data: ConnectedUser[]) => {
		// 	// console.log("activeUsersUpdateHandler", data);
		// 	if (!data) return;
		// 	const formattedData: QueueItem[] = data.map((user, index) => ({
		// 		id: `queue-${index}`,
		// 		position: index + 1,
		// 		userId: user.userId,
		// 		joinedAt: new Date(),
		// 		socketIds: user.socketIds,
		// 		canMoveToActive: user.canMoveToActive,
		// 		vapiCallId: user.vapiCallId,
		// 		clearanceLevel: user.clearanceLevel
		// 	}));
		// 	setActiveUsers(formattedData);
		// };

		// const vapiUpdateHandler = (data: VapiCallList[]) => {
		// 	// console.log("vapiUpdateHandler", data);
		// 	if (!data) return;
		// 	setVapiCalls(data);
		// };

		// const canJoinHandler = (data: { canJoin: boolean }) => {
		// 	// console.log("canJoinHandler", data);
		// 	setCanJoin(data.canJoin);
		// };

		// on("queueUpdate", queueUpdateHandler);
		// on("activeUsersUpdate", activeUsersUpdateHandler);
		// on("canJoin", canJoinHandler);
		// on("vapiCallsUpdate", vapiUpdateHandler);

		return () => {
			// off("queueUpdate", queueUpdateHandler);
			// off("activeUsersUpdate", activeUsersUpdateHandler);
			// off("canJoin", canJoinHandler);
			// off("vapiCallsUpdate", vapiUpdateHandler);
		};
	}, [on, off]);

	const OnUserLogin = useCallback(async (): Promise<UserView | null> => {
		if (!user) return null;
		try {
			console.log("fronteggUser", user.email, user.id, user.name);
			// console.log("fronteggUser roles", JSON.stringify(user.roles ? user.roles.map((role) => ({ key: role.key, level: role.level })) : []));
			// console.log("fronteggUser tenants", user.tenantId);

			let foundUser = await UserService.getUserByFronteggId(user.id);
			if (!foundUser) {
				const createdUser = await UserService.getOrCreateUser(user.email, user.name.split(" ")[0], user.name.split(" ")[1], user.tenantId);
				if (!createdUser) {
					console.error("Error creating new user");
					return null;
				}
				await UserService.updateUserFronteggIdByEmail(user.id, user.email);
				foundUser = createdUser;
			}
			// console.log("foundUser", foundUser);
			if (foundUser && foundUser.id) {
				setUserId(foundUser.id);
				setNeedsOnboarding(!foundUser.hasDoneTutorial);
				setInstageUser(foundUser);
				console.log("instageUser", foundUser);
				return foundUser;
			}
			return null;
		} catch (error) {
			console.error("Error fetching user ID:", error);
			return null;
		}
	}, [user]);

	const startCall = useCallback(
		async ({ sessionId, session }: StartCallProps): Promise<VapiCall | null> => {
			console.log("startCall", sessionId);
			sessionIdRef.current = sessionId;

			if (!vapi) {
				console.error("VAPI instance is not available.");
				return null;
			}
			let postHogSessionId = "";
			try {
				postHogSessionId = posthog?.get_session_id() || "";
			} catch (error) {
				console.error("Error getting posthog session ID:", error);
			}

			const assistantConfig: AssistantConfig = {
				name: "Web Call",

				model: {
					messages: [],
					temperature: 0.7,
					maxTokens: 250,
					model: "gpt-4o-mini",
					numFastTurns: 2,
					provider: "custom-llm",
					url: `${process.env.REACT_APP_BACKEND_URL}/api/vapi`
				},
				voice: {
					provider: "[provider]",
					voiceId: "[voiceId]",
					model: "eleven_turbo_v2_5"
				},
				transcriber: {
					provider: "deepgram",
					model: "nova-2",
					language: "en-US"
				},
				silenceTimeoutSeconds: 60,
				maxDurationSeconds: 1800, // 30 minutes
				serverUrl: `${process.env.REACT_APP_BACKEND_URL}/vapi/callbacks`,
				analysisPlan: {
					summaryPrompt: "off",
					successEvaluationPrompt: "off"
				}
			};
			// console.log("assistantConfig", assistantConfig);

			assistantConfig.firstMessageMode = VapiFirstMessageMode.AssistantSpeaksFirstWithModelGeneratedMessage;

			if (assistantConfig.firstMessage) {
				assistantConfig.firstMessage = assistantConfig.firstMessage.replace("{assistantName}", activeAssistant?.name || "");
			}

			const storedAssistantId = localStorage.getItem("selectedAssistantId");
			const currentAssistant = storedAssistantId ? assistants.find((a) => a.id === storedAssistantId) || activeAssistant : activeAssistant;
			//TODO ADD TO SESSION VIEW AND PULL THIS WAY
			assistantConfig.voice = {
				provider: currentAssistant.voice_provider,
				voiceId: currentAssistant.voice_id
			};

			assistantConfig.endCallPhrases = ["Goodbye", "goodbye", "Bye", "bye", "Goodbye for now", "goodbye for now", "Bye for now", "bye for now"];
			assistantConfig.model.messages =
				session.chatHistory?.map((message) => ({
					role: message.role,
					content: message.content
				})) || [];

			try {
				try {
					assistantConfig.metadata = {
						postHogSessionId: postHogSessionId || "",
						sessionId: session.sessionId || "",
						assignmentId: session.assignmentId || "",
						activityType: session.activityType || "",
						plGroupId: session.plGroupId?.toString() || "", // missing
						plGroupIdUrl: session.getPlGroupIdUrl() || "",
						scheduleId: session.scheduleId || "",
						userId: session.userId || "",
						sessionType: session.sessionType || "",
						clientId: session.clientId || "",
						clientName: session.clientName || "",
						tenantId: session.tenantId || "",
						fronteggUserId: user?.id || "", // missing
						experienceType: session.experienceType || ""
					};
				} catch (error) { }

				const vapiData = await vapi.start(assistantConfig);
				setIsCallActive(true);
				console.log("vapiData", vapiData);
				if (vapiData && vapiData.id) {
					console.log("status", status);
					console.log("userId", userId);

					SessionService.updateSession(sessionId, vapiData.id);
					if (status === "connected" && userId) {
						console.warn("setInCall", { userId, isInCall: true, vapiCallId: vapiData.id });
						emit("setInCall", { userId, isInCall: true, vapiCallId: vapiData.id });
					}
					return vapiData;
				}
			} catch (error) {
				console.error(`Error starting call:`, error);
			}
			return null;
		},
		[activeAssistant, vapi, status, userId, emit]
	);

	const stopCall = useCallback(async (): Promise<void> => {
		if (!vapi) return;
		try {
			vapi.stop();
			setIsCallActive(false);
			if (status === "connected" && userId) {
				emit("setInCall", { userId, isInCall: false, vapiCallId: "" });
			}
			return;
		} catch (error) {
			console.error("Error stopping call:", error);
		}
	}, [vapi, setIsCallActive, status, userId, emit]);

	const sayMessage = async (message: string): Promise<void> => {
		console.log("sayMessage", message);
		if (!vapi) return Promise.resolve();

		try {
			vapi.say(message, true);
			return Promise.resolve();
		} catch (error) {
			console.error("Error saying message:", error);
			return Promise.reject(error);
		}
	};

	const sendMessage = async (message: string): Promise<void> => {
		console.log("sendMessage", message);
		if (!vapi) return Promise.resolve();

		try {
			vapi.send({
				type: "add-message",
				message: {
					role: "user",
					content: message
				}
			});
			return Promise.resolve();
		} catch (error) {
			console.error("Error sending message:", error);
			return Promise.reject(error);
		}
	};

	const toggleMute = (): void => {
		if (!vapi) return;

		setIsMuted(!isMuted);
		if (vapi.isMuted()) {
			vapi.setMuted(false);
		} else {
			vapi.setMuted(true);
		}
	};

	const checkIfAvailable = async (userId: string): Promise<boolean> => {
		// const data = (await emit("checkIfAvailable", { userId })) as { userId: string; isAvailable: boolean };
		// console.log(data);

		return true; // data.isAvailable;
	};

	const joinQueue = async (userId: string) => {
		await emit("addToQueue", { userId });
	};

	const moveToActive = async (userId: string) => {
		const data = await emit("moveFromQueueToActive", { userId });
		console.log(data);
	};

	const moveDownQueue = async (userId: string) => {
		const data = await emit("moveDownQueue", { userId });
		console.log(data);
	};

	const removeFromQueue = async (userId: string) => {
		const data = await emit("removeFromQueue", { userId });
		console.log(data);
	};

	const removeFromActiveUsers = async (userId: string) => {
		const data = await emit("removeFromActiveUsers", { userId });
		console.log(data);
	};

	const contextValue: AppContextType = {
		status,
		emit,
		on,
		off,
		userId,
		setUserId,
		needsOnboarding,
		setNeedsOnboarding,
		fetchUserAndCheckTutorial: OnUserLogin,
		vapi,
		startCall,
		stopCall,
		sayMessage,
		sendMessage,
		isCallActive,
		setIsCallActive,
		toggleMute,
		isMuted,
		setIsMuted,
		isInitialSpeech,
		currentSpeaker,
		currentMessage,
		activityMessages,
		debugMode,
		setDebugMode,
		callStatus,
		queueData,
		activeUsers,
		canJoin,
		setCanJoin,
		checkIfAvailable,
		joinQueue,
		moveToActive,
		moveDownQueue,
		removeFromQueue,
		removeFromActiveUsers,
		vapiCalls,
		instageUser,
		activeAssistant,
		setActiveAssistant,
		isServiceUp,
		setIsServiceUp,
		pagesNavigated,
		setPagesNavigated
	};

	useEffect(() => {
		sessionStorage.setItem("pagesNavigated", JSON.stringify(pagesNavigated));
	}, [pagesNavigated]);

	return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (context === undefined) {
		throw new Error("useAppContext must be used within an AppContextProvider");
	}
	return context;
};
