import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon, LockOpenIcon, ClockIcon, LockClosedIcon, UserIcon, PencilIcon, PhoneIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon as SolidCheckCircleIcon } from "@heroicons/react/24/solid";
import { ActivityType } from "../../schemas/ActivityType";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { getStatusColors } from "../../utils/statusStyles";
import { InterviewStageType } from "@/src/types/InterviewPayload";
import { FrontlineStageType } from "@/src/types/FrontlinePayload";

export interface TabData {
	label: string;
	status: AssignedSessionStatus;
	date: Date | null;
	scheduleId: string;
	activityType: ActivityType;
	interviewStageOrderNumber?: number;
	interviewStageType?: InterviewStageType;
	frontlineStageOrderNumber?: number;
	frontlineStageType?: FrontlineStageType;
}

interface HorizontalTabsProps {
	tabs: TabData[];
	type: "user" | "admin";
	selectedTab: number;
	assignmentId: string;
	userId: string | null;
	onTabSelect: (scheduleId: string) => void;
}

export const formatStatus = (status: string) => {
	// Capitalize the first letter of each word and replace underscores with spaces
	return status.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs, type, selectedTab, assignmentId, userId, onTabSelect }) => {
	const navigate = useNavigate();

	const renderStatus = (status: AssignedSessionStatus) => {
		const styles = getStatusColors(status);
		const className = `text-xs ${styles.background} ${styles.text} ${styles.border ? `border ${styles.border}` : ""} px-2 py-1 rounded-full`;

		return <span className={className}>{formatStatus(status)}</span>;
	};

	const getIcon = (status: AssignedSessionStatus) => {
		switch (status) {
			case AssignedSessionStatus.COMPLETED:
				return <CheckCircleIcon className="h-5 w-5 text-[#00a9af]" />;
			case AssignedSessionStatus.SUBMITTED:
				return <SolidCheckCircleIcon className="h-5 w-5 text-[#00a9af]" />;
			case AssignedSessionStatus.SUBMITTED_LATE:
				return <SolidCheckCircleIcon className="h-5 w-5 text-[#00a9af]" />;
			case AssignedSessionStatus.NOT_ATTEMPTED:
				return <ClockIcon className="h-5 w-5 text-[#B42318]" />;
			case AssignedSessionStatus.PENDING:
				return <LockClosedIcon className="h-5 w-5 text-[#344054]" />;
			case AssignedSessionStatus.AVAILABLE:
				return <LockOpenIcon className="h-5 w-5 text-[#067647]" />;
			case AssignedSessionStatus.NOT_CONFIRMED:
				return <UserIcon className="h-5 w-5 text-gray-400" />;
			case AssignedSessionStatus.DRAFT:
				return <PencilIcon className="h-5 w-5 text-gray-400" />;
			case AssignedSessionStatus.IN_PROGRESS:
				return <PhoneIcon className="h-5 w-5 text-yellow-500" />;

			case AssignedSessionStatus.INCOMPLETE:
				return <XCircleIcon className="h-5 w-5 text-yellow-500" />;
			case AssignedSessionStatus.UNSUBMITTED:
				return <XCircleIcon className="h-5 w-5 text-[#B42318]" />;
			case AssignedSessionStatus.NOT_AVAILABLE:
				return <LockClosedIcon className="h-5 w-5 text-gray-400" />;
		}
	};

	return (
		<div className="mb-4">
			{/* Remove the border and rounded corners, add bottom border */}
			<div className="border-b border-gray-200 overflow-x-auto">
				<nav className="flex">
					{tabs.map((tab, index) => {
						let label;

						if (tab.interviewStageType) {
							if (tab.interviewStageType === "screening") {
								label = "Screening";
							} else if (tab.interviewStageType === "middle") {
								label = "Middle";
							} else if (tab.interviewStageType === "final") {
								label = "Final";
							} else if (tab.interviewStageType === "resume") {
								label = "Resume";
							}
						} else if (tab.frontlineStageType) {
							if (["deny", "comply", "gather"].includes(tab.frontlineStageType)) {
								label = `Call ${tab.frontlineStageOrderNumber}`;
							}
						} else if (tab.activityType === "first") {
							label = "Pre";
						} else if (tab.activityType === "middle") {
							label = `Mid ${index}`;
						} else if (tab.activityType === "last") {
							label = "Post";
						} else {
							label = tab.label;
						}

						return (
							<button
								key={index}
								className={`flex flex-col items-center px-4 md:px-6 py-2 text-sm font-medium snap-start ${
									index === selectedTab
										? "border-b-2 border-[#00a9af] bg-[#00A9AF13]" // Updated background color with 40% opacity
										: "border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:bg-gray-100"
								} transition-all duration-200 ease-in-out`}
								onClick={() => {
									onTabSelect(tab.scheduleId);
									if (type === "user") {
										navigate(`/user/assignment/${assignmentId}/user/${userId}/schedule/${tab.scheduleId}`, {
											replace: true
										});
									} else {
										navigate(`/admin/assignment/${assignmentId}/schedule/${tab.scheduleId}`, {
											replace: true
										});
									}
								}}
							>
								<div className="flex flex-col gap-2 items-center">
									<div className="flex items-center">
										{getIcon(tab.status)}
										<span className={`ml-2 ${index === selectedTab ? "font-bold" : ""}`}>{label}</span>
									</div>
									<span className="text-xs text-gray-500">{tab.date ? tab.date.toLocaleDateString() : "TBD"}</span>
									{renderStatus(tab.status)}
								</div>
							</button>
						);
					})}
				</nav>
			</div>
		</div>
	);
};

export default HorizontalTabs;
