import React, { useState } from "react";
import { AssignedSessionView, ActivityType } from "../../types/AssignedSessionView";
import SessionCount from "../Utility/SessionCount";
import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";
import { Card, CardContent } from "../../components/shadcn-ui/Card";
import posthog from "posthog-js";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { getStatusCardStyles } from "../../utils/statusStyles";
import { formatStatus } from "./HorizontalTabs";
import { useAppContext } from "../../contexts/AppContext";
import { UserCircle } from "lucide-react";

// Define the props interface for the UserAssignedSessionCard component
interface UserAssignedSessionCardProps {
	stat: Partial<AssignedSessionView>;
	userId: string | null;
	isPending?: boolean;
}

/**
 * Formats a date relative to the current date
 * @param date The date to format
 * @returns A string describing the relative time (e.g., "Due today", "Due in 3 days")
 */
const formatRelativeDate = (date: Date) => {
	const now = new Date();
	const diffTime = date.getTime() - now.getTime();
	const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffTime < 0) return "Past due";
	if (diffHours < 24) return "Due today";
	if (diffDays === 1) return "Due tomorrow";
	if (diffDays > 1 && diffDays <= 7) return `Due in ${diffDays} days`;
	if (diffDays > 7 && diffDays <= 14) return "Due in 1 week";
	if (diffDays > 14 && diffDays <= 30) return `Due in ${Math.floor(diffDays / 7)} weeks`;
	if (diffDays > 30) return `Due in ${Math.floor(diffDays / 30)} months`;
	return `Due ${Math.abs(diffDays)} days ago`;
};

/**
 * Determines the session status based on the due date
 * @param dueDate The due date of the session
 * @returns A string representing the session status
 */
const getSessionStatus = (dueDate: Date): string => {
	const now = new Date();
	const oneDayFromNow = new Date(now.getTime() + 24 * 60 * 60 * 1000);
	const threeDaysFromNow = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);

	if (dueDate < now) return "Past Due Date";
	if (dueDate <= oneDayFromNow) return "Available Now";
	if (dueDate <= threeDaysFromNow) return "Due Soon";
	return "Available Soon";
};

/**
 * UserAssignedSessionCard component
 * Displays information about an assigned session, including title, course, status, and actions
 */
const UserAssignedSessionCard: React.FC<UserAssignedSessionCardProps> = ({ stat, userId, isPending = false }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { instageUser } = useAppContext();
	const isUnsubmitted =
		stat.status === AssignedSessionStatus.IN_PROGRESS ||
		stat.status === AssignedSessionStatus.UNSUBMITTED ||
		stat.status === AssignedSessionStatus.INCOMPLETE;

	/**
	 * Starts the session and navigates to the assignment page
	 */
	const startSession = async () => {
		try {
			posthog.capture("MINOR: User Opened Assignment | Start Button Clicked", {
				userId: instageUser?.fronteggId,
				timestamp: new Date().toISOString()
			});
		} catch (err) {
			console.error("Error capturing event 'MINOR: User Opened Assignment | Start Button Clicked':", err);
		}
		setIsLoading(true);
		try {
			if (!userId) return;
			if (!stat.assignmentId) return;
			NavigateService.navToUserAssignment(stat.assignmentId, userId, stat.scheduleId);
		} catch (error) {
			console.error("Failed to start session:", error);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * Navigates to the pending session page
	 */
	const navigateToPendingSession = () => {
		if (!userId) return;
		if (!stat.assignmentId) return;
		NavigateService.navToUserAssignment(stat.assignmentId, userId, stat.scheduleId);
	};

	// Extract and set default values for session information
	const scheduleCount = stat.scheduleCount || 0;
	const scheduleNumber = stat.scheduleNumber || 0;
	const date = stat.date || new Date();

	/**
	 * Generates the due text based on the date
	 * @param date The due date
	 * @returns A string describing when the session is due
	 */
	const getDueText = (date: Date) => {
		const now = new Date();
		const diffTime = date.getTime() - now.getTime();
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (diffDays === 0) return "Due today";
		if (diffDays === 1) return "Due tomorrow";
		if (diffDays > 1) return `Due in ${diffDays} days`;
		return "Past due";
	};

	const getFrontlineStageType = (frontlineStageType: string) => {
		switch (frontlineStageType) {
			case "deny":
				return "Denying a Request";
			case "comply":
				return "Complying with a Request";
			case "gather":
				return "Information Gathering";
			default:
				return frontlineStageType;
		}
	};

	return (
		<Card className="mb-4 shadow-sm">
			<CardContent className="p-6">
				<div className="flex flex-col h-full">
					<div className="flex items-start justify-between mb-2">
						<div className="flex items-start gap-2">
							{isUnsubmitted && (
								<span
									className={`self-start px-2 py-1 text-xs font-medium rounded ${getStatusCardStyles(
										stat.status || AssignedSessionStatus.NOT_CONFIRMED
									)}`}
								>
									{formatStatus(stat.status || "")}
								</span>
							)}
							{/* Display "Available Now" status for non-pending, available sessions */}
							{!isUnsubmitted && !isPending && stat.status === AssignedSessionStatus.AVAILABLE && (
								<span
									className={`self-start px-2 py-1 text-xs font-medium rounded ${getStatusCardStyles(
										stat.status || AssignedSessionStatus.NOT_CONFIRMED
									)}`}
								>
									Available Now
								</span>
							)}
							{/* Display "Self Practice" status for solo mode sessions */}
							{stat.soloMode && (
								<span className="inline-flex items-center px-2 py-1 text-xs font-medium rounded bg-purple-100 text-purple-800 border border-purple-200">
									<UserCircle className="w-3 h-3 mr-1" />
									Self Practice
								</span>
							)}
						</div>
					</div>
					{/* Display assignment title and course name */}
					<h2 className={`text-xl font-semibold text-primary-text font-plus-jakarta mb-1 ${isPending ? "truncate" : ""}`}>
						{stat.assignmentTitle}
					</h2>
					<p className="text-sm text-gray-600 mb-4">{stat.courseName}</p>
					{isPending ? (
						// Display for pending sessions
						<div className="flex flex-col">
							{/* Show activity type */}
							<div className="text-[#303d7c] font-medium font-plus-jakarta mb-2">
								{stat.interviewStageType
									? stat.interviewStageType.charAt(0).toUpperCase() + stat.interviewStageType.slice(1) + " Stage"
									: stat.frontlineStageType
									? getFrontlineStageType(stat.frontlineStageType)
									: stat.activityType === ActivityType.FIRST
									? "Pre-Experience"
									: stat.activityType === ActivityType.MIDDLE
									? "Mid-Experience"
									: "Last-Experience"}
							</div>
							{/* Display session count */}
							<SessionCount currentCount={scheduleNumber} totalCount={scheduleCount} />
							{/* Button to navigate to pending session */}
							<Button
								variant="outline"
								className="self-start mt-4 flex items-center text-primary-text rounded-[57px] bg-[#EFEFEF] hover:bg-[#E5E5E5]"
								onClick={navigateToPendingSession}
							>
								<ClockIcon className="h-5 w-5 mr-2 text-primary-text" />
								{getDueText(stat.date || new Date())}
							</Button>
						</div>
					) : (
						// Display for non-pending sessions
						<div className="flex justify-between items-end mt-auto">
							<div className="flex-1">
								<div className="mb-4">
									{/* Show activity type */}
									<div className="text-[#303d7c] font-medium font-plus-jakarta mb-2">
										{stat.interviewStageType
											? stat.interviewStageType.charAt(0).toUpperCase() + stat.interviewStageType.slice(1) + " Stage"
											: stat.frontlineStageType
											? getFrontlineStageType(stat.frontlineStageType)
											: stat.activityType === ActivityType.FIRST
											? "Pre-Experience"
											: stat.activityType === ActivityType.MIDDLE
											? "Mid-Experience"
											: "Last-Experience"}
									</div>
									{/* Display session count */}
									<SessionCount currentCount={scheduleNumber} totalCount={scheduleCount} />
								</div>
							</div>
							<div className="flex flex-col items-end">
								{/* Display due date for non-pending sessions */}
								{!isPending && (
									<div className="flex items-center mb-2">
										<CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
										<span className="text-sm text-gray-600">{formatRelativeDate(date)}</span>
									</div>
								)}
								{/* Button to start the session */}
								<Button onClick={startSession} disabled={isLoading} variant={isLoading ? "disabled" : "default"}>
									{isLoading ? "Starting..." : isUnsubmitted ? "Review" : "Start Session"}
								</Button>
							</div>
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	);
};

export default UserAssignedSessionCard;
