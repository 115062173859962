import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import TemplateViewer from "../Chat/TemplateViewer";
import UploadJobForm from "../Chat/UploadJobForm";
import { ArrowUpTrayIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";
import { useAppContext } from "../../contexts/AppContext";
import BackButton from "../Utility/BackButton";
import { ResumeService } from "../../services/ResumeService";
import { useParams } from "react-router-dom";
import { ResumeView } from "../../types/ResumeView";
import { TemplateType, templates } from "../Chat/ResumeTemplates/TemplateRegistry";
import htmlDocx from "html-docx-js/dist/html-docx";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import ResumeFeedback from "../Chat/ResumeFeedback";

export interface UploadJobFormProps {
	profile: ProfileView | null;
	setJobTitle: (jobTitle: string) => void;
	setJobDescription: (jobDescription: string) => void;
	jobTitle: string;
	jobDescription: string;
	generateResume: () => void;
}

export interface TemplateViewerProps {
	profile: ProfileView | null;
	jobTitle: string;
	jobDescription: string;
}

export interface ResumeStyleProps {
	fontSize: number;
	accentColor: string;
}

const ResumeAssist: React.FC = () => {
	// Define a discriminated union type for steps
	const { resumeId } = useParams();
	type Step = {
		component: React.ComponentType<any>;
		label: string;
		icon: React.ForwardRefExoticComponent<any>;
		type: "upload" | "template";
	};

	const [currentStep, setCurrentStep] = useState(0);
	const [jobTitle, setJobTitle] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const { instageUser } = useAppContext();
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [resume, setResume] = useState<ResumeView | null>(null);
	const [style, setStyle] = useState<ResumeStyleProps>({
		fontSize: 1,
		accentColor: "#00a9af"
	});
	const [template, setTemplate] = useState<TemplateType>("single-column");

	useEffect(() => {
		async function fetchProfile() {
			console.log("fetchProfile");
			if (!instageUser) {
				return;
			}
			console.log("instageUser", instageUser);
			const fetchedProfile = await ProfileService.fetchProfileByUserId(instageUser.id);
			if (!fetchedProfile) {
				// Show Profile Not Found UI
				return;
			}
			console.log("fetchedProfile", fetchedProfile);
			setProfile(fetchedProfile);
		}
		fetchProfile();
	}, [instageUser]);

	useEffect(() => {
		async function fetchResume() {
			if (!resumeId) {
				return;
			}
			const fetchedResume = await ResumeService.fetchResume(resumeId);
			if (!fetchedResume) {
				return;
			}
			console.log("fetchedResume", fetchedResume);
			setJobTitle(fetchedResume.jobTitle);
			setJobDescription(fetchedResume.jobDescription);
			setResume(fetchedResume);
		}
		fetchResume();
	}, [resumeId]);

	const steps: Step[] = [
		{
			component: UploadJobForm,
			label: "Upload Job Posting",
			icon: ArrowUpTrayIcon,
			type: "upload"
		},
		{
			component: TemplateViewer,
			label: "Resume Editor",
			icon: WrenchScrewdriverIcon,
			type: "template"
		},
		{
			component: TemplateViewer,
			label: "Cover Letter Editor",
			icon: WrenchScrewdriverIcon,
			type: "template"
		}
	];

	const generateResume = async () => {
		console.log("generateResume");
		if (!profile) {
			console.log("No profile found");
			return;
		}
		if (!resumeId) {
			console.log("No resumeId found");
			return;
		}
		const generatedResume = await ResumeService.generateResume(profile.id, jobTitle, jobDescription, resumeId);

		// Merge the resumes while preserving the id and other fields
		const mergedResume: ResumeView = {
			...generatedResume,
			id: resumeId,
			userId: generatedResume.userId,
			userEmail: generatedResume.userEmail,
			createdAt: resume?.createdAt || generatedResume.createdAt,
			updatedAt: new Date()
		};

		setResume(mergedResume);
		console.log("Updated resume:", mergedResume);
		setCurrentStep(1);
	};

	const downloadDocx = () => {
		if (!resume) return;

		const TemplateComponent = templates[template].component;
		const html = ReactDOMServer.renderToString(<TemplateComponent resume={resume} style={style} />);
		const htmlWithStyles = `<!DOCTYPE html><html><head></head><body>${html}</body></html>`;

		const converted = htmlDocx.asBlob(htmlWithStyles, {
			orientation: "portrait",
			margins: {
				top: 0,
				right: 0,
				bottom: 720,
				left: 0,
				header: 0,
				footer: 720,
				gutter: 0
			}
		});

		const url = URL.createObjectURL(converted);
		const a = document.createElement("a");
		a.href = url;
		a.download = "resume.docx";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	const downloadPdf = () => {
		if (!resume) return;

		const TemplateComponent = templates[template].component;
		const html = ReactDOMServer.renderToString(<TemplateComponent resume={resume} style={style} />);
		const htmlWithStyles = `<!DOCTYPE html><html><head></head><body>${html}</body></html>`;

		const element = document.createElement("div");
		element.innerHTML = htmlWithStyles;
		document.body.appendChild(element);

		const options = {
			margin: 10,
			filename: "resume.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: {
				scale: 2,
				useCORS: true,
				logging: true,
				letterRendering: true
			},
			jsPDF: {
				unit: "mm",
				format: "a4",
				orientation: "portrait"
			}
		};

		html2pdf()
			.set(options)
			.from(element)
			.save()
			.then(() => {
				document.body.removeChild(element);
			})
			.catch((err: Error) => {
				console.error("PDF generation failed:", err);
				document.body.removeChild(element);
			});
	};

	const handleStyleChange = (property: keyof ResumeStyleProps, value: string | number) => {
		console.log(`handleStyleChange - ${property}:`, value);
		setStyle((prevStyle) => ({
			...prevStyle,
			[property]: value
		}));
	};

	return (
		<div>
			{/* <p>{resume?.id}</p> */}
			{resume && currentStep === 1 && <ResumeFeedback resume={resume} />}

			<div className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/4 md:pr-8 mb-8 md:mb-0">
					<BackButton />
					<div className="bg-white rounded-lg p-6 border border-[#eaecf0]">
						<h1 className="text-2xl font-bold text-gray-900 mb-6">Resume Creator</h1>
						<div className="mb-6">
							<div className="h-1 w-full bg-gray-200 rounded-full">
								<div
									className="h-1 bg-[#00a9af] rounded-full"
									style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
								></div>
							</div>
						</div>
						{steps.map((step, index) => (
							<button
								key={index}
								className={`flex items-center w-full p-3 mb-2 rounded-lg transition-colors border-2
								${currentStep === index ? "border-[#00a9af] text-[#00a9af]" : "border-transparent hover:bg-gray-50 text-gray-700"}`}
								onClick={() => setCurrentStep(index)}
							>
								<div
									className={`p-2 rounded-full mr-2
								${currentStep === index ? "bg-[#00a9af]/10" : "bg-gray-100"}`}
								>
									{React.createElement(step.icon, {
										className: `w-5 h-5 ${currentStep === index ? "text-[#00a9af]" : "text-gray-600"}`
									})}
								</div>
								<span>{step.label}</span>
							</button>
						))}
					</div>
					<div className="bg-white rounded-lg p-6 border border-[#eaecf0] mt-4">
						<h1 className="text-2xl font-bold text-gray-900 mb-6">Style</h1>
						<div className="space-y-6">
							<div className="flex flex-col">
								<label htmlFor="templateSelect" className="text-gray-700 mb-3">
									Template Layout
								</label>
								<select
									id="templateSelect"
									value={template}
									onChange={(e) => setTemplate(e.target.value as TemplateType)}
									className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#00a9af] focus:ring-1 focus:ring-[#00a9af]"
								>
									{Object.entries(templates).map(([key, { name, description }]) => (
										<option key={key} value={key} title={description}>
											{name}
										</option>
									))}
								</select>
								<br />
								<label htmlFor="fontSizeSlider" className="text-gray-700 mb-3">
									Font Size: {style.fontSize}
								</label>
								<input
									type="range"
									id="fontSizeSlider"
									min="0.8"
									max="1.3"
									step="0.1"
									defaultValue="1"
									className="w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:bg-gray-200 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:h-1 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#00a9af] [&::-webkit-slider-thumb]:mt-[-4px]"
									onChange={(e) => handleStyleChange("fontSize", parseFloat(e.target.value))}
								/>
								<br />
								<label htmlFor="accentColorSlider" className="text-gray-700 mb-3">
									Accent Color: {style.accentColor}
								</label>
								<input
									type="color"
									id="accentColorSlider"
									min="0.8"
									max="1.3"
									step="0.1"
									defaultValue="#00a9af"
									className="w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:bg-gray-200 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:h-1 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#00a9af] [&::-webkit-slider-thumb]:mt-[-4px]"
									onChange={(e) => handleStyleChange("accentColor", e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* Main Component */}
				<div className="w-full md:w-3/4">
					{currentStep === 0 ? (
						<UploadJobForm
							profile={profile}
							setJobTitle={setJobTitle}
							setJobDescription={setJobDescription}
							jobTitle={jobTitle}
							jobDescription={jobDescription}
							generateResume={generateResume}
						/>
					) : currentStep === 1 ? (
						<>
							{resume && (
								<div className="h-full flex flex-col">
									<div className="p-0 rounded-lg shadow-sm mb-4">
										<div className="flex justify-between items-center mb-4">
											<h2 className="text-xl font-semibold text-gray-800">Resume Preview</h2>
											<div className="flex gap-2">
												<button
													className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
													onClick={downloadDocx}
												>
													Download as DOCX
												</button>
												{/* <button
												className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
												onClick={downloadPdf}
											>
												Download as PDF
											</button> */}
											</div>
										</div>
										<div className="bg-white border p-0 min-h-[calc(100vh-250px)] overflow-y-auto m-0 rounded-lg">
											{React.createElement(templates[template].component, {
												resume,
												style,
												onUpdate: (updatedResume) => setResume(updatedResume)
											})}
										</div>
									</div>
								</div>
							)}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ResumeAssist;
