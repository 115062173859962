import React, { useRef, useEffect } from "react";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";

interface ProfileInfoBoxProps {
	profile: ProfileView;
	setSelectedSection: (section: string) => void;
	selectedSection: string;
	unconfirmedSections: string[];
	setUnconfirmedSections: (sections: string[]) => void;
	setProfile?: (profile: ProfileView) => void;
}

type SectionRefs = {
	[key: string]: React.RefObject<HTMLElement>;
};

const ProfileInfoBox: React.FC<ProfileInfoBoxProps> = ({
	profile,
	setSelectedSection,
	selectedSection,
	unconfirmedSections,
	setUnconfirmedSections,
	setProfile
}) => {
	const sectionRefs: SectionRefs = {
		contact: useRef<HTMLElement>(null),
		summary: useRef<HTMLElement>(null),
		experience: useRef<HTMLElement>(null),
		education: useRef<HTMLElement>(null),
		skills: useRef<HTMLElement>(null),
		projects: useRef<HTMLElement>(null),
		certifications: useRef<HTMLElement>(null),
		extraCurricular: useRef<HTMLElement>(null),
		interests: useRef<HTMLElement>(null)
	};

	useEffect(() => {
		if (selectedSection && sectionRefs[selectedSection]?.current) {
			sectionRefs[selectedSection].current?.scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		}
	}, [selectedSection]);

	const formatDate = (date: Date) => {
		return new Date(date).toLocaleDateString();
	};

	const getSectionClassName = (sectionName: string) => {
		return `mb-8 p-4 rounded-lg shadow-md cursor-pointer transition-all duration-200 scroll-mt-16
			${
				selectedSection === sectionName
					? "border-[#00a9af] border-2 bg-[#00a9af]/5 shadow-lg transform scale-[1.00]"
					: "border-gray-200 border bg-white hover:border-[#00a9af]/50"
			}`;
	};

	const handleSectionClick = (sectionName: string) => {
		// setSelectedSectionName(sectionName);
		setSelectedSection(sectionName);
	};

	const handleConfirmSection = async (sectionName: string) => {
		await ProfileService.updateProfileSection(profile.id, sectionName, profile[sectionName as keyof ProfileView]);
		setUnconfirmedSections(unconfirmedSections.filter((section) => section !== sectionName));
		// if (setProfile) {
		// 	setProfile({ ...profile });
		// }
	};

	return (
		<div className="max-w-7xl mx-auto overflow-hidden px-0">
			<section ref={sectionRefs.contact} className={getSectionClassName("contact")} onClick={() => handleSectionClick("contact")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Contact Information</h2>
					{unconfirmedSections.includes("contact") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("contact")}
						>
							Confirm
						</button>
					)}
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
					<div className="p-2">
						<strong>Name:</strong> {profile.contact.firstName} {profile.contact.lastName}
					</div>
					<div className="p-2">
						<strong>Email:</strong> {profile.contact.email}
					</div>
					<div className="p-2">
						<strong>Phone:</strong> {profile.contact.phone}
					</div>
					<div className="p-2">
						<strong>Address:</strong> {profile.contact.address}
					</div>
					{profile.contact.linkedinURL && (
						<div className="p-2">
							<strong>LinkedIn:</strong>{" "}
							<a
								href={profile.contact.linkedinURL}
								target="_blank"
								rel="noopener noreferrer"
								className="text-[#00a9af] hover:underline"
							>
								Profile
							</a>
						</div>
					)}
					{profile.contact.website && (
						<div className="p-2">
							<strong>Website:</strong>{" "}
							<a href={profile.contact.website} target="_blank" rel="noopener noreferrer" className="text-[#00a9af] hover:underline">
								{profile.contact.website}
							</a>
						</div>
					)}
				</div>
			</section>

			<section ref={sectionRefs.summary} className={getSectionClassName("summary")} onClick={() => handleSectionClick("summary")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Summary</h2>
					{unconfirmedSections.includes("summary") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("summary")}
						>
							Confirm
						</button>
					)}
				</div>
				<p className="text-gray-700">{profile.summary}</p>
			</section>

			<section ref={sectionRefs.experience} className={getSectionClassName("experience")} onClick={() => handleSectionClick("experience")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Experience</h2>
					{unconfirmedSections.includes("experience") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("experience")}
						>
							Confirm
						</button>
					)}
				</div>
				{profile.experience?.map((exp) => (
					<div key={exp.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
						<h3 className="text-xl font-semibold">{exp.jobTitle}</h3>
						<p className="text-gray-600 text-sm">
							{exp.companyName} | {exp.city}, {exp.stateOrProv}
						</p>
						<p className="text-gray-600 text-sm">
							{exp.startMonth} {exp.startYear} - {exp.endMonth} {exp.endYear}
						</p>
						<ul className="list-disc pl-6 mt-2">
							{exp.roles?.map((role, index) => (
								<li key={index} className="text-gray-700 mb-1">
									{role}
								</li>
							))}
						</ul>
					</div>
				))}
			</section>

			<section ref={sectionRefs.education} className={getSectionClassName("education")} onClick={() => handleSectionClick("education")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Education</h2>
					{unconfirmedSections.includes("education") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("education")}
						>
							Confirm
						</button>
					)}
				</div>
				{profile.education?.map((edu) => (
					<div key={edu.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
						<h3 className="text-xl font-semibold">{edu.school}</h3>
						<p className="text-gray-700">
							{edu.degree} in {edu.program}
						</p>
						<p className="text-gray-600">Graduating Year: {edu.graduatingYear}</p>
						{edu.notes?.length > 0 && (
							<ul className="list-disc pl-6 mt-2">
								{edu.notes?.map((note, index) => (
									<li key={index} className="text-gray-700 mb-1">
										{note}
									</li>
								))}
							</ul>
						)}
					</div>
				))}
			</section>

			<section ref={sectionRefs.skills} className={getSectionClassName("skills")} onClick={() => handleSectionClick("skills")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Skills</h2>
					{unconfirmedSections.includes("skills") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("skills")}
						>
							Confirm
						</button>
					)}
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Technical Skills</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills.technical?.map((skill, index) => (
								<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
									{skill}
								</span>
							))}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Core Competencies</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills.coreCompetencies?.map((skill, index) => (
								<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
									{skill}
								</span>
							))}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Languages</h3>
						<div className="flex flex-wrap gap-2">
							{profile.skills.languages?.map((lang, index) => (
								<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
									{lang}
								</span>
							))}
						</div>
					</div>
					<div>
						<h3 className="text-lg font-semibold text-gray-800 mb-2">Tools & Frameworks</h3>
						<div className="flex flex-wrap gap-2">
							{[...profile.skills.tools, ...profile.skills.frameworks]?.map((item, index) => (
								<span key={index} className="bg-[#00a9af]/10 text-[#00a9af] px-2 py-1 rounded-md text-sm">
									{item}
								</span>
							))}
						</div>
					</div>
				</div>
			</section>

			<section ref={sectionRefs.projects} className={getSectionClassName("projects")} onClick={() => handleSectionClick("projects")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Projects</h2>
					{unconfirmedSections.includes("projects") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("projects")}
						>
							Confirm
						</button>
					)}
				</div>
				{profile.projects?.map((project) => (
					<div key={project.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
						<h3 className="text-xl font-semibold">{project.projectName}</h3>
						<p className="text-gray-600 text-sm">
							{project.startMonth} {project.startYear} - {project.endMonth} {project.endYear}
						</p>
						<p className="text-gray-700 mt-2">{project.description}</p>
						{project.projectURL && (
							<p className="mt-2">
								<a href={project.projectURL} target="_blank" rel="noopener noreferrer" className="text-[#00a9af] hover:underline">
									Project Link
								</a>
							</p>
						)}
						{project.notes?.length > 0 && (
							<ul className="list-disc pl-6 mt-2">
								{project.notes?.map((note, index) => (
									<li key={index} className="text-gray-700 mb-1">
										{note}
									</li>
								))}
							</ul>
						)}
					</div>
				))}
			</section>

			<section
				ref={sectionRefs.certifications}
				className={getSectionClassName("certifications")}
				onClick={() => handleSectionClick("certifications")}
			>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Certifications</h2>
					{unconfirmedSections.includes("certifications") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("certifications")}
						>
							Confirm
						</button>
					)}
				</div>

				{profile.certifications?.map((cert) => (
					<div key={cert.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
						<h3 className="text-xl font-semibold">{cert.certificationName}</h3>
						<p className="text-gray-700">{cert.issuingOrganization}</p>
						<p className="text-gray-600 text-sm">
							Issued: {cert.issueMonth} {cert.issueYear}
							{cert.expirationMonth && cert.expirationYear && (
								<span>
									{" "}
									| Expires: {cert.expirationMonth} {cert.expirationYear}
								</span>
							)}
						</p>
					</div>
				))}
			</section>

			<section
				ref={sectionRefs["extraCurricular"]}
				className={getSectionClassName("extraCurricular")}
				onClick={() => handleSectionClick("extraCurricular")}
			>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Extra-Curricular Activities</h2>
					{unconfirmedSections.includes("extraCurricular") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("extraCurricular")}
						>
							Confirm
						</button>
					)}
				</div>
				{profile.extraCurricular?.map((activity) => (
					<div key={activity.id} className="mb-6 p-4 border-l-4 border-[#00a9af] bg-gray-50">
						<h3 className="text-xl font-semibold">{activity.activity}</h3>
						<p className="text-gray-700">{activity.orgName}</p>
						<p className="text-gray-600 text-sm">
							{activity.startMonth} {activity.startYear} - {activity.endMonth} {activity.endYear}
						</p>
						<ul className="list-disc pl-6 mt-2">
							{activity.tasks?.map((task, index) => (
								<li key={index} className="text-gray-700 mb-1">
									{task}
								</li>
							))}
						</ul>
					</div>
				))}
			</section>

			<section ref={sectionRefs.interests} className={getSectionClassName("interests")} onClick={() => handleSectionClick("interests")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Interests</h2>
					{unconfirmedSections.includes("interests") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("interests")}
						>
							Confirm
						</button>
					)}
				</div>
				<div>
					<ul className="list-disc pl-6">
						{profile.interests?.map((interest, index) => (
							<li key={index} className="text-gray-700 mb-1">
								{interest}
							</li>
						))}
					</ul>
				</div>
			</section>

			<section ref={sectionRefs.hobbies} className={getSectionClassName("hobbies")} onClick={() => handleSectionClick("hobbies")}>
				<div className="flex justify-between items-center border-b-2 border-[#00a9af] pb-2 mb-4">
					<h2 className="text-2xl text-gray-800">Hobbies</h2>
					{unconfirmedSections.includes("hobbies") && (
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 disabled:cursor-not-allowed h-9 px-4 py-2 text-base"
							onClick={() => handleConfirmSection("hobbies")}
						>
							Confirm
						</button>
					)}
				</div>
				<div>
					<ul className="list-disc pl-6">
						{profile.hobbies?.map((hobby, index) => (
							<li key={index} className="text-gray-700 mb-1">
								{hobby}
							</li>
						))}
					</ul>
				</div>
			</section>
		</div>
	);
};

export default ProfileInfoBox;
