import React, { useState, useEffect } from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { ResumeView } from "../../types/ResumeView";
import htmlDocx from "html-docx-js/dist/html-docx";
import html2pdf from "html2pdf.js";
import ResumeTemplate from "./ResumeTemplate";
import { ResumeStyleProps } from "../../components/UserPages/ResumeAssist";
import ReactDOMServer from "react-dom/server";

interface TemplateViewerProps {
	documentUrl?: string;
	resume?: ResumeView;
	style?: ResumeStyleProps;
	template?: "single" | "minimal";
}

const TemplateViewer: React.FC<TemplateViewerProps> = ({ documentUrl, resume: initialResume, style, template = "single" }) => {
	const [resume, setResume] = useState<ResumeView | undefined>(initialResume);

	useEffect(() => {
		setResume(initialResume);
	}, [initialResume]);

	const handleResumeUpdate = (updatedResume: ResumeView) => {
		setResume(updatedResume);
	};

	const downloadDocx = () => {
		if (!resume) return;

		const html = ReactDOMServer.renderToString(<ResumeTemplate resume={resume} style={style} />);
		const htmlWithStyles = `<!DOCTYPE html><html><head></head><body>${html}</body></html>`;

		const converted = htmlDocx.asBlob(htmlWithStyles, {
			orientation: "portrait",
			margins: {
				top: 0,
				right: 0,
				bottom: 720,
				left: 0,
				header: 0,
				footer: 720,
				gutter: 0
			}
		});

		const url = URL.createObjectURL(converted);
		const a = document.createElement("a");
		a.href = url;
		a.download = "resume.docx";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	const downloadPdf = () => {
		if (!resume) return;

		const html = ReactDOMServer.renderToString(<ResumeTemplate resume={resume} style={style} />);
		const htmlWithStyles = `<!DOCTYPE html><html><head></head><body>${html}</body></html>`;

		const element = document.createElement("div");
		element.innerHTML = htmlWithStyles;
		document.body.appendChild(element);

		const options = {
			margin: 10,
			filename: "resume.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: {
				scale: 2,
				useCORS: true,
				logging: true,
				letterRendering: true
			},
			jsPDF: {
				unit: "mm",
				format: "a4",
				orientation: "portrait"
			}
		};

		html2pdf()
			.set(options)
			.from(element)
			.save()
			.then(() => {
				document.body.removeChild(element);
			})
			.catch((err: Error) => {
				console.error("PDF generation failed:", err);
				document.body.removeChild(element);
			});
	};

	if (!resume) return null;

	return (
		<div className="h-full flex flex-col">
			<div className="flex-1 p-0 rounded-lg shadow-sm">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-semibold text-gray-800">Resume Preview</h2>
					<div className="flex gap-2">
						<button
							className="flex items-center gap-2 px-4 py-2 bg-[#00a9af] text-white rounded-lg hover:bg-[#008f94]"
							onClick={downloadDocx}
						>
							Download as DOCX
						</button>
					</div>
				</div>
				<div className="bg-white border rounded-lg h-[calc(100%-4rem)] overflow-hidden">
					<ResumeTemplate resume={resume} style={style} onUpdate={handleResumeUpdate} template={template} />
				</div>
			</div>
		</div>
	);
};

export default TemplateViewer;
