import { ResumeView } from "../../../types/ResumeView";
import { ResumeStyleProps } from "../../UserPages/ResumeAssist";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

export interface BaseTemplateProps {
	resume: ResumeView;
	style?: ResumeStyleProps;
	onUpdate?: (updatedResume: ResumeView) => void;
}

export interface SectionProps {
	title: string;
	section: string;
	children: React.ReactNode;
	onAdd?: () => void;
	onRemove?: (itemId: string) => void;
	showControls?: boolean;
}

// Common styling utilities that can be used across templates
export const commonStyles = {
	fontSize: (size: number, baseFontSize: number = 1) => `${size * (baseFontSize || 1)}pt`,
	accentColor: (style?: ResumeStyleProps) => style?.accentColor || "#003366",
	sectionTitle: (style?: ResumeStyleProps) => ({
		fontSize: `${16 * (style?.fontSize || 1)}pt`,
		color: style?.accentColor || "#003366",
		borderBottom: `1px solid ${style?.accentColor || "#003366"}`,
		paddingBottom: "3px",
		marginBottom: "12px",
		fontWeight: "bold"
	}),
	paragraph: (style?: ResumeStyleProps) => ({
		fontSize: `${11 * (style?.fontSize || 1)}pt`,
		lineHeight: "1.2",
		margin: "0"
	}),
	sectionControls: {
		position: "absolute" as const,
		top: "0",
		right: "0",
		display: "none",
		gap: "4px",
		padding: "4px",
		background: "white",
		borderRadius: "4px",
		boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
	},
	controlButton: {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "4px",
		borderRadius: "4px",
		border: "none",
		background: "transparent",
		cursor: "pointer",
		color: "#666",
		transition: "all 0.2s ease",
		":hover": {
			background: "#f3f4f6"
		}
	}
};
