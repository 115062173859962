import React, { useState, useEffect } from "react";
import { ResumeService } from "../../services/ResumeService";
import { CheckCircleIcon, XCircleIcon, BoltIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ResumeView, FeedbackData, Keyword } from "../../types/ResumeView";
import Spinner from "../Utility/Spinner";

interface FeedbackSection {
	title: string;
	score?: number;
	items?: string[] | Keyword[];
}

const ResumeFeedback = ({ resume }: { resume: ResumeView }) => {
	const [isFeedbackGenerated, setIsFeedbackGenerated] = useState(false);
	const [feedback, setFeedback] = useState<Partial<FeedbackData>>({});
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (resume?.feedback?.keywords.length > 0 && resume?.feedback?.matchScore !== 0) {
			setFeedback(resume?.feedback);
			setIsFeedbackGenerated(true);
		}
	}, [resume]);

	const generateFeedback = async () => {
		setIsLoading(true);
		const resumeId = resume?.id;
		if (!resumeId) {
			console.error("Resume ID is required to generate feedback");
			setIsLoading(false);
			return;
		}

		try {
			const generatedFeedback = await ResumeService.generateFeedback(resumeId);
			console.log(generatedFeedback?.keywords);
			setFeedback(generatedFeedback);
			setIsFeedbackGenerated(true);
		} finally {
			setIsLoading(false);
		}
	};

	const FeedbackSection: React.FC<FeedbackSection> = ({ title, score, items }) => (
		<div className="mb-6">
			<h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
			{score !== undefined && (
				<div className="flex items-center mb-2">
					<div className="w-full bg-gray-200 rounded-full h-2.5">
						<div className="bg-[#00a9af] h-2.5 rounded-full" style={{ width: `${score}%` }}></div>
					</div>
					<span className="ml-2 text-sm font-medium text-gray-600">{score}%</span>
				</div>
			)}
			{items && (
				<ul className="space-y-2">
					{items.map((item, index) => (
						<li key={index} className="flex items-start text-sm text-gray-600">
							<span className="flex-shrink-0 mt-0.5">
								{typeof item === "object" && "keyword" in item ? (
									item.isMissing ? (
										<XCircleIcon className="h-4 w-4 text-red-500 mr-2" />
									) : (
										<CheckCircleIcon className="h-4 w-4 text-[#00a9af] mr-2" />
									)
								) : (
									<BoltIcon className="h-4 w-4 text-[#00a9af] mr-2" />
								)}
							</span>
							<span className="flex-1">{typeof item === "object" && "keyword" in item ? item.keyword : item.toString()}</span>
						</li>
					))}
				</ul>
			)}
		</div>
	);

	return (
		<div
			className={`absolute left-0 top-[64px] bg-white shadow-md z-[1000] h-[calc(100vh-64px)] transition-all duration-300 ${
				isCollapsed ? "-translate-x-[317px]" : "translate-x-0"
			}`}
		>
			<div className="relative">
				<div className="w-[317px] overflow-y-auto h-[calc(100vh-64px)]">
					<div className="p-6">
						<h2 className="text-2xl font-bold text-gray-800 mb-6 border-b pb-2">Resume Feedback</h2>

						{isFeedbackGenerated && (
							<>
								<FeedbackSection title="Match Score" score={feedback?.matchScore} />

								<FeedbackSection title="Keywords" items={feedback?.keywords} />

								<FeedbackSection title="Suggested Improvements" items={feedback?.improvements} />
								<button
									className="bg-[#00a9af] text-white px-4 py-2 rounded-md w-full mb-4 mt-4 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
									onClick={() => generateFeedback()}
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<Spinner className="w-5 h-5 mr-2" /> Generating...
										</>
									) : (
										"Regenerate Feedback"
									)}
								</button>
							</>
						)}
						{!isFeedbackGenerated && (
							<div>
								<button
									className="bg-[#00a9af] text-white px-4 py-2 rounded-md w-full flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
									onClick={() => generateFeedback()}
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<Spinner className="w-5 h-5 mr-2" /> Generating...
										</>
									) : (
										"Generate Feedback"
									)}
								</button>
							</div>
						)}
					</div>
				</div>
				<button
					onClick={() => setIsCollapsed(!isCollapsed)}
					className="absolute top-1/2 -translate-y-1/2 -right-5 bg-white p-1 rounded-r shadow-sm hover:bg-gray-50"
				>
					{isCollapsed ? <ChevronRightIcon className="h-8 w-3 text-gray-400" /> : <ChevronLeftIcon className="h-8 w-3 text-gray-400" />}
				</button>
			</div>
		</div>
	);
};

export default ResumeFeedback;
