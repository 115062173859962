import React, { useEffect, useState } from "react";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { ResumeView } from "../../types/ResumeView";
import { useAppContext } from "../../contexts/AppContext";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import ProfileButton from "./ProfileButton";
import { ProfileService } from "../../services/ProfileService";
import { ProfileView } from "../../types/ProfileView";
import { useFeatureFlagEnabled } from "posthog-js/react";
import NoAccess from "../AuthPages/NoAccess";

const ResumeSelector = () => {
	const { instageUser } = useAppContext();
	const navigate = useNavigate();
	const [resumes, setResumes] = useState<ResumeView[]>([]);
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const resumeAssistFeatureFlag = useFeatureFlagEnabled("resume-assist");

	useEffect(() => {
		if (instageUser) {
			const loadProfile = async () => {
				const fetchedProfile = await ProfileService.fetchProfileByUserId(instageUser.id);
				console.log("fetchedProfile", fetchedProfile);
				setProfile(fetchedProfile);
			};
			loadProfile();
		}
	}, [instageUser]);

	useEffect(() => {
		if (instageUser) {
			fetchUserResumes(instageUser.id).then(setResumes);
		}
	}, [instageUser]);

	const fetchUserResumes = async (userId: string): Promise<ResumeView[]> => {
		const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/resume/saved/user/${userId}`);
		return plainToInstance(ResumeView, response.data as ResumeView[]);
	};

	const createResume = async () => {
		const createdResume = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/resume/user/${instageUser?.id}/create`);
		navigate(`/user/resume-assist/${createdResume.data.id}`);
	};

	const formatDate = (date: Date) => {
		return new Date(date).toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric"
		});
	};

	const resumeCard = (resume: ResumeView) => {
		return (
			<button
				key={resume.id}
				className="w-[200px] h-[300px] rounded-[12px] border border-[#171717] border-opacity-10
                flex flex-col items-center justify-between p-6 transition-all duration-200 
                hover:border-opacity-20 hover:bg-gray-50"
				onClick={() => navigate(`/user/resume-assist/${resume.id}`)}
			>
				<div className="w-full h-[200px] bg-gray-100 rounded-lg mb-4" />
				<div className="w-full text-left">
					<h3 className="font-medium text-gray-900 truncate">{resume.title || "Untitled Resume"}</h3>
					<p className="text-sm text-gray-500">{formatDate(resume.createdAt)}</p>
				</div>
			</button>
		);
	};

	const createButton = () => {
		return (
			<button
				className="w-[200px] h-[300px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 
                flex items-center justify-center transition-all duration-200 
                hover:border-opacity-40 hover:bg-gray-50"
				onClick={createResume}
			>
				<PlusIcon
					className="w-10 h-10 text-[#171717] text-opacity-20 transition-all duration-200 
                    hover:text-opacity-40"
				/>
			</button>
		);
	};

	return (
		<>
			{resumeAssistFeatureFlag && (
				<div className="h-full max-w-5xl mx-auto px-4">
					<h1 className="text-3xl font-bold text-[#16013e] mb-10">Resume Composer</h1>
					<ProfileButton userId={instageUser?.id || ""} profile={profile || null} />
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
						{createButton()}
						{resumes.map((resume) => resumeCard(resume))}
					</div>
				</div>
			)}
			{!resumeAssistFeatureFlag && (
				<div className="h-full max-w-5xl mx-auto px-4">
					<NoAccess />
				</div>
			)}
		</>
	);
};

export default ResumeSelector;
