import { InterviewPayload } from "./InterviewPayload";
import { FrontlinePayload } from "./FrontlinePayload";

export class SchedulePayload {
	title: string;
	date: Date | string | null;
	startDate: Date | string | null;
	interview?: InterviewPayload;
	frontlinePayload?: FrontlinePayload;

	constructor(data: Partial<SchedulePayload>) {
		this.title = data.title ?? "";

		// Handle date conversion
		if (data.date instanceof Date) {
			this.date = data.date;
		} else if (typeof data.date === "string") {
			this.date = new Date(data.date);
		} else {
			this.date = null;
		}

		// Handle startDate conversion
		if (data.startDate instanceof Date) {
			this.startDate = data.startDate;
		} else if (typeof data.startDate === "string") {
			this.startDate = new Date(data.startDate);
		} else {
			this.startDate = null;
		}

		this.interview = data.interview ? new InterviewPayload(data.interview) : undefined;
		this.frontlinePayload = data.frontlinePayload ? new FrontlinePayload(data.frontlinePayload) : undefined;
	}
}
