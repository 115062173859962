import React from "react";
import { BaseTemplateProps } from "./BaseTemplate";
import SingleColumnTemplate from "./SingleColumnTemplate";
import MinimalTemplate from "./MinimalTemplate";

export type TemplateType = "single-column" | "minimal";

interface TemplateInfo {
	component: React.ComponentType<BaseTemplateProps>;
	name: string;
	description: string;
}

// Registry of all available templates
export const templates: Record<TemplateType, TemplateInfo> = {
	"single-column": {
		component: SingleColumnTemplate,
		name: "Single Column Classic",
		description: "A traditional single-column layout with a clean, professional look"
	},
	minimal: {
		component: MinimalTemplate,
		name: "Minimal Clean",
		description: "A minimalist design with elegant typography and clean layout"
	}
};

// Helper function to get template component
export const getTemplateComponent = (templateType: TemplateType): React.ComponentType<BaseTemplateProps> => {
	return templates[templateType].component;
};
