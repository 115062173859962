import React, { useState, useEffect } from "react";
// import { Slider } from "../../../shadcn-ui/Slider";
import { ArrowUpIcon, ArrowDownIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../shadcn-ui/Dialog";
import { Button } from "../../../shadcn-ui/Button";
import { Textarea } from "../../../shadcn-ui/Textarea";
import {
	JobQuestion,
	InterviewPayload,
	InterviewStage,
	InterviewStageType,
	InterviewStagePlan,
	CoreQuestionType,
	QuestionType
} from "../../../../types/InterviewPayload";
import CustomTooltip from "../../../Utility/CustomTooltip";

interface FormValues {
	stages?: InterviewStage[];
	stagePlan: {
		[key: string]: InterviewStagePlan;
	};
	interview?: InterviewPayload;
}

interface InterviewPlanProps {
	interviewStageOrderNumber: number;
	values: FormValues;
	setFieldValue: (field: string, value: any) => void;
	isAdminPath?: boolean;
}

const DEFAULT_QUESTION_ORDER: CoreQuestionType[] = [
	"Recall",
	"Background",
	"Motivation",
	"Behavioral",
	"Technical",
	"Situational",
	"Cultural Fit",
	"General"
];

const INTERVIEWER_ATTITUDES = [
	{ value: "neutral", label: "Neutral" },
	{ value: "direct", label: "Direct" },
	{ value: "friendly", label: "Friendly" }
] as const;

// Add this interface after the QuestionSlot interface
interface CustomQuestion {
	type: QuestionType;
	text: string;
	context: string;
}

// Add this helper function near the top of the file
const isCustomTextValid = (question: CustomQuestion | null): boolean => {
	if (!question) return false;
	// If text is "auto", it's valid
	if (question.text === "auto") return true;
	// If custom text is selected, it must not be empty
	return question.text.trim().length > 0;
};

// Add this constant at the top of the file with other constants
const MAX_TOTAL_QUESTIONS = 15;

// Add these preset configurations near the top of the file
const QUESTION_PRESETS = {
	Standard: {
		name: "Standard",
		description: "Balanced mix of different question types",
		config: {
			technicalQuestions: 1,
			behavioralQuestions: 1,
			situationalQuestions: 1,
			culturalFitQuestions: 1,
			backgroundQuestions: 0,
			motivationQuestions: 0,
			generalQuestions: 0,
			recallQuestions: 0,
			questionOrder: ["Behavioral", "Technical", "Situational", "Cultural Fit"]
		}
	},
	Screening: {
		name: "Screening",
		description: "Screening questions",
		config: {
			technicalQuestions: 0,
			behavioralQuestions: 0,
			situationalQuestions: 0,
			culturalFitQuestions: 1,
			backgroundQuestions: 1,
			motivationQuestions: 1,
			generalQuestions: 0,
			recallQuestions: 0,
			questionOrder: ["Background", "Motivation", "Cultural Fit"]
		}
	},
	Technical: {
		name: "Technical",
		description: "Heavy emphasis on technical skills assessment",
		config: {
			technicalQuestions: 3,
			behavioralQuestions: 0,
			situationalQuestions: 1,
			culturalFitQuestions: 0,
			backgroundQuestions: 1,
			motivationQuestions: 0,
			generalQuestions: 0,
			recallQuestions: 1,
			questionOrder: ["Recall", "Background", "Technical", "Technical", "Technical", "Situational"]
		}
	},
	Behavioral: {
		name: "Behavioral",
		description: "Focus on behavioral and cultural assessment",
		config: {
			technicalQuestions: 0,
			behavioralQuestions: 3,
			situationalQuestions: 1,
			culturalFitQuestions: 1,
			backgroundQuestions: 0,
			motivationQuestions: 0,
			generalQuestions: 0,
			recallQuestions: 1,
			questionOrder: ["Recall", "Behavioral", "Behavioral", "Behavioral", "Situational", "Cultural Fit"]
		}
	},
	Final: {
		name: "Final",
		description: "Cultural fit and motivation assessment",
		config: {
			technicalQuestions: 0,
			behavioralQuestions: 0,
			situationalQuestions: 0,
			culturalFitQuestions: 2,
			backgroundQuestions: 0,
			motivationQuestions: 2,
			generalQuestions: 0,
			recallQuestions: 1,
			questionOrder: ["Recall", "Cultural Fit", "Cultural Fit", "Motivation", "Motivation"]
		}
	}
} as const;

type PresetKey = keyof typeof QUESTION_PRESETS;

const DEFAULT_QUESTIONS = QUESTION_PRESETS.Standard.config;

// Update the DEFAULT_QUESTIONS constant to use the appropriate preset based on stage type
const getDefaultPresetForStage = (stageType: InterviewStageType, middleStageNumber: number = 0): PresetKey => {
	switch (stageType) {
		case "screening":
			return "Screening";
		case "final":
			return "Final";
		case "middle":
			// First middle stage uses Technical preset
			if (middleStageNumber === 0) return "Technical";
			// Second middle stage uses Behavioral preset
			if (middleStageNumber === 1) return "Behavioral";
			// Any additional middle stages use Standard preset
			return "Standard";
		default:
			return "Standard";
	}
};

// Add this helper function at the top level
const determinePresetFromQuestions = (slots: JobQuestion[]): PresetKey => {
	// Get non-opening/closing questions
	const questions = slots.filter((slot) => slot.type !== "Opening" && slot.type !== "Closing").map((slot) => slot.type);

	// Check each preset to see if it matches
	for (const [presetKey, preset] of Object.entries(QUESTION_PRESETS)) {
		if (JSON.stringify(questions) === JSON.stringify(preset.config.questionOrder)) {
			return presetKey as PresetKey;
		}
	}

	// If no preset matches, it's customized
	return "Standard"; // Default to Standard when customized
};

// Add these helper functions at the top level
const hasScreeningStage = (stages?: InterviewStage[]): boolean => {
	return stages?.some((stage) => stage.enabled && stage.interviewStageType === "screening") ?? false;
};

const getCurrentStageType = (stages: InterviewStage[] | undefined, currentStageOrderNumber: number): InterviewStageType | undefined => {
	return stages?.find((stage) => stage.interviewStageOrderNumber === currentStageOrderNumber)?.interviewStageType;
};

// Update the getAvailablePresets function
const getAvailablePresets = (stageType: InterviewStageType | undefined): PresetKey[] => {
	if (!stageType) return ["Standard"];

	switch (stageType) {
		case "screening":
			return ["Standard", "Screening"];
		case "final":
			return ["Standard", "Final", "Technical", "Behavioral"];
		case "middle":
			return ["Standard", "Technical", "Behavioral"];
		default:
			return ["Standard"];
	}
};

// Update the getAvailableQuestionTypes function
const getAvailableQuestionTypes = (stageType: InterviewStageType | undefined, hasScreening: boolean): QuestionType[] => {
	const baseTypes: QuestionType[] = ["Behavioral", "Technical", "Situational", "Cultural Fit", "Background", "Motivation"];

	// Only show Recall if there's a screening stage and current stage is not screening
	if (hasScreening && stageType !== "screening") {
		baseTypes.push("Recall");
	}

	return baseTypes;
};

const InterviewPlan: React.FC<InterviewPlanProps> = ({ interviewStageOrderNumber, values, setFieldValue, isAdminPath = true }) => {
	const [questionSlots, setQuestionSlots] = useState<JobQuestion[]>([]);

	// Initialize question slots from stage plan or with defaults
	useEffect(() => {
		const stagePlan = values.stagePlan?.[interviewStageOrderNumber];
		if (stagePlan?.interviewQuestionList) {
			// Use existing question list from stage plan
			setQuestionSlots(stagePlan.interviewQuestionList);
		} else {
			// Initialize with default opening and closing questions
			setQuestionSlots([
				{ id: "0", type: "Opening", text: "auto", order: 0 },
				{ id: "999", type: "Closing", text: "auto", order: 999 }
			]);
		}
	}, [interviewStageOrderNumber, values.stagePlan]);

	const technicalField = `stagePlan.${interviewStageOrderNumber}.technicalQuestions`;
	const behavioralField = `stagePlan.${interviewStageOrderNumber}.behavioralQuestions`;
	const situationalField = `stagePlan.${interviewStageOrderNumber}.situationalQuestions`;
	const culturalFitField = `stagePlan.${interviewStageOrderNumber}.culturalFitQuestions`;
	const backgroundField = `stagePlan.${interviewStageOrderNumber}.backgroundQuestions`;
	const motivationField = `stagePlan.${interviewStageOrderNumber}.motivationQuestions`;
	const generalField = `stagePlan.${interviewStageOrderNumber}.generalQuestions`;
	const recallField = `stagePlan.${interviewStageOrderNumber}.recallQuestions`;
	// Replace the simple boolean states with computed values
	// const isDistributionCustomized = React.useMemo(() => {
	// 	const currentValues = values.stagePlan?.[interviewStageOrderNumber];
	// 	if (!currentValues) return false;

	// 	// Only compare question count fields, excluding questionOrder
	// 	const questionCountFields = [
	// 		"technicalQuestions",
	// 		"behavioralQuestions",
	// 		"situationalQuestions",
	// 		"culturalFitQuestions",
	// 		"backgroundQuestions",
	// 		"motivationQuestions",
	// 		"generalQuestions"
	// 	] as const;

	// 	return questionCountFields.some((key) => currentValues[key] !== DEFAULT_QUESTIONS[key]);
	// }, [values.stagePlan, interviewStageOrderNumber]);

	// const isOrderCustomized = React.useMemo(() => {
	// 	// Get current order excluding Opening and Closing
	// 	const currentOrder = questionSlots
	// 		.filter((slot): slot is JobQuestion & { type: CoreQuestionType } => slot.type !== "Opening" && slot.type !== "Closing")
	// 		.sort((a, b) => a.order - b.order) // Sort by order before mapping
	// 		.map((slot) => slot.type);

	// 	// If there are no core questions, return false
	// 	if (currentOrder.length === 0) return false;

	// 	// Group questions by type to check if we have more than one of each
	// 	const questionCounts = currentOrder.reduce((acc, type) => {
	// 		acc[type] = (acc[type] || 0) + 1;
	// 		return acc;
	// 	}, {} as Record<CoreQuestionType, number>);

	// 	// If any question type has more or less than exactly 1, it's customized
	// 	const hasNonStandardCount = Object.values(questionCounts).some((count) => count !== 1);
	// 	if (hasNonStandardCount) return true;

	// 	// Get the default order for the current number of questions
	// 	const expectedOrder = DEFAULT_QUESTION_ORDER.slice(0, currentOrder.length);

	// 	// Compare arrays
	// 	return !currentOrder.every((type, index) => type === expectedOrder[index]);
	// }, [questionSlots]);

	// Initialize stage plan if it doesn't exist
	React.useEffect(() => {
		// Only initialize if stagePlan doesn't exist OR if it's completely empty
		const currentStagePlan = values.stagePlan?.[interviewStageOrderNumber];
		const isEmptyStagePlan = currentStagePlan && Object.values(currentStagePlan).every((value) => !value);

		if (!currentStagePlan || isEmptyStagePlan) {
			// Find the current stage from values.stages
			const currentStage = values.stages?.find((stage) => stage.interviewStageOrderNumber === interviewStageOrderNumber);

			if (!currentStage) return;

			// Calculate middle stage number if this is a middle stage
			let middleStageNumber = 0;
			if (currentStage.interviewStageType === "middle") {
				middleStageNumber =
					values.stages?.filter((s) => s.interviewStageType === "middle" && s.interviewStageOrderNumber < interviewStageOrderNumber)
						.length || 0;
			}

			// Get the appropriate preset for this stage
			const defaultPreset = getDefaultPresetForStage(currentStage.interviewStageType, middleStageNumber);
			const presetConfig = QUESTION_PRESETS[defaultPreset].config;

			setFieldValue(`stagePlan.${interviewStageOrderNumber}`, {
				...presetConfig,
				interviewerAttitude: "neutral",
				interviewStageOrderNumber
			});

			// Also set the current preset in state
			setCurrentPreset(defaultPreset);
		} else {
			// If we have an existing stage plan, determine the preset from the current questions
			const existingQuestions = currentStagePlan.interviewQuestionList || [];
			setCurrentPreset(determinePresetFromQuestions(existingQuestions));
		}
	}, [values.stagePlan, interviewStageOrderNumber, values.stages, setFieldValue]);

	const getFieldValue = (fieldName: string): number => {
		const fieldParts = fieldName.split(".");
		if (fieldParts.length < 3) return 0;

		const questionType = fieldParts[2];
		return (values.stagePlan?.[interviewStageOrderNumber]?.[questionType as keyof InterviewStagePlan] ?? 0) as number;
	};

	// Update total questions calculation to be more reactive
	const totalQuestions = React.useMemo(() => {
		const stageData = values.stagePlan?.[interviewStageOrderNumber];
		if (!stageData) return 2; // Opening and Closing questions

		return (
			2 + // Opening and Closing questions
			(stageData.technicalQuestions || 0) +
			(stageData.behavioralQuestions || 0) +
			(stageData.situationalQuestions || 0) +
			(stageData.culturalFitQuestions || 0) +
			(stageData.backgroundQuestions || 0) +
			(stageData.motivationQuestions || 0) +
			(stageData.recallQuestions || 0) +
			(stageData.generalQuestions || 0)
		);
	}, [values.stagePlan, interviewStageOrderNumber]);

	// Update estimatedMinutes calculation to be reactive as well
	const estimatedMinutes = React.useMemo(() => totalQuestions * 2, [totalQuestions]);

	// Render question slider for each question type
	// const renderQuestionSlider = (fieldName: string, label: string) => (
	// 	<div className="space-y-2">
	// 		<label htmlFor={fieldName} className="block text-sm font-medium text-gray-700">
	// 			{label}
	// 		</label>
	// 		<Slider
	// 			id={fieldName}
	// 			min={0}
	// 			max={5}
	// 			step={1}
	// 			value={[getFieldValue(fieldName)]}
	// 			onValueChange={(value) => {
	// 				setFieldValue(fieldName, value[0]);
	// 			}}
	// 			className="w-full"
	// 		/>
	// 		<div className="text-sm text-gray-500 text-right">
	// 			{getFieldValue(fieldName) === 0 ? "No" : getFieldValue(fieldName)} question{getFieldValue(fieldName) !== 1 && "s"}
	// 		</div>
	// 	</div>
	// );

	// Update question slots when question counts change
	useEffect(() => {
		const newSlots: JobQuestion[] = [{ id: "0", type: "Opening", text: "auto", order: 0 }];
		let currentOrder = 1;
		const questionTypeToKey = (type: CoreQuestionType): keyof InterviewStagePlan => {
			if (type === "Cultural Fit") {
				return "culturalFitQuestions";
			}
			return `${type.toLowerCase()}Questions` as keyof InterviewStagePlan;
		};

		// Get existing question details and order
		const existingQuestionDetails = values.stagePlan?.[interviewStageOrderNumber]?.questionDetails || {};

		// Get all non-opening and non-closing questions in their current order
		const existingOrderedQuestions = questionSlots
			.filter((slot) => slot.type !== "Opening" && slot.type !== "Closing")
			.sort((a, b) => a.order - b.order);

		// Create a map to track counts of each question type
		const typeCounts = new Map<CoreQuestionType, number>();
		existingOrderedQuestions.forEach((slot) => {
			const type = slot.type as CoreQuestionType;
			typeCounts.set(type, (typeCounts.get(type) || 0) + 1);
		});

		// First, add all existing questions in their current order
		existingOrderedQuestions.forEach((slot) => {
			newSlots.push({
				...slot,
				text: existingQuestionDetails[slot.id]?.text || slot.text,
				order: currentOrder++
			});
		});

		// Then add any new questions needed for each type
		DEFAULT_QUESTION_ORDER.forEach((type) => {
			const targetCount = (values.stagePlan?.[interviewStageOrderNumber]?.[questionTypeToKey(type)] as number) || 0;
			const currentCount = typeCounts.get(type) || 0;

			// Only add new questions if we need more of this type
			for (let i = currentCount; i < targetCount; i++) {
				const safeType = type.toLowerCase().replace(/\s+/g, "-");
				newSlots.push({
					id: `${safeType}-${Date.now()}-${i}-${interviewStageOrderNumber}`,
					type,
					text: "auto",
					order: currentOrder++
				});
			}
		});

		// Add closing question
		newSlots.push({
			id: "999",
			type: "Closing",
			text: "auto",
			order: 999
		});

		setQuestionSlots(newSlots);

		// Create the question list for this stage
		const questions: JobQuestion[] = newSlots.map((slot) => ({
			id: slot.id,
			order: slot.order,
			type: slot.type,
			text: existingQuestionDetails[slot.id]?.text || slot.text,
			context: existingQuestionDetails[slot.id]?.context || ""
		}));

		// Update the stage's question list in the form
		setFieldValue(`stagePlan.${interviewStageOrderNumber}.interviewQuestionList`, questions);
	}, [
		interviewStageOrderNumber,
		values.stagePlan?.[interviewStageOrderNumber]?.technicalQuestions,
		values.stagePlan?.[interviewStageOrderNumber]?.behavioralQuestions,
		values.stagePlan?.[interviewStageOrderNumber]?.situationalQuestions,
		values.stagePlan?.[interviewStageOrderNumber]?.culturalFitQuestions,
		values.stagePlan?.[interviewStageOrderNumber]?.generalQuestions,
		values.stagePlan?.[interviewStageOrderNumber]?.questionDetails
	]);

	// Update moveQuestion to also update the interview payload
	const moveQuestion = (id: string, direction: "up" | "down") => {
		const slots = [...questionSlots];
		const index = slots.findIndex((slot) => slot.id === id);

		if (slots[index].type === "Opening" || slots[index].type === "Closing") return;
		if (direction === "up" && index === 1) return;
		if (direction === "down" && index === slots.length - 2) return;

		const newIndex = direction === "up" ? index - 1 : index + 1;
		if (slots[newIndex].type === "Opening" || slots[newIndex].type === "Closing") return;

		// Get opening question
		const opening = slots.find((slot) => slot.type === "Opening")!;
		// Get closing question
		const closing = slots.find((slot) => slot.type === "Closing")!;
		// Get middle questions (excluding opening and closing)
		const middleQuestions = slots.filter((slot) => slot.type !== "Opening" && slot.type !== "Closing");

		// Find the questions to swap in the middle questions array
		const middleIndex = middleQuestions.findIndex((slot) => slot.id === id);
		const newMiddleIndex = direction === "up" ? middleIndex - 1 : middleIndex + 1;

		// Swap the questions in the middle array
		[middleQuestions[middleIndex], middleQuestions[newMiddleIndex]] = [middleQuestions[newMiddleIndex], middleQuestions[middleIndex]];

		// Reassign orders to middle questions
		middleQuestions.forEach((slot, i) => {
			slot.order = i + 1; // +1 because opening is 0
		});

		// Reconstruct the full array with opening, reordered middle questions, and closing
		const newSlots = [opening, ...middleQuestions, closing];

		setQuestionSlots(newSlots);

		// Update the interview payload question order
		const questions = middleQuestions.map((slot, idx) => ({
			id: slot.id,
			order: idx + 1, // +1 because opening is 0
			type: slot.type,
			text: values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.text || "auto"
		}));

		setFieldValue(`stagePlan.${interviewStageOrderNumber}.interviewQuestionList`, [
			{ id: opening.id, order: 0, type: opening.type, text: "auto" },
			...questions,
			{ id: closing.id, order: questions.length + 1, type: closing.type, text: "auto" }
		]);
	};
	// const [isDistributionOpen, setIsDistributionOpen] = useState(false);
	const [isOrderOpen, setIsOrderOpen] = useState(false);
	// Badge component for reuse
	const PresetBadge = ({ text }: { text: string }) => (
		<span className="px-2 py-1 text-xs font-medium rounded-lg bg-[#e6f7f7] text-[#00a9af]">{text}</span>
	);

	const CustomBadge = ({ isCustom }: { isCustom: boolean }) => (
		<span className={`px-2 py-1 text-xs font-medium rounded-lg ${isCustom ? "bg-blue-100 text-blue-800" : "bg-[#e6f7f7] text-[#00a9af]"}`}>
			{isCustom ? "Custom" : "Default"}
		</span>
	);

	// Update the removeQuestion function
	const removeQuestion = (id: string) => {
		const slot = questionSlots.find((s) => s.id === id);
		if (!slot || slot.type === "Opening" || slot.type === "Closing") return;

		// Remove the question from questionSlots
		const updatedSlots = questionSlots.filter((s) => s.id !== id);

		// Reorder the remaining questions
		const reorderedSlots = updatedSlots.map((slot, index) => {
			if (slot.type === "Opening") return { ...slot, order: 0 };
			if (slot.type === "Closing") return { ...slot, order: 999 };
			return { ...slot, order: index };
		});

		// Count the remaining questions of each type
		const counts = reorderedSlots.reduce((acc, slot) => {
			if (slot.type !== "Opening" && slot.type !== "Closing") {
				if (slot.type === "Cultural Fit") {
					acc.culturalFitQuestions = (acc.culturalFitQuestions || 0) + 1;
				} else if (slot.type === "Background") {
					acc.backgroundQuestions = (acc.backgroundQuestions || 0) + 1;
				} else if (slot.type === "Motivation") {
					acc.motivationQuestions = (acc.motivationQuestions || 0) + 1;
				} else {
					const key = `${slot.type.toLowerCase()}Questions`;
					acc[key] = (acc[key] || 0) + 1;
				}
			}
			return acc;
		}, {} as Record<string, number>);

		// Create the updated question list
		const updatedQuestionList = reorderedSlots.map((slot) => ({
			id: slot.id,
			order: slot.order,
			type: slot.type,
			text: values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.text || "auto",
			context: values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.context || ""
		}));

		// Update the state with new counts, slots, and question list
		setFieldValue(`stagePlan.${interviewStageOrderNumber}`, {
			...values.stagePlan?.[interviewStageOrderNumber],
			technicalQuestions: counts.technicalQuestions || 0,
			behavioralQuestions: counts.behavioralQuestions || 0,
			situationalQuestions: counts.situationalQuestions || 0,
			culturalFitQuestions: counts.culturalFitQuestions || 0,
			backgroundQuestions: counts.backgroundQuestions || 0,
			motivationQuestions: counts.motivationQuestions || 0,
			recallQuestions: counts.recallQuestions || 0,
			generalQuestions: counts.generalQuestions || 0,
			interviewQuestionList: updatedQuestionList
		});

		setQuestionSlots(reorderedSlots);

		// Update the current preset based on the new question configuration
		setCurrentPreset(determinePresetFromQuestions(reorderedSlots));
	};

	// Add this computed value for attitude customization
	const isAttitudeCustomized = React.useMemo(() => {
		return values.stagePlan?.[interviewStageOrderNumber]?.interviewerAttitude !== "neutral";
	}, [values.stagePlan, interviewStageOrderNumber]);

	const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
	const [newQuestion, setNewQuestion] = useState<CustomQuestion>({
		type: "Behavioral",
		text: "auto",
		context: ""
	});

	const handleAddQuestion = () => {
		if (totalQuestions >= MAX_TOTAL_QUESTIONS) {
			return; // Don't add more questions if at the limit
		}

		const questionId = `${newQuestion.type.toLowerCase()}-${Date.now()}-${interviewStageOrderNumber}`;

		// Get the closing question
		const closingQuestion = questionSlots.find((slot) => slot.type === "Closing");
		if (!closingQuestion) return; // Early return if no closing question found

		// Get all non-closing questions
		const nonClosingQuestions = questionSlots.filter((slot) => slot.type !== "Closing");
		// Get the last order number of non-closing questions
		const lastOrder = Math.max(...nonClosingQuestions.map((slot) => slot.order));

		// Create new question slots array with the new question inserted before closing
		const updatedSlots = [
			...nonClosingQuestions,
			{
				id: questionId,
				type: newQuestion.type,
				order: lastOrder + 1,
				text: newQuestion.text
			},
			closingQuestion
		];

		setQuestionSlots(updatedSlots);

		// Update the corresponding question count
		const fieldMapping: Record<CoreQuestionType, string> = {
			Technical: "technicalQuestions",
			Behavioral: "behavioralQuestions",
			Situational: "situationalQuestions",
			"Cultural Fit": "culturalFitQuestions",
			Background: "backgroundQuestions",
			Motivation: "motivationQuestions",
			General: "generalQuestions",
			Recall: "recallQuestions"
		};

		const fieldToUpdate = fieldMapping[newQuestion.type as CoreQuestionType];
		if (fieldToUpdate) {
			const currentStagePlan = values.stagePlan?.[interviewStageOrderNumber] || {};
			const currentCount = (currentStagePlan[fieldToUpdate as keyof InterviewStagePlan] as number) || 0;

			setFieldValue(`stagePlan.${interviewStageOrderNumber}`, {
				...currentStagePlan,
				[fieldToUpdate]: currentCount + 1,
				questionDetails: {
					...currentStagePlan.questionDetails,
					[questionId]: {
						text: newQuestion.text,
						context: newQuestion.context
					}
				}
			});
		}

		setIsAddQuestionOpen(false);
		setNewQuestion({ type: "Behavioral", text: "auto", context: "" });
	};

	const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false);
	const [editingQuestion, setEditingQuestion] = useState<CustomQuestion | null>(null);
	const [editingQuestionId, setEditingQuestionId] = useState<string | null>(null);

	const handleEditQuestion = () => {
		if (!editingQuestion || !editingQuestionId) return;

		// Find the question slot being edited
		const updatedSlots = questionSlots.map((slot) => {
			if (slot.id === editingQuestionId) {
				// If the type has changed, we need to update the question counts
				if (slot.type !== editingQuestion.type) {
					// Decrement the old type count
					const oldFieldMapping: Record<CoreQuestionType, string> = {
						Technical: technicalField,
						Behavioral: behavioralField,
						Situational: situationalField,
						"Cultural Fit": culturalFitField,
						Background: backgroundField,
						Motivation: motivationField,
						General: generalField,
						Recall: recallField
					};

					const oldFieldToUpdate = oldFieldMapping[slot.type as CoreQuestionType];
					if (oldFieldToUpdate) {
						const currentValue = getFieldValue(oldFieldToUpdate);
						setFieldValue(oldFieldToUpdate, Math.max(0, currentValue - 1));
					}

					// Increment the new type count
					const newFieldMapping: Record<CoreQuestionType, string> = {
						Technical: technicalField,
						Behavioral: behavioralField,
						Situational: situationalField,
						"Cultural Fit": culturalFitField,
						Background: backgroundField,
						Motivation: motivationField,
						General: generalField,
						Recall: recallField
					};

					const newFieldToUpdate = newFieldMapping[editingQuestion.type as CoreQuestionType];
					if (newFieldToUpdate) {
						const currentValue = getFieldValue(newFieldToUpdate);
						setFieldValue(newFieldToUpdate, currentValue + 1);
					}
				}

				// Update the slot with new type
				return {
					...slot,
					type: editingQuestion.type
				};
			}
			return slot;
		});

		// First, get the current question details
		const currentQuestionDetails = values.stagePlan?.[interviewStageOrderNumber]?.questionDetails || {};

		// Create a new questionDetails object with all existing questions
		const updatedQuestionDetails = {
			...currentQuestionDetails,
			[editingQuestionId]: {
				text: editingQuestion.text,
				context: editingQuestion.context
			}
		};

		// Get all the current values for the stage
		const currentStageValues = values.stagePlan?.[interviewStageOrderNumber] || {};

		// Update both the slots and the question details atomically
		setFieldValue(`stagePlan.${interviewStageOrderNumber}`, {
			...currentStageValues,
			questionDetails: updatedQuestionDetails,
			technicalQuestions: getFieldValue(technicalField),
			behavioralQuestions: getFieldValue(behavioralField),
			situationalQuestions: getFieldValue(situationalField),
			culturalFitQuestions: getFieldValue(culturalFitField),
			generalQuestions: getFieldValue(generalField)
		});

		// Update the question slots
		setQuestionSlots(updatedSlots);

		// Reset the editing state
		setIsEditQuestionOpen(false);
		setEditingQuestion(null);
		setEditingQuestionId(null);
	};

	const [currentPreset, setCurrentPreset] = useState<PresetKey>("Standard");
	const currentStageType = getCurrentStageType(values.stages, interviewStageOrderNumber);
	const hasScreening = hasScreeningStage(values.stages);
	const availablePresets = getAvailablePresets(currentStageType);
	const availableQuestionTypes = getAvailableQuestionTypes(currentStageType, hasScreening);

	return (
		<div className="space-y-4">
			<div className="flex flex-col space-y-2">
				<p className="text-left text-sm text-gray-500">Total Questions: {totalQuestions}</p>
				<p className="text-left text-sm text-gray-500">Est. Duration: {estimatedMinutes} min.</p>
			</div>
			{/* Question Distribution Section */}
			{/* <div className="border rounded-lg">
				<button
					type="button"
					className="w-full px-4 py-3 flex justify-between items-center bg-gray-50 rounded-t-lg hover:bg-gray-100"
					onClick={() => setIsDistributionOpen(!isDistributionOpen)}
				>
					<div className="flex items-center space-x-3">
						<div className="space-y-1">
							<div className="flex items-center space-x-3">
								<h4 className="text-sm font-medium text-gray-700 text-left">Question Distribution</h4>
								<StatusBadge isCustom={isDistributionCustomized} />
							</div>
						</div>
					</div>
					{isDistributionOpen ? <ChevronUpIcon className="w-5 h-5 text-gray-500" /> : <ChevronDownIcon className="w-5 h-5 text-gray-500" />}
				</button>

				{isDistributionOpen && (
					<div className="p-4 space-y-6">
						{renderQuestionSlider(behavioralField, "Behavioral Questions")}
						{renderQuestionSlider(technicalField, "Technical Questions")}
						{renderQuestionSlider(situationalField, "Situational Questions")}
						{renderQuestionSlider(culturalFitField, "Cultural Fit Questions")}
						{(values.stagePlan?.[interviewStageOrderNumber]?.generalQuestions > 0 || questionSlots.some((slot) => slot.type === "General")) &&
							renderQuestionSlider(generalField, "General Questions")}
						<div className="mt-4">
							<Button type="button" variant="outline" className="w-full" onClick={() => setIsAddQuestionOpen(true)}>
								+ Add New Question
							</Button>
						</div>
					</div>
				)}
			</div> */}

			{/* Question Order Section */}
			<div className="border rounded-lg">
				<button
					type="button"
					className="w-full px-4 py-3 flex justify-between items-center bg-gray-50 rounded-t-lg hover:bg-gray-100"
					onClick={() => setIsOrderOpen(!isOrderOpen)}
				>
					<div className="flex items-center space-x-3">
						<h4 className="text-sm font-medium text-gray-700">Question List</h4>
						<PresetBadge text={QUESTION_PRESETS[currentPreset].name} />
					</div>
					{isOrderOpen ? <ChevronUpIcon className="w-5 h-5 text-gray-500" /> : <ChevronDownIcon className="w-5 h-5 text-gray-500" />}
				</button>

				{isOrderOpen && (
					<div className="p-4">
						<div className="mb-4">
							<label className="block text-sm mb-2">Select a preset question order</label>
							<div className="flex items-center gap-3">
								<div className="w-60">
									<Select
										value={currentPreset}
										onValueChange={(preset: PresetKey) => {
											const selectedPreset = QUESTION_PRESETS[preset];
											const config = selectedPreset.config;

											// Create new question list based on preset order
											const newQuestions: JobQuestion[] = [
												{ id: "0", type: "Opening", text: "auto", order: 0 },
												...config.questionOrder.map((type, index) => ({
													id: `${type.toLowerCase()}-${Date.now()}-${index}`,
													type,
													text: "auto",
													order: index + 1
												})),
												{ id: "999", type: "Closing", text: "auto", order: 999 }
											];

											// Update question slots
											setQuestionSlots(newQuestions);

											// Update all the question counts and question list
											setFieldValue(`stagePlan.${interviewStageOrderNumber}`, {
												...values.stagePlan?.[interviewStageOrderNumber],
												technicalQuestions: config.technicalQuestions,
												behavioralQuestions: config.behavioralQuestions,
												situationalQuestions: config.situationalQuestions,
												culturalFitQuestions: config.culturalFitQuestions,
												backgroundQuestions: config.backgroundQuestions,
												motivationQuestions: config.motivationQuestions,
												recallQuestions: config.recallQuestions,
												generalQuestions: config.generalQuestions
											});

											setCurrentPreset(preset);
										}}
									>
										<SelectTrigger className="w-full text-left">
											<SelectValue>
												{currentPreset ? QUESTION_PRESETS[currentPreset].name : "Select a question preset"}
											</SelectValue>
										</SelectTrigger>
										<SelectContent>
											{Object.entries(QUESTION_PRESETS)
												.filter(([key]) => availablePresets.includes(key as PresetKey))
												.map(([key, preset]) => (
													<SelectItem key={key} value={key}>
														<div>
															<div className="font-medium">{preset.name}</div>
															<div className="text-sm text-gray-500">{preset.description}</div>
														</div>
													</SelectItem>
												))}
										</SelectContent>
									</Select>
								</div>
								<span className="text-sm">or customize the sequence and type of questions.</span>
							</div>
						</div>
						<div className="space-y-2">
							{questionSlots
								.sort((a, b) => a.order - b.order)
								.map((slot, index) => (
									<div key={slot.id} className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg">
										<div className="flex-1">
											<span className="text-sm text-gray-600">
												{values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.text === "auto" ||
												!values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.text
													? "[Auto-generated question]"
													: values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id]?.text}
											</span>
										</div>
										<div className="flex items-center space-x-4">
											<span className="px-3 py-1 text-sm rounded-full bg-gray-100 text-primary-text">{slot.type}</span>
											<div className="flex items-center space-x-2">
												<button
													type="button"
													onClick={() => {
														const existingDetails =
															values.stagePlan?.[interviewStageOrderNumber]?.questionDetails?.[slot.id];
														setEditingQuestionId(slot.id);
														setEditingQuestion({
															type: slot.type as QuestionType,
															text: existingDetails?.text || "auto",
															context: existingDetails?.context || ""
														});
														setIsEditQuestionOpen(true);
													}}
													disabled={slot.type === "Opening" || slot.type === "Closing"}
													className={`p-1 ${
														slot.type === "Opening" || slot.type === "Closing"
															? "text-gray-200 cursor-not-allowed"
															: "text-gray-400 hover:text-[#00a9af] transition-colors"
													}`}
												>
													<PencilIcon className="w-5 h-5" />
												</button>
												<button
													type="button"
													onClick={() => moveQuestion(slot.id, "up")}
													disabled={index === 1 || slot.type === "Opening" || slot.type === "Closing"}
													className={`p-1 ${
														index === 1 || slot.type === "Opening" || slot.type === "Closing"
															? "text-gray-200 cursor-not-allowed"
															: "text-gray-400 hover:text-[#00a9af] transition-colors"
													}`}
												>
													<ArrowUpIcon className="w-5 h-5" />
												</button>
												<button
													type="button"
													onClick={() => moveQuestion(slot.id, "down")}
													disabled={
														index === questionSlots.length - 2 || slot.type === "Opening" || slot.type === "Closing"
													}
													className={`p-1 ${
														index === questionSlots.length - 2 || slot.type === "Opening" || slot.type === "Closing"
															? "text-gray-200 cursor-not-allowed"
															: "text-gray-400 hover:text-[#00a9af] transition-colors"
													}`}
												>
													<ArrowDownIcon className="w-5 h-5" />
												</button>
												<button
													type="button"
													onClick={() => removeQuestion(slot.id)}
													disabled={slot.type === "Opening" || slot.type === "Closing"}
													className={`p-1 ${
														slot.type === "Opening" || slot.type === "Closing"
															? "text-gray-200 cursor-not-allowed"
															: "text-gray-400 hover:text-red-600 transition-colors"
													}`}
												>
													<TrashIcon className="w-5 h-5" />
												</button>
											</div>
										</div>
									</div>
								))}
						</div>
						<div className="mt-4">
							{totalQuestions < MAX_TOTAL_QUESTIONS ? (
								<Button
									type="button"
									variant="outline"
									className="w-full text-primary-900 border-primary-900 bg-white"
									onClick={() => setIsAddQuestionOpen(true)}
								>
									+ Add New Question
								</Button>
							) : (
								<p className="text-sm text-gray-500 text-center">Maximum question limit reached (15 questions)</p>
							)}
						</div>
					</div>
				)}
			</div>

			{/* Interviewer Attitude Section */}
			<div className="bg-gray-50 border rounded-lg p-4">
				<div className="space-y-3">
					<div className="flex items-center space-x-3">
						<h4 className="text-sm font-medium text-gray-700">Interviewer Attitude</h4>
						<CustomBadge isCustom={isAttitudeCustomized} />
					</div>
					<div className="relative">
						<Select
							value={values.stagePlan?.[interviewStageOrderNumber]?.interviewerAttitude || "neutral"}
							onValueChange={(value) => {
								setFieldValue(`stagePlan.${interviewStageOrderNumber}.interviewerAttitude`, value);
							}}
						>
							<SelectTrigger className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
								<SelectValue placeholder="Select interviewer attitude" />
							</SelectTrigger>
							<SelectContent className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
								{INTERVIEWER_ATTITUDES.map((attitude) => (
									<SelectItem key={attitude.value} value={attitude.value} className="py-2 px-4 hover:bg-gray-100">
										{attitude.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
			</div>
			<Dialog open={isAddQuestionOpen} onOpenChange={setIsAddQuestionOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Add New Question</DialogTitle>
						<DialogDescription>Configure a new question for this interview stage.</DialogDescription>
					</DialogHeader>

					<div className="space-y-4 py-4">
						<div className="space-y-2">
							<label className="text-sm font-medium flex items-center">
								Question Type
								<CustomTooltip content="Select the category of the question to shape the interview focus." />
							</label>
							<Select
								value={newQuestion.type}
								onValueChange={(value) =>
									setNewQuestion((prev: CustomQuestion) => ({
										...prev,
										type: value as QuestionType
									}))
								}
							>
								<SelectTrigger>
									<SelectValue placeholder="Select question type" />
								</SelectTrigger>
								<SelectContent>
									{availableQuestionTypes.map((type) => (
										<SelectItem key={type} value={type}>
											{type}
										</SelectItem>
									))}
									{isAdminPath && <SelectItem value="General">General</SelectItem>}
								</SelectContent>
							</Select>
						</div>

						{isAdminPath && (
							<div className="space-y-2">
								<label className="text-sm font-medium flex items-center">
									Question Text
									<CustomTooltip content="If you pick 'Custom', you can write your own question. If you choose 'Auto-generated', the system provides a context-based question aligned with the selected category." />
								</label>
								{newQuestion.type === "Recall" ? (
									<div className="text-sm text-gray-600">Auto-generated question</div>
								) : (
									<Select
										value={newQuestion.text === "auto" ? "auto" : "custom"}
										onValueChange={(value) =>
											setNewQuestion((prev: CustomQuestion) => ({
												...prev,
												text: value === "auto" ? "auto" : ""
											}))
										}
									>
										<SelectTrigger>
											<SelectValue placeholder="Select text type" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="auto">Auto-generated</SelectItem>
											<SelectItem value="custom">Custom</SelectItem>
										</SelectContent>
									</Select>
								)}

								{newQuestion.text !== "auto" && (
									<Textarea
										value={newQuestion.text === "auto" ? "" : newQuestion.text}
										onChange={(e) =>
											setNewQuestion((prev: CustomQuestion) => ({
												...prev,
												text: e.target.value
											}))
										}
										placeholder="Enter your custom question..."
										className="mt-2"
									/>
								)}
							</div>
						)}

						{newQuestion.text !== "auto" && isAdminPath && (
							<div className="space-y-2">
								<label className="text-sm font-medium flex items-center">
									Model Response (Optional)
									<CustomTooltip content="If you provide a model (ideal) response, it will influence the AI's evaluation of the student's answer." />
								</label>
								<Textarea
									value={newQuestion.context}
									onChange={(e) =>
										setNewQuestion((prev: CustomQuestion) => ({
											...prev,
											context: e.target.value
										}))
									}
									placeholder="Enter a model response to the question..."
								/>
							</div>
						)}
					</div>

					<DialogFooter>
						<div className="flex justify-between w-full">
							<Button type="button" variant="outline" onClick={() => setIsAddQuestionOpen(false)}>
								Cancel
							</Button>
							<Button type="button" onClick={handleAddQuestion} disabled={!isCustomTextValid(newQuestion)}>
								Add Question
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<Dialog open={isEditQuestionOpen} onOpenChange={setIsEditQuestionOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Edit Question</DialogTitle>
						<DialogDescription>Modify this interview question.</DialogDescription>
					</DialogHeader>

					<div className="space-y-4 py-4">
						<div className="space-y-2">
							<label className="text-sm font-medium flex items-center">
								Question Type
								<CustomTooltip content="Select the category of the question to shape the interview focus." />
							</label>
							<Select
								value={editingQuestion?.type || ""}
								onValueChange={(value) => setEditingQuestion((prev) => (prev ? { ...prev, type: value as QuestionType } : null))}
							>
								<SelectTrigger>
									<SelectValue placeholder="Select question type" />
								</SelectTrigger>
								<SelectContent>
									{availableQuestionTypes.map((type) => (
										<SelectItem key={type} value={type}>
											{type}
										</SelectItem>
									))}
									{isAdminPath && <SelectItem value="General">General</SelectItem>}
								</SelectContent>
							</Select>
						</div>

						{isAdminPath && (
							<>
								<div className="space-y-2">
									<label className="text-sm font-medium flex items-center">
										Question Text
										<CustomTooltip content="If you pick 'Custom', you can write your own question. If you choose 'Auto-generated', the system provides a context-based question aligned with the selected category." />
									</label>
									{editingQuestion?.type === "Recall" ? (
										<div className="text-sm text-gray-600">Auto-generated question</div>
									) : (
										<Select
											value={editingQuestion?.text === "auto" ? "auto" : "custom"}
											onValueChange={(value) =>
												setEditingQuestion((prev) => (prev ? { ...prev, text: value === "auto" ? "auto" : "" } : null))
											}
										>
											<SelectTrigger>
												<SelectValue placeholder="Select text type" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="auto">Auto-generated</SelectItem>
												<SelectItem value="custom">Custom</SelectItem>
											</SelectContent>
										</Select>
									)}

									{editingQuestion?.text !== "auto" && (
										<Textarea
											value={editingQuestion?.text === "auto" ? "" : editingQuestion?.text}
											onChange={(e) => setEditingQuestion((prev) => (prev ? { ...prev, text: e.target.value } : null))}
											placeholder="Enter your custom question..."
											className="mt-2"
										/>
									)}
								</div>

								{editingQuestion?.text !== "auto" && isAdminPath && (
									<div className="space-y-2">
										<label className="text-sm font-medium flex items-center">
											Model Response (Optional)
											<CustomTooltip content="If you provide a model (ideal) response, it will influence the AI's evaluation of the student's answer." />
										</label>
										<Textarea
											value={editingQuestion?.context || ""}
											onChange={(e) => setEditingQuestion((prev) => (prev ? { ...prev, context: e.target.value } : null))}
											placeholder="Enter a model response to the question..."
										/>
									</div>
								)}
							</>
						)}
					</div>

					<DialogFooter>
						<div className="flex justify-between w-full">
							<Button type="button" variant="outline" onClick={() => setIsEditQuestionOpen(false)}>
								Cancel
							</Button>
							<Button type="button" onClick={handleEditQuestion} disabled={!isCustomTextValid(editingQuestion)}>
								Save Changes
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default InterviewPlan;
