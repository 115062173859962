import React from "react";

const LoadingBubble: React.FC = () => {
	return (
		<div className="p-2 flex justify-start">
			<div className="max-w-[80%] p-4 bg-gray-100 rounded-t-lg rounded-r-lg rounded-bl-none mr-auto">
				<div className="flex space-x-1">
					<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0ms" }}></div>
					<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "150ms" }}></div>
					<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "300ms" }}></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingBubble;
