import React from "react";
import { ResumeUpload } from "./Resumeupload";

interface ChatBubbleProps {
	message: string;
	isUser: boolean;
	isFirstMessage?: boolean;
	onFileSelect?: (file: File) => void;
	profileId: string;
	setProfile: (profile: any) => void;
	profile: any;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ message, isUser, isFirstMessage, onFileSelect, profileId, setProfile, profile }) => {
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file && onFileSelect) {
			onFileSelect(file);
		}
	};

	return (
		<div className={`p-2 flex ${isUser ? "justify-end" : "justify-start"}`}>
			<div
				className={`max-w-[80%] p-3 text-sm break-words ${
					isUser
						? "bg-[#00a9af] text-white rounded-t-lg rounded-l-lg rounded-br-none ml-auto"
						: "bg-gray-100 rounded-t-lg rounded-r-lg rounded-bl-none mr-auto"
				}`}
			>
				{message}
				{isFirstMessage && !isUser && (
					<ResumeUpload profileId={profileId} setProfile={setProfile} profile={profile} />
					// <div className="mt-4 border-t pt-4">
					// 	<label className="flex flex-col items-center p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
					// 		<span className="text-sm text-gray-600 mb-2">Upload your resume</span>
					// 		{/* <input type="file" className="hidden" accept=".pdf,.doc,.docx" onChange={handleFileChange} /> */}

					// 		<span className="text-xs text-gray-500">PDF, DOC, DOCX (Max 5MB)</span>
					// 	</label>
					// </div>
				)}
			</div>
		</div>
	);
};

export default ChatBubble;
