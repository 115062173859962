import React, { useState } from "react";
import { BaseTemplateProps, commonStyles } from "./BaseTemplate";
import {
	EducationSection,
	ExperienceSection,
	ProjectsSection,
	SkillsSection,
	ExtraCurricularSection
} from "../ResumeTemplate/sections/resumeTemplateIndex";
import { useEditableField } from "./useEditableField";
import { ResumeService } from "../../../services/ResumeService";
import { PlusIcon } from "@heroicons/react/24/outline";

export const minimalStyles = (
	<style>{`
	@page:first {
		margin: 0in 1in 1in 1in;
		mso-header-margin: 0in;
		mso-footer-margin: 1in;
		mso-page-orientation: portrait;
		mso-title-page: yes;
		margin-top: 0;
		margin-bottom: 720pt;
	}
	@page {
		margin-top: 720pt;
		margin-bottom: 720pt;
		margin-top: 0.5in !important;
		margin-right: 1in !important;
		margin-bottom: 0.5in !important;
		margin-left: 1in !important;
		mso-header-margin: 0.5in !important;
		mso-footer-margin: 0.5in !important;
		mso-page-orientation: portrait;
        font-family: "Calibri, sans-serif";
	}
	body {
		margin: 0;
		padding: 0;
		-webkit-font-smoothing: antialiased;
        
	}
    table {
        mso-table-layout: fixed;
    }
	.resume-section {
		margin: 0px 0px 15px 0px;
		padding: 10px;
		border-radius: 4px;
		transition: all 0.3s ease;
		box-sizing: border-box;
		border: 1px solid transparent;
		cursor: pointer;
	}
	.resume-section:hover {
		background-color: rgba(0, 169, 175, 0.05);
		border: 1px solid #00a9af;
	}
	.minimal-section-title {
		text-transform: uppercase;
		letter-spacing: 1.5px;
		border-bottom-width: 1pt;
		border-bottom-style: solid;
		border-bottom-color: #666666;
		mso-border-bottom-alt: solid #666666 1pt;
		padding-bottom: 3pt;
	}
	.minimal-header {
		text-transform: uppercase;
		letter-spacing: 4px;
		font-weight: 300;
	}
	.resume-section-individual {
		margin: 0px 0px 10px 0px;
		padding: 2px;
		border-radius: 4px;
		transition: all 0.3s ease;
		box-sizing: border-box;
		cursor: pointer;
	}
	.resume-section-individual:hover {
		background-color: rgba(0, 169, 175, 0.1);
	}
	.resume-section-individual:hover .remove-button {
		visibility: visible !important;
		opacity: 1 !important;
	}
	.resume-section-individual:hover .add-button {
		visibility: visible !important;
		opacity: 1 !important;
	}
	.remove-button {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s ease;
	}
	.remove-button:hover {
		color: #ef4444;
	}
	.add-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s ease;
		padding: 2px 4px;
		font-size: 11pt;
		color: #666;
		background: transparent;
		border: none;
		cursor: pointer;
		margin-top: 4px;
	}
	.add-button:hover {
		color: #00a9af;
	}
	.skills-container {
		display: flex !important;
		flex-direction: column !important;
		width: 100% !important;
		gap: 15px !important;
	}
	.skills-group {
		margin-bottom: 15px !important;
		font-family: Calibri, sans-serif !important;
	}
	.skills-group h3 {
		font-size: 11pt !important;
		font-family: Calibri, sans-serif !important;
		color: #666 !important;
		font-weight: 600 !important;
		margin-bottom: 5px !important;
		text-transform: none !important;
	}
	.skills-group ul {
		margin: 0 !important;
		padding-left: 20px !important;
		list-style-type: disc !important;
		font-family: Calibri, sans-serif !important;
	}
	.skills-group li {
		margin: 0 !important;
		padding: 0 0 1px 0 !important;
		font-family: Calibri, sans-serif !important;
		font-size: 11pt !important;
		line-height: 1.1 !important;
	}
`}</style>
);

const MinimalTemplate: React.FC<BaseTemplateProps> = ({ resume, style, onUpdate }) => {
	const { renderEditableField } = useEditableField(resume.id, onUpdate);

	const handleAddItem = async (section: string) => {
		try {
			const updatedResume = await ResumeService.addResumeItem(resume.id, section);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to add item to ${section}:`, error);
		}
	};

	const handleAddTask = async (section: string, itemIndex: string) => {
		try {
			const updatedResume = await ResumeService.addTask(resume.id, section, itemIndex);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to add task to ${section}:`, error);
		}
	};

	const handleRemoveTask = async (section: string, itemIndex: number, taskIndex: number) => {
		try {
			const updatedResume = await ResumeService.removeTask(resume.id, section, itemIndex.toString(), taskIndex.toString());
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to remove task from ${section}:`, error);
		}
	};

	const handleRemoveItem = async (section: string, itemId: string) => {
		try {
			const updatedResume = await ResumeService.removeResumeItem(resume.id, section, itemId);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to remove item from ${section}:`, error);
		}
	};

	const ContactInfo = () => {
		const contactStyle = {
			fontSize: commonStyles.fontSize(11, style?.fontSize),
			fontFamily: "Calibri, sans-serif",
			color: "#666",
			lineHeight: "1.6",
			margin: "0 0 15px 0",
			marginLeft: 5
		};

		return (
			<div className="resume-section" style={{ marginBottom: "20px", marginRight: "10px", marginLeft: "20px" }}>
				<div style={{ marginBottom: "15px" }}>
					<div
						style={{
							fontSize: commonStyles.fontSize(12, style?.fontSize),
							fontFamily: "Calibri, sans-serif",
							color: "#666",
							fontWeight: "500",
							marginBottom: "5px",
							textTransform: "uppercase",
							letterSpacing: "1.5px",
							borderBottom: "1pt solid #666666",
							paddingBottom: "3pt"
						}}
					>
						Contact
					</div>
					<div style={contactStyle}>
						{" • "}
						{renderEditableField("contact.phone", resume.contact.phone)}
					</div>
					<div style={contactStyle}>
						{" • "}
						{renderEditableField("contact.email", resume.contact.email)}
					</div>
					<div style={contactStyle}>
						{" • "}
						{renderEditableField("contact.address", resume.contact.address)}
					</div>
					<div style={contactStyle}>
						{" • "}
						{renderEditableField("contact.linkedinURL", resume.contact.linkedinURL)}
					</div>
				</div>
			</div>
		);
	};

	const Section: React.FC<{ title: string; section: string; children: React.ReactNode; showAddButton?: boolean }> = ({
		title,
		section,
		children,
		showAddButton = true
	}) => {
		const [isHovered, setIsHovered] = useState(false);

		return (
			<div
				className="resume-section"
				style={{ marginBottom: "20px", marginRight: "20px", marginLeft: "20px", fontFamily: "Calibri, sans-serif", position: "relative" }}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<h2
					className="minimal-section-title"
					style={{
						fontSize: commonStyles.fontSize(12, style?.fontSize),
						fontFamily: "Calibri, sans-serif",
						color: "#666",
						fontWeight: "500",
						marginBottom: "5px",
						textTransform: "uppercase",
						letterSpacing: "1.5px",
						borderBottom: "1pt solid #666666",
						paddingBottom: "3pt"
					}}
				>
					{title}
				</h2>
				{isHovered && showAddButton && (
					<div
						style={{
							...commonStyles.sectionControls,
							display: "flex"
						}}
					>
						<button style={commonStyles.controlButton} onClick={() => handleAddItem(section)} title={`Add ${title} Item`}>
							<PlusIcon className="h-4 w-4" />
						</button>
					</div>
				)}
				{children}
			</div>
		);
	};

	const SkillsList = () => {
		const skillStyle = {
			fontSize: commonStyles.fontSize(11, style?.fontSize),
			fontFamily: "Calibri, sans-serif",
			color: "#666",
			lineHeight: "1.6",
			margin: "0 0 15px 0",
			marginLeft: 5
		};

		const renderSkillCategory = (title: string, skills: string[]) => {
			if (!skills || skills.length === 0) return null;
			return (
				<div style={{ marginBottom: "15px", position: "relative" }} className="resume-section-individual">
					<div
						style={{
							fontSize: commonStyles.fontSize(11, style?.fontSize),
							fontFamily: "Calibri, sans-serif",
							color: "#666",
							fontWeight: "600",
							marginBottom: "5px"
						}}
					>
						{title}
					</div>
					{skills.map((skill, index) => (
						<div key={index} style={skillStyle}>
							{" • "}
							{renderEditableField(`skills.${title.toLowerCase()}.${index}`, skill)}
						</div>
					))}
					<button
						onClick={() => handleAddItem(title.toLowerCase())}
						style={{
							visibility: "hidden",
							opacity: 0,
							display: "inline-flex",
							alignItems: "center",
							padding: "2px 4px",
							fontSize: "11pt",
							color: "#666",
							background: "transparent",
							border: "none",
							cursor: "pointer",
							marginTop: "4px",
							marginLeft: 5
						}}
						className="add-button"
						title={`Add ${title}`}
					>
						<PlusIcon className="h-4 w-4" />
					</button>
				</div>
			);
		};

		return (
			<>
				{renderSkillCategory("Technical", resume.skills.technical)}
				{renderSkillCategory("Core Competencies", resume.skills.coreCompetencies)}
				{renderSkillCategory("Frameworks", resume.skills.frameworks)}
				{renderSkillCategory("Tools", resume.skills.tools)}
			</>
		);
	};

	return (
		<div
			style={{
				margin: "40px auto",
				padding: 0,
				fontFamily: "Calibri, sans-serif",
				height: "72vh",
				display: "flex",
				flexDirection: "column",
				overflow: "scroll"
			}}
		>
			{minimalStyles}
			<table
				style={{
					width: "100%",
					borderCollapse: "collapse",
					tableLayout: "fixed",
					msWordBreak: "break-word",
					wordBreak: "break-word"
				}}
			>
				<colgroup>
					<col style={{ width: "25%", MozColumnWidth: "-25%" }} />
					<col style={{ width: "75%", MozColumnWidth: "-75%" }} />
				</colgroup>
				<tbody>
					<tr>
						{/* Left Column - 25% */}
						<td style={{ width: "25%", verticalAlign: "top", paddingRight: "20px" }}>
							<div style={{ wordBreak: "break-all" }}>
								<ContactInfo />
							</div>

							<Section title="Skills" section="skills" showAddButton={false}>
								<SkillsSection
									resume={resume}
									style={style}
									renderEditableField={renderEditableField}
									onAddTask={(section, index) => handleAddTask(section, index.toString())}
									onRemoveTask={handleRemoveTask}
									layout="minimal"
								/>
							</Section>
						</td>

						{/* Right Column - 75% */}
						<td style={{ width: "75%", verticalAlign: "top", paddingLeft: "20px", wordBreak: "break-word" }}>
							<h1
								className="minimal-header"
								style={{
									fontSize: commonStyles.fontSize(32, style?.fontSize),
									fontFamily: "Calibri, sans-serif",
									margin: "0 0 25px 0",
									color: "#333"
								}}
							>
								{renderEditableField("contact.firstName", resume.contact.firstName)}{" "}
								{renderEditableField("contact.lastName", resume.contact.lastName)}
							</h1>

							<Section title="Professional Overview" section="summary" showAddButton={false}>
								<p
									style={{
										fontSize: commonStyles.fontSize(11, style?.fontSize),
										fontFamily: "Calibri, sans-serif",
										color: "#666",
										lineHeight: "1.6",
										margin: "0"
									}}
								>
									{renderEditableField("summary", resume.summary, "", true)}
								</p>
							</Section>

							<Section title="Work Experience" section="experience">
								<div style={{ fontSize: commonStyles.fontSize(11, style?.fontSize), color: "#666", lineHeight: "1.6" }}>
									<ExperienceSection
										resume={resume}
										style={style}
										renderEditableField={renderEditableField}
										onRemoveItem={(index) => handleRemoveItem("experience", index.toString())}
										onAddTask={(section, index) => handleAddTask(section, index.toString())}
										onRemoveTask={handleRemoveTask}
									/>
								</div>
							</Section>

							<Section title="Education" section="education">
								<div style={{ fontSize: commonStyles.fontSize(11, style?.fontSize), color: "#666", lineHeight: "1.6" }}>
									<EducationSection
										resume={resume}
										style={style}
										renderEditableField={renderEditableField}
										onRemoveItem={(index) => handleRemoveItem("education", index.toString())}
									/>
								</div>
							</Section>

							{resume.projects && resume.projects.length > 0 && (
								<Section title="Projects" section="projects">
									<div style={{ fontSize: commonStyles.fontSize(11, style?.fontSize), color: "#666", lineHeight: "1.6" }}>
										<ProjectsSection
											resume={resume}
											style={style}
											renderEditableField={renderEditableField}
											onRemoveItem={(index) => handleRemoveItem("projects", index.toString())}
											onAddTask={(section, index) => handleAddTask(section, index.toString())}
											onRemoveTask={handleRemoveTask}
										/>
									</div>
								</Section>
							)}

							{resume.extraCurricular && resume.extraCurricular.length > 0 && (
								<Section title="Extracurricular Activities" section="extraCurricular">
									<div style={{ fontSize: commonStyles.fontSize(11, style?.fontSize), color: "#666", lineHeight: "1.6" }}>
										<ExtraCurricularSection
											resume={resume}
											style={style}
											renderEditableField={renderEditableField}
											onRemoveItem={(index) => handleRemoveItem("extraCurricular", index.toString())}
											onAddTask={(section, index) => handleAddTask(section, index.toString())}
											onRemoveTask={handleRemoveTask}
										/>
									</div>
								</Section>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default MinimalTemplate;
