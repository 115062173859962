import React, { useState } from "react";
import { BaseTemplateProps, commonStyles } from "./BaseTemplate";
import {
	EducationSection,
	ExperienceSection,
	ProjectsSection,
	SkillsSection,
	ExtraCurricularSection
} from "../ResumeTemplate/sections/resumeTemplateIndex";
import { useEditableField } from "./useEditableField";
import { ResumeService } from "../../../services/ResumeService";
import { PlusIcon } from "@heroicons/react/24/outline";

export const singleColumnStyles = (
	<style>{`
	@page:first {
		margin: 0in 1in 1in 1in;
		mso-header-margin: 0in;
		mso-footer-margin: 1in;
		mso-page-orientation: portrait;
		mso-title-page: yes;
		margin-top: 0;
		margin-bottom: 720pt;
	}
	@page {
		margin-top: 720pt;
		margin-bottom: 720pt;
		margin-top: 0.5in !important;
		margin-right: 1in !important;
		margin-bottom: 0.5in !important;
		margin-left: 1in !important;
		mso-header-margin: 0.5in !important;
		mso-footer-margin: 0.5in !important;
		mso-page-orientation: portrait;
	}
	body {
		margin: 0;
		padding: 0;
		-webkit-font-smoothing: antialiased;
	}
	.resume-section {
		margin: 0px 0px 10px 0px;
		padding: 10px;
		border-radius: 8px;
		transition: all 0.3s ease;
		box-sizing: border-box;
		border: 1px solid transparent;
		cursor: pointer;
	}
	.resume-section:hover {
		background-color: rgba(0, 169, 175, 0.05);
		border: 1px solid #00a9af;
	}
    .resume-section-individual {
        margin: 0px 0px 10px 0px;
        padding: 2px;
        border-radius: 4px;
        transition: all 0.3s ease;
        box-sizing: border-box;
        cursor: pointer;
	}
	.resume-section-individual:hover {
		background-color: rgba(0, 169, 175, 0.1);
	}
	.resume-section-individual:hover .remove-button {
		visibility: visible !important;
		opacity: 1 !important;
	}
	.remove-button {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s ease;
	}
	.remove-button:hover {
		color: #ef4444;
	}
	.resume-section-individual:hover .add-button {
		visibility: visible !important;
		opacity: 1 !important;
	}
	.add-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s ease;
		padding: 2px 4px;
		font-size: 11pt;
		color: #666;
		background: transparent;
		border: none;
		cursor: pointer;
		margin-top: 4px;
	}
	.add-button:hover {
		color: #00a9af;
	}
	.skills-container {
		display: flex !important;
		flex-direction: row !important;
		flex-wrap: nowrap !important;
		width: 100% !important;
		gap: 15px !important;
		justify-content: space-between !important;
		mso-table-lspace: 0pt !important;
		mso-table-rspace: 0pt !important;
	}
	.skills-group {
		flex: 1 1 25% !important;
		min-width: 100px !important;
		font-family: Calibri, sans-serif !important;
		mso-line-height-rule: exactly !important;
	}
	.skills-group h3 {
		font-size: 13pt !important;
		margin: 0 0 5px 0 !important;
		font-weight: bold !important;
		font-family: Calibri, sans-serif !important;
		white-space: nowrap !important;
	}
`}</style>
);

const SingleColumnTemplate: React.FC<BaseTemplateProps> = ({ resume, style, onUpdate }) => {
	const { renderEditableField } = useEditableField(resume.id, onUpdate);

	const handleAddItem = async (section: string) => {
		try {
			const updatedResume = await ResumeService.addResumeItem(resume.id, section);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to add item to ${section}:`, error);
		}
	};

	const handleAddTask = async (section: string, itemIndex: string) => {
		try {
			const updatedResume = await ResumeService.addTask(resume.id, section, itemIndex);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to add task to ${section}:`, error);
		}
	};

	const handleRemoveTask = async (section: string, itemIndex: number, taskIndex: number) => {
		try {
			const updatedResume = await ResumeService.removeTask(resume.id, section, itemIndex.toString(), taskIndex.toString());
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to remove task from ${section}:`, error);
		}
	};

	const handleRemoveItem = async (section: string, itemId: string) => {
		try {
			console.log("Removing item from", resume.id, section, itemId);
			const updatedResume = await ResumeService.removeResumeItem(resume.id, section, itemId);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error(`Failed to remove item from ${section}:`, error);
		}
	};

	const Header = () => (
		<div className="resume-section resume-header" style={{ marginLeft: 0, marginRight: 0 }}>
			<div style={{ margin: 0, padding: 0 }}>
				<table
					className="resume-header"
					style={{
						backgroundColor: commonStyles.accentColor(style),
						width: "100%",
						borderCollapse: "collapse",
						margin: 0,
						padding: 0,
						textAlign: "center"
					}}
				>
					<tbody>
						<tr>
							<td
								style={{
									padding: "20pt 0",
									color: "white",
									fontFamily: "Calibri, sans-serif",
									textAlign: "center",
									verticalAlign: "middle"
								}}
							>
								<h1 style={{ fontSize: commonStyles.fontSize(28, style?.fontSize), margin: "0 0 10pt 0", fontWeight: "normal" }}>
									{renderEditableField("contact.firstName", resume.contact.firstName)}{" "}
									{renderEditableField("contact.lastName", resume.contact.lastName)}
								</h1>
								<h2
									style={{
										fontSize: commonStyles.fontSize(11, style?.fontSize),
										margin: "0",
										lineHeight: "1.2",
										fontWeight: "normal"
									}}
								>
									{renderEditableField("contact.address", resume.contact.address)} |{" "}
									{renderEditableField("contact.phone", resume.contact.phone)} |{" "}
									{renderEditableField("contact.email", resume.contact.email)} |{" "}
									{renderEditableField("contact.linkedinURL", resume.contact.linkedinURL)}
								</h2>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);

	const Section: React.FC<{ title: string; section: string; children: React.ReactNode; showAddButton?: boolean }> = ({
		title,
		section,
		children,
		showAddButton = true
	}) => {
		const [isHovered, setIsHovered] = useState(false);

		return (
			<div className="resume-section relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<h2 style={commonStyles.sectionTitle(style)}>{title}</h2>
				{isHovered && showAddButton && (
					<div
						style={{
							...commonStyles.sectionControls,
							display: "flex"
						}}
					>
						<button style={commonStyles.controlButton} onClick={() => handleAddItem(section)} title={`Add ${title} Item`}>
							<PlusIcon className="h-4 w-4" />
						</button>
					</div>
				)}
				{children}
			</div>
		);
	};

	return (
		<div
			style={{
				margin: 0,
				padding: 0,
				fontFamily: "Calibri, sans-serif",
				height: "80vh",
				display: "flex",
				flexDirection: "column",
				overflow: "scroll"
			}}
		>
			{singleColumnStyles}
			<Header />
			<div style={{ marginLeft: 15, marginRight: 15, marginBottom: 20 }}>
				<Section title="Professional Summary" section="summary" showAddButton={false}>
					<p style={commonStyles.paragraph(style)}>{renderEditableField("summary", resume.summary, "", true)}</p>
				</Section>

				<Section title="Education" section="education">
					<EducationSection
						resume={resume}
						style={style}
						renderEditableField={renderEditableField}
						onRemoveItem={(index) => handleRemoveItem("education", index.toString())}
					/>
				</Section>

				<Section title="Professional Experience" section="experience">
					<ExperienceSection
						resume={resume}
						style={style}
						renderEditableField={renderEditableField}
						onRemoveItem={(index) => handleRemoveItem("experience", index.toString())}
						onAddTask={(section, index) => handleAddTask(section, index.toString())}
						onRemoveTask={handleRemoveTask}
					/>
				</Section>

				<Section title="Projects" section="projects">
					<ProjectsSection
						resume={resume}
						style={style}
						renderEditableField={renderEditableField}
						onRemoveItem={(index) => handleRemoveItem("projects", index.toString())}
						onAddTask={(section, index) => handleAddTask(section, index.toString())}
						onRemoveTask={handleRemoveTask}
					/>
				</Section>

				<Section title="Extracurricular Activities" section="extraCurricular">
					<ExtraCurricularSection
						resume={resume}
						style={style}
						renderEditableField={renderEditableField}
						onRemoveItem={(index) => handleRemoveItem("extraCurricular", index.toString())}
						onAddTask={(section, index) => handleAddTask(section, index.toString())}
						onRemoveTask={handleRemoveTask}
					/>
				</Section>

				<Section title="Skills" section="skills" showAddButton={false}>
					<SkillsSection
						resume={resume}
						style={style}
						renderEditableField={renderEditableField}
						onAddTask={(section, index) => handleAddTask(section, index.toString())}
						onRemoveTask={handleRemoveTask}
					/>
				</Section>
			</div>
		</div>
	);
};

export default SingleColumnTemplate;
