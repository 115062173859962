import axios from "axios";
import { ResumeView } from "../types/ResumeView";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";

export class ChatMessage {
	role!: "User" | "Assistant";
	content!: string;
	timestamp!: Date;
}

export interface Keyword {
	keyword: string;
	isMissing: boolean;
}

export class FeedbackData {
	matchScore!: number;
	keywords!: Keyword[];
	improvements!: string[];
}

export class ResumeService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/resume`;

	public static async updateChatHistory(resumeId: string, newChat: ChatMessage): Promise<ResumeView[]> {
		try {
			const response = await axios.post(`${this.baseUrl}/update-chat-history`, {
				resumeId,
				newChat: newChat.content,
				role: newChat.role
			});
			return plainToInstance(ResumeView, response.data as ResumeView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update chat history ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async fetchChatHistory(resumeId: string): Promise<ChatMessage[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/fetch-chat-history/${resumeId}`);
			return plainToInstance(ChatMessage, response.data as ChatMessage[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `fetch chat history ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async fetchResume(resumeId: string): Promise<ResumeView> {
		try {
			const response = await axios.get(`${this.baseUrl}/get-saved-resume/${resumeId}`);
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `fetch resume ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async updateTitle(resumeId: string, newTitle: string): Promise<ResumeView> {
		try {
			const response = await axios.post(`${this.baseUrl}/update-title`, { resumeId, newTitle });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update title ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async generateResume(profileId: string, jobTitle: string, jobDescription: string, resumeId: string): Promise<ResumeView> {
		console.log("generateResume", jobTitle);
		try {
			const response = await axios.post(`${this.baseUrl}/generate-resume`, { profileId, jobTitle, jobDescription, resumeId });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate resume ${profileId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async updateResumeField(resumeId: string, field: string, value: string): Promise<ResumeView> {
		try {
			console.log("updateResumeField", resumeId, field, value);
			const response = await axios.patch(`${this.baseUrl}/update-field`, {
				resumeId,
				field,
				value
			});
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update resume field ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async generateFeedback(resumeId: string): Promise<Partial<FeedbackData>> {
		try {
			const response = await axios.post(`${this.baseUrl}/generate-feedback`, { resumeId });
			return plainToInstance(FeedbackData, response.data as Partial<FeedbackData>);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate feedback ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async addResumeItem(resumeId: string, section: string): Promise<ResumeView> {
		try {
			const response = await axios.post(`${this.baseUrl}/add-resume-item`, { resumeId, section });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `add resume item ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async removeResumeItem(resumeId: string, section: string, itemIndex: string): Promise<ResumeView> {
		try {
			const response = await axios.patch(`${this.baseUrl}/remove-resume-item`, { resumeId, section, itemIndex });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `remove resume item ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async addTask(resumeId: string, section: string, itemIndex: string): Promise<ResumeView> {
		try {
			const response = await axios.patch(`${this.baseUrl}/add-task`, { resumeId, section, itemIndex });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `add task ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async removeTask(resumeId: string, section: string, itemIndex: string, taskIndex: string): Promise<ResumeView> {
		try {
			console.log("removeTask", resumeId, section, itemIndex, taskIndex);
			const response = await axios.patch(`${this.baseUrl}/remove-task`, { resumeId, section, itemIndex, taskIndex });
			return plainToInstance(ResumeView, response.data as ResumeView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `remove task ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}
}
