import React, { useState } from "react";
import { ResumeView } from "../../types/ResumeView";
import { ResumeStyleProps } from "../../components/UserPages/ResumeAssist";
// import { ResumeService } from "../../services/ResumeService";
import SingleColumnTemplate from "./ResumeTemplates/SingleColumnTemplate";
import MinimalTemplate from "./ResumeTemplates/MinimalTemplate";
import { singleColumnStyles } from "./ResumeTemplates/SingleColumnTemplate";
import { minimalStyles } from "./ResumeTemplates/MinimalTemplate";

// Types
interface ResumeTemplateProps {
	resume: ResumeView;
	style?: ResumeStyleProps;
	onUpdate?: (updatedResume: ResumeView) => void;
	template?: "single" | "minimal";
}

// Main Component
const ResumeTemplate: React.FC<ResumeTemplateProps> = ({ resume, style, onUpdate, template = "single" }) => {
	const templateStyles = template === "single" ? singleColumnStyles : minimalStyles;
	const TemplateComponent = template === "single" ? SingleColumnTemplate : MinimalTemplate;

	return (
		<div className="h-full overflow-y-auto custom-scrollbar">
			<style>{templateStyles}</style>
			<TemplateComponent resume={resume} style={style} onUpdate={onUpdate} />
		</div>
	);
};

export default ResumeTemplate;
