import { ExperienceType } from "../schemas/ExperienceType";
import { SessionType } from "../schemas/SessionType";
/**
 * Groups experience types into session types
 * @param experienceType - The type of experience from ExperienceType enum
 * @returns SessionType enum value based on the experience type
 */
export function getSessionType(experienceType: ExperienceType): SessionType {
	switch (experienceType) {
		case ExperienceType.CO_OP_EXPERIENCE:
		case ExperienceType.INTERNSHIP:
		case ExperienceType.FIELD_PLACEMENT:
		case ExperienceType.CAPSTONE_PROJECT:
		case ExperienceType.WEEKLY_SPRINT:
		case ExperienceType.MONTHLY_PROJECT:
			return SessionType.REFLECTION_COOP;
		case ExperienceType.COURSE:
			return SessionType.REFLECTION_COURSE;
		case ExperienceType.CONFERENCE:
		case ExperienceType.DEMO:
			return SessionType.INBOUND;
		case ExperienceType.INTERVIEW:
			return SessionType.INTERVIEW;
		case ExperienceType.FRONTLINE:
			return SessionType.FRONTLINE;
		default:
			return SessionType.REFLECTION_COOP;
	}
}
