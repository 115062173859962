import React from "react";
import { ResumeView } from "../../../../types/ResumeView";
import { ResumeStyleProps } from "../../../UserPages/ResumeAssist";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { commonStyles } from "../../ResumeTemplates/BaseTemplate";

interface SectionComponentProps {
	resume: ResumeView;
	style?: ResumeStyleProps;
	renderEditableField: (field: string, value: string, className?: string, multiline?: boolean, itemId?: string | number | null) => React.ReactNode;
	onRemoveItem?: (index: number) => void;
	onAddTask?: (section: string, index: number) => void;
	onRemoveTask?: (section: string, itemIndex: number, taskIndex: number) => void;
}

export const EducationSection: React.FC<SectionComponentProps> = ({ resume, style, renderEditableField, onRemoveItem }) => (
	<div>
		{resume.education?.map((edu, eduIndex) => (
			<div style={{ marginBottom: "10px", position: "relative" }} key={edu.id} className="resume-section-individual">
				{onRemoveItem && (
					<button
						onClick={() => onRemoveItem(eduIndex)}
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							padding: "4px",
							visibility: "hidden",
							opacity: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							background: "transparent",
							border: "none",
							cursor: "pointer",
							color: "#666"
						}}
						className="remove-button"
						title="Remove Education"
					>
						<MinusIcon className="h-4 w-4" />
					</button>
				)}
				<h3 style={{ fontSize: `${13 * (style?.fontSize || 1)}pt`, margin: "0", fontWeight: "bold" }}>
					{renderEditableField(`education.${eduIndex}.degree`, edu.degree, "", false, edu.id)} in{" "}
					{renderEditableField(`education.${eduIndex}.program`, edu.program, "", false, edu.id)}
				</h3>
				<table style={{ width: "100%", borderCollapse: "collapse", margin: "0" }}>
					<tbody>
						<tr style={{ width: "80%", marginRight: "20px", marginBottom: "2px" }}>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									padding: "0",
									width: "40%",
									paddingLeft: "0"
								}}
							>
								{renderEditableField(`education.${eduIndex}.school`, edu.school, "", false, edu.id)}
							</td>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									padding: "0",
									textAlign: "right",
									width: "60%",
									fontWeight: "bold",
									paddingRight: "0",
									marginRight: "20px"
								}}
							>
								Graduating Year: {renderEditableField(`education.${eduIndex}.graduatingYear`, edu.graduatingYear, "", false, edu.id)}
							</td>
						</tr>
					</tbody>
				</table>
				{edu?.notes && edu?.notes.length > 0 && (
					<ul
						style={{
							listStyleType: "disc",
							paddingLeft: "20px",
							margin: "0",
							fontSize: `${11 * (style?.fontSize || 1)}pt`,
							lineHeight: "1.1"
						}}
					>
						{edu?.notes?.map((note, index) => (
							<li style={{ margin: "0", padding: "0 0 1px 0" }} key={index}>
								{renderEditableField(`education.${eduIndex}.notes.${index}`, note.trim(), "", true, edu.id)}
							</li>
						))}
					</ul>
				)}
			</div>
		))}
	</div>
);

export const ExperienceSection: React.FC<SectionComponentProps> = ({ resume, style, renderEditableField, onRemoveItem, onAddTask, onRemoveTask }) => (
	<div>
		{resume?.experience?.map((exp, expIndex) => (
			<div style={{ marginBottom: "10px", position: "relative" }} key={exp.id} className="resume-section-individual">
				{onRemoveItem && (
					<button
						onClick={() => onRemoveItem(expIndex)}
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							padding: "4px",
							visibility: "hidden",
							opacity: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							background: "transparent",
							border: "none",
							cursor: "pointer",
							color: "#666"
						}}
						className="remove-button"
						title="Remove Experience"
					>
						<MinusIcon className="h-4 w-4" />
					</button>
				)}
				<h3 style={{ fontSize: `${13 * (style?.fontSize || 1)}pt`, margin: "0", fontWeight: "bold" }}>
					{renderEditableField(`experience.${expIndex}.jobTitle`, exp.jobTitle, "", false, exp.id)} at{" "}
					{renderEditableField(`experience.${expIndex}.companyName`, exp.companyName, "", false, exp.id)}
				</h3>
				<table style={{ width: "100%", borderCollapse: "collapse", margin: "0", tableLayout: "fixed" }}>
					<tbody>
						<tr style={{ width: "80%", marginRight: "20px", marginBottom: "2px" }}>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									padding: "0",
									width: "50%",
									paddingLeft: "0",
									whiteSpace: "normal"
								}}
							>
								{renderEditableField(`experience.${expIndex}.city`, exp.city, "", false, exp.id)},{" "}
								{renderEditableField(`experience.${expIndex}.stateOrProv`, exp.stateOrProv, "", false, exp.id)}
							</td>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									padding: "0",
									textAlign: "right",
									width: "50%",
									fontWeight: "bold",
									paddingRight: "0",
									whiteSpace: "nowrap",
									marginRight: "20px"
								}}
							>
								{renderEditableField(`experience.${expIndex}.startMonth`, exp.startMonth, "", false, exp.id)}{" "}
								{renderEditableField(`experience.${expIndex}.startYear`, exp.startYear, "", false, exp.id)} -{" "}
								{renderEditableField(`experience.${expIndex}.endMonth`, exp.endMonth, "", false, exp.id)}{" "}
								{renderEditableField(`experience.${expIndex}.endYear`, exp.endYear, "", false, exp.id)}
							</td>
						</tr>
					</tbody>
				</table>
				<ul
					style={{
						listStyleType: "disc",
						paddingLeft: "20px",
						margin: "0",
						fontSize: `${11 * (style?.fontSize || 1)}pt`,
						lineHeight: "1.1",
						position: "relative"
					}}
				>
					{exp?.roles?.map((role, roleIndex) => (
						<li
							style={{ margin: "0", padding: "0 0 1px 0", position: "relative", paddingRight: "20px" }}
							key={roleIndex}
							className="resume-section-individual"
						>
							{renderEditableField(`experience.${expIndex}.roles.${roleIndex}`, role.trim(), "", true, exp.id)}
							{onRemoveTask && (
								<button
									onClick={() => onRemoveTask("experience", expIndex, roleIndex)}
									style={{
										position: "absolute",
										top: "2px",
										right: "0",
										padding: "2px",
										visibility: "hidden",
										opacity: 0,
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										background: "transparent",
										border: "none",
										cursor: "pointer",
										color: "#666"
									}}
									className="remove-button"
									title="Remove Role"
								>
									<MinusIcon className="h-3 w-3" />
								</button>
							)}
						</li>
					))}
					{onAddTask && (
						<button
							onClick={() => onAddTask("experience", expIndex)}
							style={{
								visibility: "hidden",
								opacity: 0,
								display: "inline-flex",
								alignItems: "center",
								padding: "2px 4px",
								fontSize: "11pt",
								color: "#666",
								background: "transparent",
								border: "none",
								cursor: "pointer",
								marginTop: "4px"
							}}
							className="add-button"
							title="Add Role"
						>
							<PlusIcon className="h-4 w-4" />
						</button>
					)}
				</ul>
			</div>
		))}
	</div>
);

export const ProjectsSection: React.FC<SectionComponentProps> = ({ resume, style, renderEditableField, onRemoveItem, onAddTask, onRemoveTask }) => (
	<div>
		{resume?.projects?.map((project, projectIndex) => (
			<div style={{ marginBottom: "15px", position: "relative" }} key={project.id} className="resume-section-individual">
				{onRemoveItem && (
					<button
						onClick={() => onRemoveItem(projectIndex)}
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							padding: "4px",
							visibility: "hidden",
							opacity: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							background: "transparent",
							border: "none",
							cursor: "pointer",
							color: "#666"
						}}
						className="remove-button"
						title="Remove Project"
					>
						<MinusIcon className="h-4 w-4" />
					</button>
				)}
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "0" }}>
					<h3 style={{ fontSize: `${13 * (style?.fontSize || 1)}pt`, margin: "0", fontWeight: "bold" }}>
						{renderEditableField(`projects.${projectIndex}.projectName`, project.projectName, "", false, project.id)}
					</h3>
					<span
						style={{
							color: "#666666",
							fontSize: `${11 * (style?.fontSize || 1)}pt`,
							fontWeight: "bold",
							margin: "0"
						}}
					>
						{renderEditableField(`projects.${projectIndex}.startMonth`, project.startMonth, "", false, project.id)}{" "}
						{renderEditableField(`projects.${projectIndex}.startYear`, project.startYear, "", false, project.id)} -{" "}
						{renderEditableField(`projects.${projectIndex}.endMonth`, project.endMonth, "", false, project.id)}{" "}
						{renderEditableField(`projects.${projectIndex}.endYear`, project.endYear, "", false, project.id)}
					</span>
				</div>
				<p style={{ fontSize: `${11 * (style?.fontSize || 1)}pt`, lineHeight: "1.2", margin: "5px 0" }}>
					{renderEditableField(`projects.${projectIndex}.description`, project.description, "", true, project.id)}
				</p>
				{project?.notes && project?.notes.length > 0 && (
					<ul
						style={{
							listStyleType: "disc",
							paddingLeft: "20px",
							margin: "0",
							fontSize: `${11 * (style?.fontSize || 1)}pt`,
							lineHeight: "1.1",
							position: "relative"
						}}
					>
						{project?.notes?.map((note, index) => (
							<li
								style={{ margin: "0", padding: "0 0 1px 0", position: "relative", paddingRight: "20px" }}
								key={index}
								className="resume-section-individual"
							>
								{renderEditableField(`projects.${projectIndex}.notes.${index}`, note.trim(), "", true, project.id)}
								{onRemoveTask && (
									<button
										onClick={() => onRemoveTask("projects", projectIndex, index)}
										style={{
											position: "absolute",
											top: "2px",
											right: "0",
											padding: "2px",
											visibility: "hidden",
											opacity: 0,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											background: "transparent",
											border: "none",
											cursor: "pointer",
											color: "#666"
										}}
										className="remove-button"
										title="Remove Note"
									>
										<MinusIcon className="h-3 w-3" />
									</button>
								)}
							</li>
						))}
						{onAddTask && (
							<button
								onClick={() => onAddTask("projects", projectIndex)}
								style={{
									visibility: "hidden",
									opacity: 0,
									display: "inline-flex",
									alignItems: "center",
									padding: "2px 4px",
									fontSize: "11pt",
									color: "#666",
									background: "transparent",
									border: "none",
									cursor: "pointer",
									marginTop: "4px"
								}}
								className="add-button"
								title="Add Note"
							>
								<PlusIcon className="h-4 w-4" />
							</button>
						)}
					</ul>
				)}
				{project?.projectURL && (
					<p style={{ fontSize: `${11 * (style?.fontSize || 1)}pt`, margin: "5px 0" }}>
						<a href={project.projectURL} style={{ color: style?.accentColor || "#003366", textDecoration: "none" }}>
							{renderEditableField(`projects.${projectIndex}.projectURL`, project.projectURL, "", false, project.id)}
						</a>
					</p>
				)}
			</div>
		))}
	</div>
);

export const SkillsSection: React.FC<
	SectionComponentProps & {
		layout?: "single-column" | "minimal" | string;
	}
> = ({ resume, style, renderEditableField, onAddTask, onRemoveTask, layout = "single-column" }) => {
	const getHeadingStyle = () => {
		if (layout === "minimal") {
			return {
				fontSize: `${11 * (style?.fontSize || 1)}pt`,
				fontFamily: "Calibri, sans-serif",
				color: "#666",
				fontWeight: "600",
				marginBottom: "5px"
			};
		}
		return {
			fontSize: `${13 * (style?.fontSize || 1)}pt`,
			margin: "0 0 5px 0",
			fontWeight: "bold",
			fontFamily: "Calibri, sans-serif",
			color: "#333333"
		};
	};

	const getListStyle = () => {
		if (layout === "minimal") {
			return {
				margin: "0 0 15px 0",
				marginLeft: 5,
				fontSize: `${11 * (style?.fontSize || 1)}pt`,
				fontFamily: "Calibri, sans-serif",
				color: "#666",
				lineHeight: "1.6",
				position: "relative" as const
			};
		}
		return {
			listStyleType: "disc",
			paddingLeft: "20px",
			margin: "0",
			fontSize: `${11 * (style?.fontSize || 1)}pt`,
			lineHeight: "1.1",
			position: "relative" as const,
			fontFamily: "Calibri, sans-serif"
		};
	};

	const renderSkillCategory = (title: string, skills: string[], section: string) => {
		if (!skills || skills.length === 0) return null;

		const renderSkillItem = (skill: string, index: number) => {
			if (layout === "minimal") {
				const bulletStyle = {
					color: "#666",
					marginRight: "5px",
					display: "inline-block",
					width: "12px"
				};
				return (
					<div key={index} style={getListStyle()} className="resume-section-individual">
						<span style={bulletStyle}>•</span>
						{renderEditableField(`skills.${section}.${index}`, skill.trim(), "", false, index)}
						{onRemoveTask && (
							<button
								onClick={() => onRemoveTask(section, 0, index)}
								className="remove-button"
								title="Remove Skill"
								style={{
									position: "absolute",
									top: "2px",
									right: "0",
									padding: "2px",
									visibility: "hidden",
									opacity: 0,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									background: "transparent",
									border: "none",
									cursor: "pointer",
									color: "#666"
								}}
							>
								<MinusIcon className="h-3 w-3" />
							</button>
						)}
					</div>
				);
			}

			return (
				<li
					key={index}
					style={{ margin: "0", padding: "0 0 1px 0", position: "relative", paddingRight: "20px" }}
					className="resume-section-individual"
				>
					{renderEditableField(`skills.${section}.${index}`, skill.trim(), "", false, index)}
					{onRemoveTask && (
						<button
							onClick={() => onRemoveTask(section, 0, index)}
							className="remove-button"
							title="Remove Skill"
							style={{
								position: "absolute",
								top: "2px",
								right: "0",
								padding: "2px",
								visibility: "hidden",
								opacity: 0,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								background: "transparent",
								border: "none",
								cursor: "pointer",
								color: "#666"
							}}
						>
							<MinusIcon className="h-3 w-3" />
						</button>
					)}
				</li>
			);
		};

		return (
			<div style={{ position: "relative" }} className="resume-section-individual">
				<div style={getHeadingStyle()}>{title}</div>
				{layout === "minimal" ? (
					<div>{skills.map((skill, index) => renderSkillItem(skill, index))}</div>
				) : (
					<ul style={getListStyle()}>
						{skills.map((skill, index) => renderSkillItem(skill, index))}
						{onAddTask && (
							<button
								onClick={() => onAddTask(section, 0)}
								className="add-button"
								title={`Add ${title}`}
								style={{
									visibility: "hidden",
									opacity: 0,
									display: "inline-flex",
									alignItems: "center",
									padding: "2px 4px",
									fontSize: "11pt",
									color: "#666",
									background: "transparent",
									border: "none",
									cursor: "pointer",
									marginTop: "4px"
								}}
							>
								<PlusIcon className="h-4 w-4" />
							</button>
						)}
					</ul>
				)}
			</div>
		);
	};

	if (layout === "minimal") {
		return (
			<div>
				{renderSkillCategory("Technical Skills", resume.skills.technical, "technical")}
				{renderSkillCategory("Core Competencies", resume.skills.coreCompetencies, "coreCompetencies")}
				{renderSkillCategory("Frameworks", resume.skills.frameworks, "frameworks")}
				{renderSkillCategory("Tools", resume.skills.tools, "tools")}
			</div>
		);
	}

	// Single column layout (default)
	return (
		<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
			<colgroup>
				<col style={{ width: "25%" }} />
				<col style={{ width: "25%" }} />
				<col style={{ width: "25%" }} />
				<col style={{ width: "25%" }} />
			</colgroup>
			<tbody>
				<tr>
					<td style={{ verticalAlign: "top", paddingRight: "15px" }}>
						{renderSkillCategory("Technical Skills", resume.skills.technical, "technical")}
					</td>
					<td style={{ verticalAlign: "top", paddingRight: "15px" }}>
						{renderSkillCategory("Core Competencies", resume.skills.coreCompetencies, "coreCompetencies")}
					</td>
					<td style={{ verticalAlign: "top", paddingRight: "15px" }}>
						{renderSkillCategory("Frameworks", resume.skills.frameworks, "frameworks")}
					</td>
					<td style={{ verticalAlign: "top" }}>{renderSkillCategory("Tools", resume.skills.tools, "tools")}</td>
				</tr>
			</tbody>
		</table>
	);
};

export const ExtraCurricularSection: React.FC<SectionComponentProps> = ({
	resume,
	style,
	renderEditableField,
	onRemoveItem,
	onAddTask,
	onRemoveTask
}) => (
	<div>
		{resume?.extraCurricular?.map((activity, activityIndex) => (
			<div style={{ marginBottom: "10px", position: "relative" }} key={activity.id} className="resume-section-individual">
				{onRemoveItem && (
					<button
						onClick={() => onRemoveItem(activityIndex)}
						style={{
							position: "absolute",
							top: "0",
							right: "0",
							padding: "4px",
							visibility: "hidden",
							opacity: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							background: "transparent",
							border: "none",
							cursor: "pointer",
							color: "#666"
						}}
						className="remove-button"
						title="Remove Activity"
					>
						<MinusIcon className="h-4 w-4" />
					</button>
				)}
				<h3 style={{ fontSize: `${13 * (style?.fontSize || 1)}pt`, margin: "0 0 2px 0", fontWeight: "bold" }}>
					{renderEditableField(`extraCurricular.${activityIndex}.activity`, activity.activity, "", false, activity.id)}
				</h3>
				<table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "2px" }}>
					<tbody>
						<tr style={{ width: "80%", marginRight: "20px", marginBottom: "2px" }}>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									paddingBottom: "2px",
									width: "40%",
									paddingLeft: "0"
								}}
							>
								{renderEditableField(`extraCurricular.${activityIndex}.orgName`, activity.orgName, "", false, activity.id)}
							</td>
							<td
								style={{
									color: "#666666",
									fontSize: `${11 * (style?.fontSize || 1)}pt`,
									paddingBottom: "2px",
									textAlign: "right",
									width: "60%",
									fontWeight: "bold",
									paddingRight: "0",
									marginRight: "20px"
								}}
							>
								{renderEditableField(`extraCurricular.${activityIndex}.startMonth`, activity.startMonth, "", false, activity.id)}{" "}
								{renderEditableField(`extraCurricular.${activityIndex}.startYear`, activity.startYear, "", false, activity.id)} -{" "}
								{renderEditableField(`extraCurricular.${activityIndex}.endMonth`, activity.endMonth, "", false, activity.id)}{" "}
								{renderEditableField(`extraCurricular.${activityIndex}.endYear`, activity.endYear, "", false, activity.id)}
							</td>
						</tr>
					</tbody>
				</table>
				{/* <ul
					style={{
						listStyleType: "disc",
						paddingLeft: "20px",
						margin: "0",
						fontSize: `${11 * (style?.fontSize || 1)}pt`,
						lineHeight: "1.1"
					}}
				>
					{activity?.tasks?.map((task, taskIndex) => (
						<li style={{ margin: "0", padding: "0 0 1px 0" }} key={taskIndex}>
							{renderEditableField(`extraCurricular.${activityIndex}.tasks.${taskIndex}`, task.trim(), "", true, activity.id)}
						</li>
					))}
				</ul> */}

				{Array.isArray(activity.tasks) && activity.tasks.length > 0 && (
					<ul
						style={{
							listStyleType: "disc",
							paddingLeft: "20px",
							margin: "0",
							fontSize: `${11 * (style?.fontSize || 1)}pt`,
							lineHeight: "1.1",
							position: "relative"
						}}
					>
						{activity.tasks.map((task, index) => (
							<li
								style={{ margin: "0", padding: "0 0 1px 0", position: "relative", paddingRight: "20px" }}
								key={index}
								className="resume-section-individual"
							>
								{renderEditableField(`extraCurricular.${activityIndex}.tasks.${index}`, task.trim(), "", true, activity.id)}
								{onRemoveTask && (
									<button
										onClick={() => onRemoveTask("extraCurricular", activityIndex, index)}
										style={{
											position: "absolute",
											top: "2px",
											right: "0",
											padding: "2px",
											visibility: "hidden",
											opacity: 0,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											background: "transparent",
											border: "none",
											cursor: "pointer",
											color: "#666"
										}}
										className="remove-button"
										title="Remove Task"
									>
										<MinusIcon className="h-3 w-3" />
									</button>
								)}
							</li>
						))}
						{onAddTask && (
							<button
								onClick={() => onAddTask("extraCurricular", activityIndex)}
								style={{
									visibility: "hidden",
									opacity: 0,
									display: "inline-flex",
									alignItems: "center",
									padding: "2px 4px",
									fontSize: "11pt",
									color: "#666",
									background: "transparent",
									border: "none",
									cursor: "pointer",
									marginTop: "4px"
								}}
								className="add-button"
								title="Add Task"
							>
								<PlusIcon className="h-4 w-4" />
							</button>
						)}
					</ul>
				)}
			</div>
		))}
	</div>
);
