import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useAuth } from "@frontegg/react";
import { useAppContext } from "./contexts/AppContext";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import SignUp from "./components/AuthPages/SignUp";
import TokenManager from "./services/TokenManager";
import QueuePage from "./components/UserPages/QueuePage";
import UserEmailLaunch from "./components/UserPages/UserEmailLaunch";
import UserScheduleCall from "./components/UserPages/UserScheduleCall";
import UserCallMe from "./components/UserPages/UserCallMe";
import UserCallScheduled from "./components/UserPages/UserCallScheduled";
import UserReportEmailConfirm from "./components/UserPages/UserReportEmailConfirm";
// force build
// Components
import Layout from "./components/Utility/Layout";
import AssignmentList from "./components/AdminPages/AssignmentList";
import AdminSettings from "./components/AdminPages/AdminSettings";
import SentEmailsPage from "./components/AdminPages/SentEmailsPage";
import CreateAssignment from "./components/AdminPages/CreateAssignment/CreateAssignment";
import SessionLog from "./components/AdminPages/SessionLog";
import Activity from "./components/UserPages/Activity";
import OnboardingStart from "./components/UserPages/OnboardingStart";
import UserHomepage from "./components/UserPages/UserHomepage";
import UserSessionPage from "./components/UserPages/UserSessionPage";
import NotFound from "./components/AuthPages/NotFound";
import AdminAssignmentPage from "./components/AdminPages/AdminAssignmentPage";
import AssignmentCodePage from "./components/Utility/AssignmentCodePage";
import ConnectedUsersPage from "./components/AdminPages/ConnectedUsersPage";
import NoAccess from "./components/AuthPages/NoAccess";
import StatusBanner from "./components/UserPages/StatusBanner";
import ProviderStatusPage from "./components/UserPages/ProviderStatusPage";
import { NavigateService } from "./services/NavigateService";
import EmailReportPostSession from "./components/UserPages/EmailReportPostSession";
import EmailReportPostAssignment from "./components/UserPages/EmailReportPostAssignment";
import SupportPage from "./components/UserPages/SupportPage";
import UserPublicSessionPage from "./components/UserPages/UserPublicSessionPage";
import AdminCostPage from "./components/AdminPages/AdminCostPage";
import { MonitorResponse } from "./services/ProviderService";
import ConferenceManager from "./components/AdminPages/ConferenceManager";
import ManageBookingPage from "./components/UserPages/ManageBookingPage";
import { Toaster } from "./components/shadcn-ui/Toaster";
import QueueTestPage from "./components/UserPages/QueueTestPage";
import ProfileCreator from "./components/UserPages/ProfileCreator";
import ResumeAssist from "./components/UserPages/ResumeAssist";
import { ClientSettingsProvider } from "./contexts/ClientSettingsContext";
import ResumeSelector from "./components/Chat/ResumeSelector";

// ProtectedRoute component
const ProtectedRoute: React.FC<{ element: React.ReactElement; hasClearance: boolean }> = ({ element, hasClearance }) => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();
	const { assignmentCode } = useParams();
	if (isAuthenticated && hasClearance) {
		return element;
	} else if (isAuthenticated) {
		return <Navigate to="/no-access" />;
	}

	if (assignmentCode) {
		return (
			<Navigate
				to={{
					pathname: "/account/login",
					search: `?redirectUrl=${encodeURIComponent(location.pathname + location.search)}&assignmentCode=${assignmentCode}`
				}}
			/>
		);
	}

	return (
		<Navigate
			to={{
				pathname: "/account/login",
				search: `?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`
			}}
		/>
	);
};

const App: React.FC = () => {
	const location = useLocation();
	const { isAuthenticated, user } = useAuth();
	const posthog = usePostHog();
	const { fetchUserAndCheckTutorial, on, off, isServiceUp, setIsServiceUp, pagesNavigated, setPagesNavigated, instageUser } = useAppContext();
	const bannerFlag = useFeatureFlagEnabled("reflection-banner-system");
	const isBannerEnabled = bannerFlag === true || bannerFlag === null;

	const [loading, setLoading] = useState(true);

	const serviceProviderBaseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/service-providers`;

	const token = TokenManager.getToken();

	useEffect(() => {
		const fetchBetterstackStatus = () => {
			if (!isAuthenticated) return; // Check if user is authenticated
			axios
				.get(`${serviceProviderBaseUrl}/getBetterStackStatus`)
				.then((response: AxiosResponse<MonitorResponse>) => {
					// console.log("Betterstack status response:", response.data);
					setIsServiceUp(true); // Initialize boolean to track provider status

					const providersToCheck = ["ElevenLabs", "OpenAI2", "Vapi", "FrontEgg", "instage.io", "Reflection Backend"];

					for (const provider of response.data.data) {
						// Skip if provider name is not in our list
						if (!providersToCheck.includes(provider.attributes.pronounceable_name)) {
							continue;
						}

						if (provider.attributes.status !== "up" && !provider.attributes.paused) {
							console.log("Provider is not up:", provider.attributes.pronounceable_name);
							setIsServiceUp(false);
							break;
						}
					}

					// setBetterstackStatus(response.data);
				})
				.catch((error) => {
					console.error("Error fetching provider status:", error);
				});
		};

		// Initial fetch
		if (token) {
			fetchBetterstackStatus();
		}
		setLoading(false);

		// Set up interval to fetch provider status every 3 minutes
		const intervalId = setInterval(fetchBetterstackStatus, 3 * 60 * 1000);

		// Clean up interval on component unmount or when user disconnects
		return () => clearInterval(intervalId);
	}, [serviceProviderBaseUrl, token, isAuthenticated]);

	useEffect(() => {
		const currentPath = location.pathname;
		setPagesNavigated((prev) => {
			// Don't add if it's the same as the last path
			if (prev[prev.length - 1] === currentPath) {
				return prev;
			}
			return [...prev, currentPath];
		});
	}, [location.pathname]);

	useEffect(() => {
		// console.log("pagesNavigated", pagesNavigated);
		// if user has not navigated to the login page, then they are already signed in
		if (!pagesNavigated.includes("/account/login") && pagesNavigated.length > 0 && isAuthenticated) {
			if (!sessionStorage.getItem("alreadySignedInEventCaptured")) {
				console.log("alreadySignedInEventCaptured - instageUser: ", instageUser);
				try {
					// console.log("MAJOR_EVENT: Already Signed In When Entering Site");
					posthog.capture("MAJOR_EVENT: Already Signed In When Entering Site", {
						fronteggId: instageUser?.fronteggId || "", // need frontegg id
						userId: instageUser?.id || "",
						tenantId: instageUser?.tenantId || "", // need tenant id
						clientId: instageUser?.clientId || ""
					});
					// Set the flag in sessionStorage
					if (instageUser) {
						sessionStorage.setItem("alreadySignedInEventCaptured", "true");
					}
				} catch (err) {
					console.error("Error capturing event 'M.E. Already Signed In When Entering Site':", err);
				}
			}
		}
		try {
			posthog.capture(`NAVIGATION: Pages Navigated to ${location.pathname}`, { page: location.pathname, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'NAVIGATION: Pages Navigated to':", err);
		}
	}, [pagesNavigated, instageUser]);

	// const [incidentMessage, setIncidentMessage] = useState<string | null>(null);

	// useEffect(() => {
	// 	console.log("incidentStatus UseEffect", incidentMessage);
	// 	// Listen for the incident status update event
	// 	const handleIncidentStatusUpdate = (data: any) => {
	// 		console.log("Received incident status update: ", data);
	// 		setHasIncidents(data.headers.status === "resolved" ? false : true);
	// 		console.log("hasIncidents", hasIncidents);
	// 		// setIncidentMessage("test");
	// 	};

	// 	on("incidentStatus", handleIncidentStatusUpdate);

	// 	// Clean up the socket connection on component unmount
	// 	return () => {
	// 		off("incidentStatus", handleIncidentStatusUpdate);
	// 	};
	// }, [on, off]);

	// useEffect(() => {
	// 	console.log("incident check", incident);
	// 	setHasIncidents(incident); // Set has incidents based on the incident value
	// }, [incident]);

	useEffect(() => {
		if (isAuthenticated && user) {
			// console.log("user.roles", user.roles);

			TokenManager.setToken(user.accessToken);

			fetchUserAndCheckTutorial().then((foundUser) => {
				setLoading(false);
				if (foundUser) {
					posthog?.identify(foundUser.id, {
						email: foundUser.email,
						role: user.roles[0]?.key,
						isReflectionApp: true
					});
				}
			});
		} else {
			setLoading(false);
		}
	}, [isAuthenticated, user, fetchUserAndCheckTutorial, posthog]);

	useEffect(() => {
		if (!loading && isAuthenticated) {
			const currentPath = location.pathname;

			if (TokenManager.hasFacultyClearance()) {
				if (currentPath === "/") {
					NavigateService.navToAdmin();
				}
			}
		}
	}, [loading, isAuthenticated, location]);

	// Add this useEffect to track initial login status
	useEffect(() => {
		if (!loading && isAuthenticated) {
			// Check if the event has already been captured in this session
			if (!sessionStorage.getItem("successfullySignedIn") && !sessionStorage.getItem("alreadySignedInEventCaptured")) {
				console.log("Capturing event 'MAJOR_EVENT: User Successfully Signed In'");
				console.log("instageUser", instageUser);
				try {
					posthog?.capture("MAJOR_EVENT: User Successfully Signed In", {
						was_already_logged_in: true,
						entry_path: location.pathname,
						timestamp: new Date().toISOString(),
						fronteggId: instageUser?.fronteggId || "", // need frontegg id
						userId: instageUser?.id || "",
						tenantId: instageUser?.tenantId || "", // need tenant id
						clientId: instageUser?.clientId || ""
					});
				} catch (err) {
					console.error("Error capturing event 'MAJOR_EVENT: User Successfully Signed In':", err);
				}
				// Set the flag in sessionStorage
				if (instageUser) {
					sessionStorage.setItem("successfullySignedIn", "true");
				}
			}
		}
	}, [loading, isAuthenticated, posthog, location.pathname, instageUser]);

	if (loading) {
		// console.log("Returning null due to loading");
		return null; // or a loading spinner if you prefer
	}

	// const isAnyProviderUnstable = () => {
	// 	if (!providers) {
	// 		return false;
	// 	}
	// 	// console.log("typeof providers", typeof providers, providers);
	// 	return providers?.some((provider) => provider.value === "down") || false;
	// };

	// console.log("All providers:", isServiceUp);

	return (
		<>
			<Toaster />
			{!isServiceUp && <StatusBanner message="One of our third party providers is currently experiencing delays. Please hold tight!" />}

			<Layout>
				<Routes>
					<Route path="/" element={<Navigate to="/user" replace />} />
					<Route
						path="onboarding"
						element={<ProtectedRoute element={<OnboardingStart />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route path="admin" element={<ProtectedRoute element={<AssignmentList />} hasClearance={TokenManager.hasFacultyClearance()} />} />
					<Route
						path="admin/sent-emails"
						element={<ProtectedRoute element={<SentEmailsPage />} hasClearance={TokenManager.hasInstageAdminClearance()} />}
					/>
					<Route
						path="provider-status"
						element={<ProtectedRoute element={<ProviderStatusPage />} hasClearance={TokenManager.hasFacultyClearance()} />}
					/>
					<Route
						path="admin/connected-users"
						element={<ProtectedRoute element={<ConnectedUsersPage />} hasClearance={TokenManager.hasFacultyClearance()} />}
					/>
					<Route path="user" element={<ProtectedRoute element={<UserHomepage />} hasClearance={TokenManager.hasMemberClearance()} />} />
					<Route
						path="queue-test"
						element={<ProtectedRoute element={<QueueTestPage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="queue-test/:sessionId"
						element={<ProtectedRoute element={<QueueTestPage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>

					<Route
						path="user/assignment/:assignmentId/user/:userId/schedule/:scheduleId"
						element={<ProtectedRoute element={<UserSessionPage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="user/assignment/:assignmentId/user/:userId"
						element={<ProtectedRoute element={<UserSessionPage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="admin/create-reflection/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="create" assignmentType="reflection" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasFacultyClearance()}
							/>
						}
					/>
					<Route
						path="admin/create-interview/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="create" assignmentType="interview" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasFacultyClearance()}
							/>
						}
					/>
					<Route
						path="admin/create-frontline/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="create" assignmentType="frontline" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasFacultyClearance()}
							/>
						}
					/>
					<Route
						path="user/create-reflection/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="create" assignmentType="reflection" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasMemberClearance()}
							/>
						}
					/>
					<Route
						path="user/create-interview/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="create" assignmentType="interview" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasMemberClearance()}
							/>
						}
					/>
					<Route
						path="admin/edit-reflection/:assignmentId/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="edit" assignmentType="reflection" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasAdminClearance()}
							/>
						}
					/>
					<Route
						path="admin/edit-interview/:assignmentId/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="edit" assignmentType="interview" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasAdminClearance()}
							/>
						}
					/>
					<Route
						path="admin/edit-frontline/:assignmentId/:step?"
						element={
							<ProtectedRoute
								element={
									<ClientSettingsProvider>
										<CreateAssignment mode="edit" assignmentType="frontline" />
									</ClientSettingsProvider>
								}
								hasClearance={TokenManager.hasFacultyClearance()}
							/>
						}
					/>
					<Route
						path="admin/settings"
						element={<ProtectedRoute element={<AdminSettings />} hasClearance={TokenManager.hasAdminClearance()} />}
					/>
					<Route
						path="admin/assignment/:assignmentId"
						element={<ProtectedRoute element={<AdminAssignmentPage />} hasClearance={TokenManager.hasFacultyClearance()} />}
					/>

					<Route
						path="admin/assignment/:assignmentId/schedule/:scheduleId"
						element={<ProtectedRoute element={<AdminAssignmentPage />} hasClearance={TokenManager.hasFacultyClearance()} />}
					/>
					{/* <Route path="/session-log" element={<SessionLog />} /> */}
					<Route
						path="admin/session-log"
						element={<ProtectedRoute element={<SessionLog />} hasClearance={TokenManager.hasFacultyClearance()} />}
					/>
					<Route
						path="user/assignment/:id/user/:userId/session/:sessionId"
						element={<ProtectedRoute element={<Activity />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="user/assignment/:assignmentId/user/:userId/session/:sessionId/queue"
						element={<ProtectedRoute element={<QueuePage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>

					<Route
						path="admin/costs"
						element={<ProtectedRoute element={<AdminCostPage />} hasClearance={TokenManager.hasInstageAdminClearance()} />}
					/>
					<Route
						path="onboarding/user/:userId/session/:sessionId"
						element={<ProtectedRoute element={<Activity />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route path="account/signup/:assignmentCode" element={<SignUp isEmailConfirm={false} />} />

					<Route path="account/emailConfirm/:assignmentCode" element={<SignUp isEmailConfirm={true} />} />
					<Route
						path="assignment/:assignmentCode"
						element={<ProtectedRoute element={<AssignmentCodePage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					{/* <Route path="user/phone-call" element={<PhoneCallForm />} /> */}

					<Route
						path="user/email-launch"
						element={<ProtectedRoute element={<UserEmailLaunch />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>

					<Route
						path="user/schedule-call/:assignedSessionId"
						element={<ProtectedRoute element={<UserScheduleCall />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route path="user/call-scheduled" element={<UserCallScheduled />} />
					<Route
						path="booking/:bookingId/seat/:seatId"
						element={<ProtectedRoute element={<ManageBookingPage />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="user/call-me"
						element={<ProtectedRoute element={<UserCallMe />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="user/call-me/session/:sessionId"
						element={<ProtectedRoute element={<UserCallMe />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>
					<Route
						path="user/call-me/:assignmentId/:scheduleId"
						element={<ProtectedRoute element={<UserCallMe />} hasClearance={TokenManager.hasMemberClearance()} />}
					/>

					<Route path="user/email-launch/:token" element={<UserEmailLaunch />} />

					<Route path="user/report-post-session/:token" element={<EmailReportPostSession />} />

					<Route path="user/report-post-assignment-new/:token/schedule/:scheduleToken" element={<EmailReportPostAssignment />} />

					<Route
						path="user/report-post-assignment/assignmentToken/:token/scheduleToken/:scheduleToken/assignedSessionToken/:assignedSessionsToken"
						element={<EmailReportPostAssignment />}
					/>
					<Route path="user/resume-assist" element={<ResumeSelector />} />
					<Route path="user/resume-assist/profile/:profileId/user/:userId" element={<ProfileCreator />} />
					<Route path="user/resume-assist/:resumeId" element={<ResumeAssist />} />
					<Route path="user/report-confirm/:token" element={<UserReportEmailConfirm />} />

					<Route path="session/:sessionId" element={<UserPublicSessionPage />} />
					<Route path="/support" element={<SupportPage />} />
					<Route path="/no-access" element={<NoAccess />} />
					<Route path="*" element={<NotFound />} />
					<Route
						path="admin/conferences"
						element={<ProtectedRoute element={<ConferenceManager />} hasClearance={TokenManager.hasInstageAdminClearance()} />}
					/>
				</Routes>
			</Layout>
		</>
	);
};

export default App;
