import { useState, useRef, useEffect } from "react";
import { ResumeService } from "../../../services/ResumeService";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

interface EditingState {
	field: string;
	itemId: string | number | null;
}

interface EditableFieldProps {
	field: string;
	value: string;
	className?: string;
	multiline?: boolean;
	itemId?: string | number | null;
}

export const useEditableField = (resumeId: string, onUpdate?: (updatedResume: any) => void) => {
	const [editingState, setEditingState] = useState<EditingState | null>(null);
	const [editValue, setEditValue] = useState("");
	const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
	const [cursorPosition, setCursorPosition] = useState<number | null>(null);

	const handleEdit = (field: string, value: string, itemId: string | number | null = null) => {
		setEditingState({ field, itemId });
		setEditValue(value);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = e.target.value;
		const newPosition = e.target.selectionStart;
		setEditValue(newValue);
		setCursorPosition(newPosition);
	};

	// Effect to maintain cursor position
	useEffect(() => {
		if (cursorPosition !== null && inputRef.current) {
			inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
		}
	}, [editValue, cursorPosition]);

	const handleSave = async () => {
		if (!editingState) return;

		try {
			const updatedResume = await ResumeService.updateResumeField(resumeId, editingState.field, editValue);
			onUpdate?.(updatedResume);
		} catch (error) {
			console.error("Failed to update resume field:", error);
		}

		setEditingState(null);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSave();
		} else if (e.key === "Escape") {
			setEditingState(null);
		}
	};

	const renderEditableField = (
		field: string,
		value: string,
		className: string = "",
		multiline: boolean = false,
		itemId: string | number | null = null
	) => {
		const isEditing = editingState?.field === field && editingState?.itemId === itemId;

		if (isEditing) {
			const commonStyles = `bg-white text-black border border-[#00a9af] rounded px-2 py-1 focus:outline-none focus:border-[#00a9af] focus:ring-1 focus:ring-[#00a9af] ${className}`;

			if (multiline) {
				return (
					<textarea
						ref={inputRef as React.RefObject<HTMLTextAreaElement>}
						value={editValue}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						onBlur={() => setTimeout(handleSave, 100)}
						className={`${commonStyles} w-full min-h-[100px] resize-y`}
						autoFocus
					/>
				);
			}

			return (
				<input
					ref={inputRef as React.RefObject<HTMLInputElement>}
					type="text"
					value={editValue}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onBlur={() => setTimeout(handleSave, 100)}
					className={`${commonStyles} min-w-[50px]`}
					style={{ width: `${Math.max(editValue.length * 12, value.length * 12, 50)}px` }}
					autoFocus
				/>
			);
		}

		return (
			<span className={"resume-section-individual"} onClick={() => handleEdit(field, value, itemId)}>
				{value}
			</span>
		);
	};

	return { renderEditableField };
};
