import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../shadcn-ui/Card";
import { Button } from "../shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { VapiService } from "../../services/VapiService";
import PhoneVerification from "../Shared/PhoneVerification";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { assistants } from "../../constants/Assistants";
import { NavigateService } from "../../services/NavigateService";
import { CheckIcon } from "lucide-react";
import callingScreen from "../../assets/images/calling_screen.png";
import { PhoneCallIcon } from "lucide-react";
import posthog from "posthog-js";
import { useQueueSystem } from "../../hooks/useQueueSystem";
import Spinner from "../Utility/Spinner";

const SuccessMessage: React.FC = () => (
	<div className="w-full h-24 bg-gradient-to-r from-[#DB4F86] to-[#00A9AF] rounded-lg flex items-center justify-center mb-4">
		<div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
			<CheckIcon className="text-[#00A9AF] w-8 h-8" />
		</div>
	</div>
);

const UserCallMe: React.FC = () => {
	const navigate = useNavigate();
	const { assignmentId, scheduleId, sessionId } = useParams();
	const { instageUser, on, off } = useAppContext();

	const [isVerified, setIsVerified] = useState(false); // phone number verified
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isLoading, setIsLoading] = useState(false); // waiting for vapi call to be , button should say calling...
	const [isSuccess, setIsSuccess] = useState(false); // set to true when the vapi call returns true.
	const [error, setError] = useState<string | null>(null);
	const [currentAssistant, setCurrentAssistant] = useState(assistants[0]);
	const [isWaiting, setIsWaiting] = useState(false); // you are in the queue, waiting for spot
	const [isPending, setIsPending] = useState(false); // your spot is ready, waiting user click to create vapi call  60 seconds timer here
	const [isActuallyStartingCall, setIsActuallyStartingCall] = useState(false);

	const {
		timeLeft,
		isLoading: queueIsLoading,
		joinCallQueue,
		leaveCallQueue,
		startCall
	} = useQueueSystem({
		userId: instageUser?.id || "",
		onReadyToStart: handleReadyToStart,
		onCancelPendingCall: handleCancelPendingCall
	});

	useEffect(() => {
		const storedAssistantId = localStorage.getItem("selectedAssistantId");
		if (storedAssistantId) {
			const assistant = assistants.find((a) => a.id === storedAssistantId);
			if (assistant) {
				setCurrentAssistant(assistant);
			}
		}
	}, []);

	useEffect(() => {
		console.log("isVerified: ", isVerified);
	}, [isVerified]);

	useEffect(() => {
		console.log("isLoading: ", isLoading);
	}, [isLoading]);

	useEffect(() => {
		console.log("isSuccess: ", isSuccess);
	}, [isSuccess]);

	useEffect(() => {
		console.log("isWaiting: ", isWaiting);
	}, [isWaiting]);

	useEffect(() => {
		console.log("isPending: ", isPending);
	}, [isPending]);

	useEffect(() => {
		console.log("getting number");
		UserService.getPhoneNumber().then((number) => {
			console.log(number);
			if (number) {
				const formatted = number.number.replace(/^\+?(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
				setPhoneNumber(formatted);
				setIsVerified(number.isVerified);
			}
		});
	}, []);

	useEffect(() => {
		const handleSummaryGenerated = (data: { assignmentId: string; scheduleId: string }) => {
			// console.log("summaryGenerated", data);
			if (data.assignmentId && instageUser?.id && data.scheduleId) {
				NavigateService.navToUserAssignment(data.assignmentId, instageUser.id, data.scheduleId);
			}
		};

		on("summaryGenerated", handleSummaryGenerated);

		return () => {
			off("summaryGenerated", handleSummaryGenerated);
		};
	}, [on, off, instageUser?.id]);

	const handleVerificationComplete = React.useCallback((verifiedPhoneNumber: string) => {
		setIsVerified(true);
		setPhoneNumber(verifiedPhoneNumber);
		try {
			posthog.capture("MAJOR_EVENT: Verified Phone Number", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Verified Phone Number':", err);
		}
		console.log(verifiedPhoneNumber);
	}, []);

	const handleCallNow = async () => {
		if (!instageUser) return;

		setIsLoading(true);
		setError(null);

		try {
			let postHogSessionId = "";
			try {
				postHogSessionId = posthog?.get_session_id() || "";
			} catch (error) {
				console.error("Error getting posthog session ID:", error);
			}

			if (!sessionId) {
				// old not used anymore
				await VapiService.createPhoneCall(
					instageUser.id,
					instageUser.firstName,
					currentAssistant.voice_provider,
					currentAssistant.voice_id,
					currentAssistant.name,
					assignmentId,
					scheduleId
				);
			} else if (isPending === false) {
				const data = await joinCallQueue(sessionId);
				if (data.hasToWait) {
					setIsWaiting(true);
				} else {

					const vapiCallId = await VapiService.createPhoneCallV3(instageUser.id, sessionId, postHogSessionId);
					await startCall(sessionId, vapiCallId);
					console.log("vapiCallId", vapiCallId);

					setIsLoading(false);
					setIsSuccess(true);
				}
			} else {
				setIsActuallyStartingCall(true);

				// if pending is true, then the user clicked the button already, so we just wait for the vapi call to return true
				const vapiCallId = await VapiService.createPhoneCallV3(instageUser.id, sessionId, postHogSessionId);

				await startCall(sessionId, vapiCallId);

				console.log("vapiCallId", vapiCallId);

				setIsLoading(false);
				setIsSuccess(true);
			}
		} catch (error) {
			console.error("Failed to create phone call:", error);
			setIsLoading(false);
			setIsSuccess(false);
			setError("Failed to initiate the call. Please try again later.");
			try {
				posthog.capture("MAJOR_EVENT: Failed to Create Phone Call", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Failed to Create Phone Call':", err);
			}
		}
	};

	const handleChangeNumber = () => {
		if (sessionId) {
			leaveCallQueue(sessionId);
		}
		setIsWaiting(false);
		setIsPending(false);
		setIsActuallyStartingCall(false);
		setIsSuccess(false);
		setIsLoading(false);
		setIsVerified(false);
		setPhoneNumber("");
	};

	function handleReadyToStart() {
		// called when the queue is ready to start aka leaving the waiting room
		// setIsWaiting(false);
		setIsLoading(false);
		setIsPending(true);
	}
	function handleCancelPendingCall() {
		navigate(-1);
	}

	const handleCancel = () => {
		if (sessionId) {
			leaveCallQueue(sessionId);
		}
		handleCancelPendingCall();
	};

	const VerificationComponent: React.FC<{
		onVerificationComplete: (phone: string) => void;
		onCancel: () => void;
	}> = ({ onVerificationComplete, onCancel }) => (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<PhoneVerification onVerificationComplete={onVerificationComplete} onCancel={onCancel} />
			</CardContent>
		</Card>
	);

	const CallInProgressComponent: React.FC = () => (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<p className="text-center mb-2 text-[#101828] text-xl font-bold flex items-center justify-center">
					<PhoneCallIcon className="w-6 h-6 mr-2 text-[#00A9AF]" />
					Call in progress...
				</p>
				<img src={callingScreen} alt="Calling Screen" className="w-full h-auto mb-4" />
				<p className="text-center text-[#475467] mb-4">You will receive a call shortly.</p>
			</CardContent>
		</Card>
	);

	const SpinnerLoadingIntoCallComponent: React.FC = () => (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<SuccessMessage />
				<br />
				<Spinner />
				<br />
			</CardContent>
		</Card>
	);

	const VerifiedWaitingComponent: React.FC<{
		phoneNumber: string;
		isLoading: boolean;
		timeLeft: number;
		isPending: boolean;
		handleChangeNumber: () => void;
		handleCancel: () => void;
		handleCallNow: () => void;
	}> = ({ phoneNumber, isLoading, timeLeft, isPending, handleChangeNumber, handleCancel, handleCallNow }) => (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<SuccessMessage />
				<p className="text-center mb-2 text-[#101828] text-xl font-bold">Number verified successfully!</p>
				<p className="text-center text-[#475467] mb-4">You are in the queue. Waiting for spot...</p>
				<p className="text-center mb-4">
					{phoneNumber}{" "}
					<Button
						variant="link"
						disabled={isLoading}
						onClick={handleChangeNumber}
						className="disabled:text-gray-400 disabled:bg-transparent"
					>
						change number
					</Button>
				</p>
				{isLoading && !isActuallyStartingCall && <Spinner />}

				<br />

				<div className="flex space-x-4">
					<Button variant="outline" className="w-1/2" onClick={handleCancel}>
						Cancel
					</Button>
					<Button className="w-1/2 bg-[#00A9AF] hover:bg-[#008C8F] text-white" onClick={handleCallNow} disabled={isLoading}>
						{isActuallyStartingCall && <p>Call In Progress</p>}
						{!isActuallyStartingCall && <>{isLoading ? "Waiting..." : "Start Call"}</>}
					</Button>
				</div>
				{isPending && timeLeft !== -1 && <p>Time left before auto-cancel: {timeLeft} seconds</p>}
			</CardContent>
		</Card>
	);

	if (!isVerified) {
		return <VerificationComponent onVerificationComplete={handleVerificationComplete} onCancel={handleCancel} />;
	}

	if (isSuccess) {
		return <CallInProgressComponent />;
	}

	if (isWaiting) {
		return (
			<VerifiedWaitingComponent
				phoneNumber={phoneNumber}
				isLoading={isLoading}
				timeLeft={timeLeft}
				isPending={isPending}
				handleChangeNumber={handleChangeNumber}
				handleCancel={handleCancel}
				handleCallNow={handleCallNow}
			/>
		);
	}

	if (isActuallyStartingCall) {
		return <SpinnerLoadingIntoCallComponent />;
	}

	return (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<SuccessMessage />
				<p className="text-center mb-2 text-[#101828] text-xl font-bold">Number verified successfully!</p>
				{queueIsLoading ? (
					<Spinner />
				) : (
					<>
						<p className="text-center text-[#475467] mb-4">Click on "Call me now" to start the call</p>
						<p className="text-center mb-4">
							{phoneNumber}{" "}
							<Button
								variant="link"
								disabled={isLoading}
								onClick={handleChangeNumber}
								className="disabled:text-gray-400 disabled:bg-transparent"
							>
								change number
							</Button>
						</p>
						<div className="flex space-x-4">
							<Button variant="outline" className="w-1/2" onClick={handleCancel}>
								Cancel
							</Button>
							<Button className="w-1/2 bg-[#00A9AF] hover:bg-[#008C8F] text-white" onClick={handleCallNow} disabled={isLoading}>
								{isLoading ? "Calling..." : "Call me now"}
							</Button>
						</div>
					</>
				)}
				{error && <p className="text-red-500 text-sm mt-2">{error}</p>}
			</CardContent>
		</Card>
	);
};

export default UserCallMe;
