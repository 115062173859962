import axios from "axios";
import { ProfileView } from "../types/ProfileView";

import { plainToInstance } from "class-transformer";
import { ChatMessage } from "./ResumeService";
import { LogAxiosError } from "./AxiosUtility";

export class ProfileService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/profile`;

	public static async fetchOrCreateProfile(userId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.post(`${this.baseUrl}/fetch-or-create-profile`, { userId });
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async fetchProfile(profileId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/fetch-profile/${profileId}`);
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async fetchProfileByUserId(userId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/fetch-profile-by-user-id/${userId}`);
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async updateChatHistory(profileId: string, newChat: ChatMessage): Promise<ProfileView[]> {
		try {
			const response = await axios.post(`${this.baseUrl}/update-chat-history`, {
				profileId,
				newChat: newChat.content,
				role: newChat.role
			});
			return plainToInstance(ProfileView, response.data as ProfileView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update chat history ${profileId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	static async updateProfileSection(profileId: string, sectionName: string, sectionData: any): Promise<void> {
		console.log("updateProfileSection", sectionName, sectionData, profileId);
		try {
			await axios.patch(`${this.baseUrl}/update-section`, {
				sectionName,
				sectionData,
				profileId
			});
		} catch (error) {
			console.error("Error updating profile section:", error);
			throw error;
		}
	}
}
