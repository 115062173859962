import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import TokenManager from "../../services/TokenManager";
import { AdminAssignmentView } from "../../types/AdminAssignmentView";
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { BriefcaseBusiness, Feather, Headset } from "lucide-react";
import { Cog6ToothIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { DataTableActionMenu } from "../../components/shadcn-ui/DataTableActionMenu";
import { DataTableColumnHeader } from "../../components/shadcn-ui/DataTableColumnHeader";
import { Badge } from "../../components/shadcn-ui/Badge";
import { Button } from "../../components/shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { AssignmentService } from "../../services/AssignmentService";
import { Filter, DataTable } from "../../components/shadcn-ui/DataTable";
import { ExtendedColumnDef } from "../shadcn-ui/DataTable";
import BackButton from "../Utility/BackButton";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../components/shadcn-ui/Dialog";
import { Checkbox } from "../../components/shadcn-ui/Checkbox";
import { NavigateService } from "../../services/NavigateService";
import { SessionStats, SessionService } from "../../services/SessionService";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/shadcn-ui/Dropdown";
import { ExperienceType } from "../../schemas/ExperienceType";

const AssignmentList: React.FC = () => {
	const navigate = useNavigate();
	const { debugMode } = useAppContext();
	const frontlineFeatureFlag = useFeatureFlagEnabled("frontline-access");
	// Get unique clients from assignments and set assignments as state
	const [assignments, setAssignments] = useState<AdminAssignmentView[]>([]);
	const [showWelcomeModal, setShowWelcomeModal] = useState(() => {
		const dontShow = localStorage.getItem("dontShowWelcomeModal");
		return dontShow !== "true";
	});

	const [deleteAssignmentId, setDeleteAssignmentId] = useState<string | null>(null);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteAssignmentLoading, setDeleteAssignmentLoading] = useState(false);
	const [deleteError, setDeleteError] = useState<string | null>(null);

	const [dontShowAgain, setDontShowAgain] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingStats, setLoadingStats] = useState(false);
	const [sessionStats, setSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});
	async function fetchAssignments() {
		try {
			setLoading(true);
			setLoadingStats(true);
			if (debugMode) {
				const data = await AssignmentService.getAllAdminViewDebug();
				setAssignments(data);
				setLoading(false);
				const { stats } = await SessionService.getAllSessionsDebug();
				setSessionStats(stats);
				setLoadingStats(false);
			} else {
				// console.log("Fetching assignments");
				const data = await AssignmentService.getAllAdminView();
				setAssignments(data);
				setLoading(false);
				const { stats } = await SessionService.getAllSessionsByClientId();
				setSessionStats(stats);
				setLoadingStats(false);
				console.log("Assignments fetched:", data);
			}
		} catch (error) {
			console.error("Error fetching assignments:", error);
			// Fallback to fake data if API call fails
			// setAssignments(fakeAssignments);
			setLoading(false);
			setLoadingStats(false);
		}
	}

	useEffect(() => {
		fetchAssignments();
	}, [debugMode]);

	const handleEditAssignment = (assignmentId: string, experienceType: ExperienceType) => {
		NavigateService.navToEditAssignmentByExperienceType(assignmentId, experienceType);
	};

	const handleDeleteAssignment = async (assignmentId: string) => {
		console.log("Deleting assignment", assignmentId);
		setDeleteError(null);
		setDeleteAssignmentLoading(true);

		try {
			await AssignmentService.deleteAssignment(assignmentId);
			await fetchAssignments();
			setShowDeleteConfirmation(false);
			setDeleteAssignmentId(null);
		} catch (error) {
			console.error("Error deleting assignment:", error);
			setDeleteError(error instanceof Error ? error.message : "An unexpected error occurred while deleting the assignment");
		} finally {
			setDeleteAssignmentLoading(false);
		}
	};

	const handleViewAssignment = (assignmentId: string) => {
		NavigateService.navToAdminAssignment(assignmentId);
	};

	const handleCreateAssignment = (type: "reflection" | "interview" | "frontline") => {
		if (type === "reflection") {
			navigate("/admin/create-reflection/");
		} else if (type === "interview") {
			navigate("/admin/create-interview/");
		} else if (type === "frontline") {
			navigate("/admin/create-frontline/");
		}
	};

	let columns = useMemo<ExtendedColumnDef<AdminAssignmentView, any>[]>(
		() => [
			{
				accessorKey: "assignmentTitle",
				header: "Assignment",
				// header: ({ column }) => {
				// 	return <DataTableColumnHeader column={column} title="Assignment" />;
				// },
				cell: ({ row }) => (
					<button
						onClick={() => handleViewAssignment(row.original.assignmentId)}
						className="text-[#00a9af] hover:text-[#008c91] hover:underline text-left w-full"
					>
						{row.original.assignmentTitle}
					</button>
				)
			},
			// {
			// 	accessorKey: "satisfaction",
			// 	header: ({ column }) => {
			// 		return <DataTableColumnHeader column={column} title="Satisfaction" />;
			// 	},
			// 	cell: ({ getValue }) => {
			// 		const value = getValue() as string;
			// 		const colors: { [key: string]: string } = {
			// 			"Very Low": "bg-red-100 text-red-800",
			// 			Low: "bg-orange-100 text-orange-800",
			// 			Medium: "bg-yellow-100 text-yellow-800",
			// 			High: "bg-green-100 text-green-800",
			// 			"Very High": "bg-emerald-100 text-emerald-800"
			// 		};
			// 		return (
			// 			<span className={`px-2 py-1 rounded-full text-xs font-medium ${colors[value] || "bg-gray-100 text-gray-800"}`}>{value}</span>
			// 		);
			// 	}
			// },
			// {
			// 	header: "Flags",
			// 	accessorKey: "flags",
			// 	initiallyHidden: true, // This column will be initially hidden
			// 	cell: () => {
			// 		const flags = Math.floor(Math.random() * 4);
			// 		return flags > 0 ? <span className="text-red-500">{"🚩".repeat(flags)}</span> : null;
			// 	}
			// },
			{
				accessorKey: "startDate",
				header: "Start Date",
				cell: ({ getValue }) => {
					const date = getValue() as string;
					return new Date(date).toLocaleDateString("en-US", {
						year: "numeric",
						month: "short",
						day: "numeric"
					});
				}
			},
			{
				accessorKey: "endDate",
				header: "End Date",

				cell: ({ getValue }) => {
					const date = getValue() as string;
					return new Date(date).toLocaleDateString("en-US", {
						year: "numeric",
						month: "short",
						day: "numeric"
					});
				}
			},
			{
				accessorKey: "enrolledUserCount",
				header: "Students #"
			},
			// {
			// 	accessorKey: "sessionsAssigned",
			// 	header: ({ column }) => {
			// 		return <DataTableColumnHeader column={column} title="Sessions Assigned" />;
			// 	}
			// },
			{
				accessorKey: "totalExpectedSessions",
				header: "Total Sessions"
			},
			{
				accessorKey: "submissionCount",
				header: "Sessions Complete"
			},
			// {
			// 	accessorKey: "totalSessionsToGo",
			// 	header: ({ column }) => {
			// 		return <DataTableColumnHeader column={column} title="Sessions to Go" />;
			// 	}
			// },
			{
				accessorKey: "participationRate",
				header: "Participation",
				cell: ({ getValue }) => `${getValue()}%`
			},

			{
				accessorKey: "serviceHoursUsed",
				header: "Hours Used",
				cell: ({ getValue }) => `${getValue()}h`
			},
			// {
			// 	accessorKey: "projectedHours",
			// 	header: ({ column }) => {
			// 		return <DataTableColumnHeader column={column} title="Projected Hours" />;
			// 	},
			// 	cell: ({ getValue }) => `${getValue()}h`
			// },
			{
				accessorKey: "status",
				header: "Status",
				cell: ({ getValue }) => {
					const value = getValue() as string;
					const colors: { [key: string]: string } = {
						Complete: "bg-[#00a9af] text-white",
						Ongoing: "bg-[#41c1ca] text-white",
						Drafts: "bg-gray-200 text-gray-800"
					};
					return <Badge className={` ${colors[value] || " "}`}>{value}</Badge>;
				}
			},
			{
				id: "actions",
				cell: ({ row }) => {
					const assignment = row.original;

					return (
						<DataTableActionMenu
							label="Actions"
							copyId={debugMode ? assignment.assignmentId : undefined}
							menuItems={[
								{ label: "Edit", onClick: () => handleEditAssignment(assignment.assignmentId, assignment.experienceType) },
								{
									label: "Delete",
									onClick: () => {
										setDeleteAssignmentId(assignment.assignmentId);
										setShowDeleteConfirmation(true);
									}
								}
							]}
						/>
					);
				}
			}
		],
		[]
	);
	const hasClientColumn = columns.some((column) => column.accessorKey === "clientName");

	if (debugMode) {
		if (!hasClientColumn) {
			columns.unshift({
				accessorKey: "clientName",
				header: "Client"
			});
		}
	} else {
		// remove that column
		columns = columns.filter((column) => column.accessorKey !== "clientName");
	}

	const filters: Filter[] = [
		{
			columnId: "assignmentTitle",
			type: "input",
			placeholder: "Search Assignments" // Changed from "Filter Assignment..."
		},
		{
			columnId: "status",
			type: "select",
			placeholder: "Status",
			options: [
				{ label: "Complete", value: "Complete" },
				{ label: "Ongoing", value: "Ongoing" },
				{ label: "Drafts", value: "Drafts" }
			]
		},
		{
			columnId: "startDate",
			type: "dateRange",
			placeholder: "Start Date"
		}
	];

	const hasClientFilter = filters.some((filter) => filter.columnId === "clientName");
	if (debugMode && !hasClientFilter) {
		filters.unshift({
			columnId: "clientName",
			type: "input",
			placeholder: "Client"
		});
	}

	const downloadCSV = () => {
		const headers = columns.map((column) => column.header as string);
		const csvContent = [
			headers.join(","),
			assignments.map((assignment) =>
				columns
					.map((column) => {
						let value;
						if ("accessorKey" in column && typeof column.accessorKey === "string") {
							value = assignment[column.accessorKey as keyof AdminAssignmentView];
						} else if ("id" in column && column.id) {
							value = assignment[column.id as keyof AdminAssignmentView];
						} else {
							value = "";
						}
						return typeof value === "string" ? `"${value}"` : value;
					})
					.join(",")
			)
		].join("\n");

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "assignments.csv");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const metrics = useMemo(() => {
		const filteredAssignments = assignments;
		const totalDuration = filteredAssignments.reduce((sum, a) => sum + a.serviceHoursUsed, 0);
		const sessionsStarted = filteredAssignments.reduce((sum, a) => sum + a.totalExpectedSessions, 0);
		const sessionsCompleted = filteredAssignments.reduce((sum, a) => sum + a.submissionCount, 0);
		const totalStudents = filteredAssignments.reduce((sum, a) => sum + a.enrolledUserCount, 0);
		const averageParticipation = (sessionStats?.uniqueStudents / totalStudents) * 100;

		return {
			totalDuration: `${totalDuration.toFixed(2)}h`,
			sessionsStarted,
			sessionsCompleted,
			totalStudents,
			averageParticipation: `${averageParticipation.toFixed(2)}%`
		};
	}, [assignments, sessionStats]);

	const renderMetricsV2 = () => (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
			{[
				{ label: "Usage", value: sessionStats.totalDuration },
				{ label: "Sessions Started", value: sessionStats.sessionsStarted },
				{ label: "Sessions Submitted", value: sessionStats.sessionsSubmitted },
				{ label: "Unique Students", value: sessionStats.uniqueStudents }
			].map((metric, index) => (
				<div key={index} className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">{metric.label}</p>
					<p className="text-2xl font-bold text-[#16013e]">{metric.value}</p>
				</div>
			))}
		</div>
	);

	const renderMetrics = () => (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
			{[
				{ label: "Usage", value: sessionStats?.totalDuration },
				// { label: "Total Duration", value: metrics.totalDuration },
				{ label: "Calls Assigned", value: metrics.sessionsStarted },
				// { label: "Sessions Started", value: sessionStats.sessionsStarted },
				{ label: "Calls Submitted", value: sessionStats.sessionsSubmitted },
				// { label: "Sessions Completed", value: metrics.sessionsCompleted },

				// { label: "Students", value: metrics.totalStudents },
				{ label: "Avg Participation", value: metrics.averageParticipation }
			].map((metric, index) => (
				<div key={index} className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">{metric.label}</p>
					<p className="text-2xl font-bold text-[#16013e]">{metric.value}</p>
				</div>
			))}
		</div>
	);

	const handleIntroSession = () => {
		setShowWelcomeModal(false);
		if (dontShowAgain) {
			// Save the preference to local storage or your preferred state management solution
			localStorage.setItem("dontShowWelcomeModal", "true");
		}
		NavigateService.navToOnboarding();
	};

	const handleCloseModal = () => {
		setShowWelcomeModal(false);
		if (dontShowAgain) {
			// Save the preference to local storage or your preferred state management solution
			localStorage.setItem("dontShowWelcomeModal", "true");
		}
	};

	return (
		<div className="h-full">
			{/* Welcome Modal */}
			{/* to comment back in, remove the false check */}

			<Dialog open={showDeleteConfirmation} onOpenChange={setShowDeleteConfirmation}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Delete Assignment</DialogTitle>
						<DialogDescription>Are you sure you want to delete this assignment? This action cannot be undone.</DialogDescription>
					</DialogHeader>
					{deleteError && (
						<div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
							<span className="block sm:inline">{deleteError}</span>
						</div>
					)}
					<div className="flex justify-end space-x-2">
						<Button
							variant="outline"
							onClick={() => {
								setShowDeleteConfirmation(false);
								setDeleteError(null);
							}}
							disabled={deleteAssignmentLoading}
						>
							Cancel
						</Button>
						<Button
							variant="destructive"
							onClick={async () => {
								if (deleteAssignmentId) {
									await handleDeleteAssignment(deleteAssignmentId);
								}
							}}
							disabled={deleteAssignmentLoading}
						>
							{deleteAssignmentLoading ? (
								<>
									<svg
										className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
									Deleting...
								</>
							) : (
								"Delete"
							)}
						</Button>
					</div>
				</DialogContent>
			</Dialog>

			{false && (
				<Dialog open={showWelcomeModal} onOpenChange={setShowWelcomeModal}>
					<DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh] w-full h-full flex flex-col">
						<DialogHeader>
							<DialogTitle className="text-4xl font-bold text-primary">Welcome to AI-Guided Reflection Admin Home Page</DialogTitle>
							<DialogDescription>
								Please watch this video to learn the basics of how AI-Guided works and how to create an AI-Guided Reflection
								assignment.
							</DialogDescription>
						</DialogHeader>
						<div className="flex-grow flex justify-center items-center my-6">
							{/* Embedded placeholder YouTube video */}
							<iframe
								width="100%"
								height="100%"
								src="https://www.youtube.com/embed/SaenkZ8u5r0"
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
						<div className="mt-auto flex items-center justify-between">
							<div className="flex space-x-2">
								<Button onClick={handleIntroSession}>Do the Intro Session</Button>
								<Button variant="secondary" onClick={handleCloseModal}>
									Close
								</Button>
							</div>
							<div className="flex items-center space-x-2">
								<Checkbox
									id="dontShowAgain"
									checked={dontShowAgain}
									onCheckedChange={(checked) => setDontShowAgain(checked as boolean)}
								/>
								<label
									htmlFor="dontShowAgain"
									className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
								>
									Don't show again
								</label>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			)}
			<BackButton />
			<div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
				<div className="flex items-center">
					<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] mr-3">Assignments</h2>
					<button
						onClick={() => setShowWelcomeModal(true)}
						className="text-[#16013e] hover:text-[#00a9af]"
						title="Show welcome information"
					>
						<InformationCircleIcon className="h-4 w-4" />
					</button>
				</div>
				<div className="flex flex-wrap items-center gap-4">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="w-full md:w-auto">
								<PlusIcon className="mr-2 h-4 w-4" />
								Create Assignment
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end" className="px-0 md:w-[200px]">
							<DropdownMenuItem className="cursor-pointer h-9 px-4 py-2 text-base" onClick={() => handleCreateAssignment("reflection")}>
								<Feather className="mr-2 h-4 w-4" />
								Reflection
							</DropdownMenuItem>
							{TokenManager.hasInstageAdminClearance() && (
								<DropdownMenuItem
									className="cursor-pointer h-9 px-4 py-2 text-base"
									onClick={() => handleCreateAssignment("interview")}
								>
									<BriefcaseBusiness className="mr-2 h-4 w-4" />
									Interview
								</DropdownMenuItem>
							)}
							{frontlineFeatureFlag && (
								<DropdownMenuItem
									className="cursor-pointer h-9 px-4 py-2 text-base"
									onClick={() => handleCreateAssignment("frontline")}
								>
									<Headset className="mr-2 h-4 w-4" />
									Frontline
								</DropdownMenuItem>
							)}
						</DropdownMenuContent>
					</DropdownMenu>
					{TokenManager.hasAdminClearance() && (
						<Button
							onClick={() => NavigateService.navToAdminSettings()}
							variant="outline"
							size="icon"
							title="Settings"
							className="w-full md:w-9 md:flex-none"
						>
							<Cog6ToothIcon className="h-4 w-4" />
							<span className="md:hidden ml-2">Settings</span>
						</Button>
					)}
					<Button onClick={downloadCSV} variant="outline" size="icon" title="Download CSV" className="w-full md:w-9 md:flex-none">
						<ArrowDownTrayIcon className="h-4 w-4" />
						<span className="md:hidden ml-2">Download</span>
					</Button>
				</div>
			</div>
			{loadingStats ? <div className="flex justify-center items-center h-full">Loading...</div> : renderMetrics()}
			{/* {renderMetricsV2()} */}

			{loading ? (
				<div className="flex justify-center items-center h-full">Loading...</div>
			) : (
				<DataTable enableSorting={true} filters={filters} columns={columns} data={assignments} />
			)}
		</div>
	);
};

export default AssignmentList;
