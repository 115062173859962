import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ChatSection from "../Chat/ChatSection";
import ResumeSelector from "../Chat/ResumeSelector";
import { ChatMessage, ResumeService } from "../../services/ResumeService";
import { plainToInstance } from "class-transformer";
import { ResumeView } from "../../types/ResumeView";
import TemplateViewer from "../Chat/TemplateViewer";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";
import ProfileInfoBox from "../Chat/ProfileInfoBox";
import ProfileProgressBar from "../Chat/ProfileProgressBar";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import BackButton from "../Utility/BackButton";

const ProfileCreator: React.FC = () => {
	const [message, setMessage] = useState("");
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const [isWaitingForAI, setIsWaitingForAI] = useState(false);
	const { profileId } = useParams<{ profileId?: string }>();
	const { userId } = useParams<{ userId?: string }>();
	const [previousProfile, setPreviousProfile] = useState<ProfileView | null>(null);
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [documentUrl, setDocumentUrl] = useState<string | null>(null);
	const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSection, setSelectedSection] = useState<string | null>("contact");
	const [unconfirmedSections, setUnconfirmedSections] = useState<string[]>([]);

	const updateProfileSection = (sectionName: string, sectionData: any) => {
		console.log("updateProfileSection: ", sectionName, sectionData);
		if (profile) {
			setPreviousProfile(profile);
			const updatedProfile = { ...profile, [sectionName]: sectionData };
			setProfile(updatedProfile);
		}
	};

	useEffect(() => {
		if (!profile || !previousProfile) {
			return;
		}
		const profileSections = [
			"contact",
			"summary",
			"experience",
			"education",
			"extraCurricular",
			"skills",
			"projects",
			"certifications",
			"interests",
			"hobbies"
		];
		if (previousProfile && profile) {
			profileSections.forEach((section) => {
				if (profile[section as keyof ProfileView] !== previousProfile[section as keyof ProfileView]) {
					setUnconfirmedSections((prev) => [...prev, section]);
				}
			});
		}
	}, [previousProfile, profile]);

	const getAISelectedSectionResponse = async (message: string, chatHistory: string[]) => {
		// Artificial delay
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/get-ai-selected-section`, {
				message,
				chatHistory,
				sectionName: selectedSection
			});
			console.log("response.data: ", response.data.AiSelectedSection);
			if (response.data.AiSelectedSection) {
				setSelectedSection(response.data.AiSelectedSection);
			}
			return response.data.AiSelectedSection;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const getAIResponse = async (message: string, chatHistory: string[], section: string) => {
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/send-profile-chat`, {
				profileId: profileId,
				message,
				chatHistory,
				sectionName: section // Use the passed section instead of selectedSection state
			});
			console.log("response.data: ", response.data[`${section}`]);
			if (section) {
				updateProfileSection(section, response.data[`${section}`]);
			}
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (message.trim()) {
			// Add user message to chat
			const userMessage: ChatMessage = {
				role: "User",
				content: message,
				timestamp: new Date()
			};
			const updatedHistory = [...chatHistory, userMessage];
			setChatHistory(updatedHistory);
			setIsWaitingForAI(true);

			if (profileId) {
				try {
					console.log("updatingChatHistory ProfileId: ", profileId, userMessage);
					await ProfileService.updateChatHistory(profileId, userMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
			// try {
			// 	await getAISelectedSectionResponse(
			// 		message,
			// 		updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
			// 	);
			// 	// setSelectedSection(aiDeterminedSection.AiSelectedSection);
			// } catch (error) {
			// 	console.log(error);
			// }

			try {
				const aiSelectedSummary = await getAISelectedSectionResponse(
					message,
					updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
				);
				console.log("aiSelectedSummary", aiSelectedSummary);

				// Get AI response using the newly selected section directly from the response
				const aiResponse = await getAIResponse(
					message,
					updatedHistory.map((msg) => `${msg.role}: ${msg.content}`),
					aiSelectedSummary // Pass the selected section directly
				);
				const assistantMessage: ChatMessage = {
					role: "Assistant",
					content: aiResponse.message,
					timestamp: new Date()
				};
				setChatHistory([...updatedHistory, assistantMessage]);

				if (profileId) {
					try {
						await ProfileService.updateChatHistory(profileId, assistantMessage);
					} catch (error) {
						console.log(error);
						throw error;
					}
				}
			} catch (error) {
				console.error("Error getting AI response:", error);
			} finally {
				setIsWaitingForAI(false);
			}

			setMessage("");
		}
	};

	const handleFileSelect = (file: File) => {
		if (file.size > 5 * 1024 * 1024) {
			alert("File size should be less than 5MB");
			return;
		}

		const userMessage: ChatMessage = {
			role: "User",
			content: `Selected resume: ${file.name}`,
			timestamp: new Date()
		};
		const assistantMessage: ChatMessage = {
			role: "Assistant",
			content: `I see you've selected ${file.name}. Would you like me to analyze this resume for you?`,
			timestamp: new Date()
		};
		setChatHistory([...chatHistory, userMessage, assistantMessage]);
	};

	useEffect(() => {
		const fetchProfile = async () => {
			if (profileId) {
				const fetchedProfile = await ProfileService.fetchProfile(profileId);
				setProfile(fetchedProfile);
				setChatHistory(fetchedProfile?.chatHistory || []);
				setInitialized(true);
			}
		};
		if (profileId) {
			fetchProfile();
		}
	}, [profileId]);

	useEffect(() => {
		const initializeChat = async () => {
			const initialMessage: ChatMessage = {
				role: "Assistant",
				content:
					"Hi there, I see that you need some help creating or refining your resume. First, Can you please either upload your existing resume or start by providing some basic information about yourself such as your name?",
				timestamp: new Date()
			};
			setChatHistory([initialMessage]);
			setIsLoading(false);

			if (profileId) {
				try {
					await ProfileService.updateChatHistory(profileId, initialMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
		};

		if (chatHistory.length === 0 && profileId && initialized) {
			initializeChat();
		}
	}, [chatHistory, profileId, initialized]);

	return (
		<>
			{profileId && (
				<div className="h-[calc(75vh)] mt-0">
					<div className="container mx-auto px-6 py-2 flex flex-col h-full">
						<div className="flex items-center gap-4">
							<BackButton />
							<div className="flex-1">
								<ProfileProgressBar profile={profile} setSelectedSection={setSelectedSection} />
							</div>
						</div>
						<div className="flex gap-8 h-full">
							<ChatSection
								chatHistory={chatHistory}
								message={message}
								setMessage={setMessage}
								handleSubmit={handleSubmit}
								isLoading={isWaitingForAI}
								onFileSelect={handleFileSelect}
								profileId={profileId}
								setProfile={setProfile}
								profile={profile}
							/>

							<div className="w-[65%] h-full overflow-y-auto">
								{profile && (
									<ProfileInfoBox
										profile={profile}
										setSelectedSection={setSelectedSection}
										selectedSection={selectedSection || "contact"}
										unconfirmedSections={unconfirmedSections}
										setUnconfirmedSections={setUnconfirmedSections}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfileCreator;
